import React, { useState } from "react"
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useContractRead, useContractReads, useContractWrite, useNetwork, useToken, useWaitForTransaction } from "wagmi";
import PreSaleManager from "../../_constant/PreSaleManager.json";
import SimpleToken from "../../_constant/SimpleToken.json";
import {PRESALE_MANAGER_LIMIT_ADDRESSESS, SALE_TYPE } from "../../_constant";
import { getAccount, getNetwork } from "@wagmi/core"
import { PostErrorLogAction, presaleStartAction } from "../../redux/apiActions/api.action";
import { toast } from "react-toastify";
import { SUCCESSMSGS, TRANSACTIONMSGS } from "../../utils/messages";
import { ethers } from "ethers";
import FairLaunch from "../../_constant/FairLaunch.json";
import config from "../../config";

const currentTime = dayjs(); // Get the current time
const startTime = currentTime.add(5, 'minute');
const endTime = startTime.add(1, 'day');
const estimatedDexListingTime = endTime.add(24,'hour')

export function PresaleStart({ presaleDetails, getPresaleFuncWithoutLoading }) {

    const account = getAccount()
    const { chain, chains } = useNetwork();

    const PRESALE_MANAGER_ADDRESS = PRESALE_MANAGER_LIMIT_ADDRESSESS[chain?.network]


    const [endTimeState, setEndtimeState] = useState(endTime);


    const validateAddress = (address) => {
        const addressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
        return addressRegex.test(address);
    };


    const popperSx = {
        "& .MuiPaper-root": {
            border: "1px solid black",
            padding: 2,
            marginTop: 1,
            backgroundColor: "rgba(120, 120, 120, 0.2)",
            minWidth: "100%"
        },
        "& .MuiCalendarPicker-root": {
            backgroundColor: "rgba(45, 85, 255, 0.4)"
        },
        "& .PrivatePickersSlideTransition-root": {},
        "& .MuiPickersDay-dayWithMargin": {
            color: "rgb(229,228,226)",
            backgroundColor: "rgba(50, 136, 153)"
        },
        "& .MuiTabs-root": { backgroundColor: "rgba(120, 120, 120, 0.4)" }
    };



    // function _tokensRequiredForCreatingFairSale()
    // {
    //     /// @dev Calculating the amounts.
    //      const _tokensForFairSale = BigInt(presaleDetails?.preSaleAmount);  //eslint-disable-line no-undef

    //     const _tokensForDex = (BigInt(presaleDetails?.preSaleAmount) *   //eslint-disable-line no-undef
    //        BigInt(presaleDetails?.preSaleRatePercentage) *               //eslint-disable-line no-undef
    //         BigInt(presaleDetails.dexLiquidityPercentage)) / BigInt(10000);   //eslint-disable-line no-undef

    //     const _tokensForBonus = (BigInt(presaleDetails.preSaleAmount) *       //eslint-disable-line no-undef
    //         BigInt(presaleDetails.bonusReceivedPercentage)) / BigInt(100);     //eslint-disable-line no-undef

    //     return (BigInt(_tokensForFairSale) +      //eslint-disable-line no-undef
    //         BigInt(_tokensForDex) +               //eslint-disable-line no-undef
    //         BigInt(_tokensForBonus) +             //eslint-disable-line no-undef
    //         BigInt(presaleDetails.vestingAmount));  //eslint-disable-line no-undef
    // }

    function _tokensRequiredForCreatingFairSale()
    {
        /// @dev Calculating the amounts.
         const _tokensForFairSale = BigInt(presaleDetails?.preSaleAmount);  //eslint-disable-line no-undef
        const _tokensForDex = (BigInt(presaleDetails?.preSaleAmount) *   //eslint-disable-line no-undef
           BigInt(presaleDetails?.preSaleRatePercentage) *               //eslint-disable-line no-undef
            BigInt(presaleDetails.dexLiquidityPercentage)) / BigInt(10000);   //eslint-disable-line no-undef
        const _tokensForBonus = (BigInt(presaleDetails.preSaleAmount) *       //eslint-disable-line no-undef
            BigInt(presaleDetails.bonusReceivedPercentage)) / BigInt(100);     //eslint-disable-line no-undef
        const _requiredToken = (BigInt(_tokensForFairSale) +      //eslint-disable-line no-undef
            BigInt(_tokensForDex) +               //eslint-disable-line no-undef
            BigInt(_tokensForBonus) +             //eslint-disable-line no-undef
            BigInt(presaleDetails.vestingAmount)) //eslint-disable-line no-undef
            // console.log("_requiredToken",_requiredToken)
        // return  ethers.utils.formatUnits(_requiredToken.toString(),18) //eslint-disable-line no-undef
        const convertedEther = ethers.utils.formatEther(_requiredToken.toString());
        return convertedEther;


    }

    function _tokenRequiredForCreatingNormalPresale(){
        let hard_cap = ethers.utils.formatUnits(String(presaleDetails?.hardCap || 0), presaleDetails?.custom_fund_token_decimal || 0).toString();
        let dex_liquidity = Number(presaleDetails.dexLiquidityPercentage);
        let pre_sale_rate = ethers.utils  
        .formatUnits(String(presaleDetails?.preSaleRate || 0), Number(presaleDetails?.token_decimal || 0))
        .toString();
        
        let dex_listing_rate =  ethers.utils
        .formatUnits(String(presaleDetails?.dexListingRate || 0), Number(presaleDetails?.token_decimal || 0))
        .toString();
    
        let max_buy =ethers.utils.formatUnits(String(presaleDetails?.maxBuy || 0), presaleDetails?.custom_fund_token_decimal || 0).toString()

        let bonus_received = presaleDetails?.isBonusSale==1 ? Number(presaleDetails?.bonusReceivedPercentage) : 0

        let eligableInvestor = presaleDetails?.isBonusSale==1 ? Number(presaleDetails?.noOfBonusEligibleInvestors) : 0

        let vestingAmount =ethers.utils
        .formatUnits(String(presaleDetails?.vestingAmount), Number(presaleDetails?.custom_fund_token_decimal))
        .toString() || 0

            
          const tokenLiquidityPercent = (Number(hard_cap) * Number(dex_liquidity)) / 100;
    
          const _tokensNeededForPreSale = (Number(pre_sale_rate) * Number(hard_cap));
    
          const _tokensNeedForLiquidity = (Number(dex_listing_rate) * tokenLiquidityPercent);
    
          const _tokensNeedForBonus = (Number(max_buy) *
           Number(bonus_received) *
            Number(eligableInvestor) *
            Number(pre_sale_rate)) / 100;
    
          const _requiredTokenAmount = (_tokensNeededForPreSale +
            _tokensNeedForLiquidity +
            _tokensNeedForBonus +
            Number(vestingAmount));

            return _requiredTokenAmount;
    }




 
    const formikStartPresale = useFormik({
        enableReinitialize: true,
        initialValues: {
            rounds: presaleDetails?.rounds.map(item=>({ ...item, startTime: item.startTime || moment(new Date(startTime)).unix(), endTime: item.endTime || moment(new Date(endTime)).unix() })) || [],
            estimated_dex_listing_date: moment(new Date(estimatedDexListingTime)).unix(),
            preSaleToken: ''
        },
        validationSchema: Yup.object().shape({
            rounds: Yup.array().of(
                Yup.object().shape({
                startTime: Yup.number().required('Start Date is required'),
                endTime: Yup.number().required('End Date is required'),
                preSaleRate: Yup.number()
                    .min(0, 'Presale rate should be greater than 0!')
                    .lessThan(1e+59, "Presale Rate should be less than or equal to 1e59")
                    .required("Presale Rate is required"),
                })
            ),
            // start_date: Yup.number().test(
            //     "start_date",
            //     "StartTime should be > CurrentTime",
            //     function (value) {
            //         return Number(value) > moment(new Date(dayjs())).unix()
            //     }
            // ),
            // end_date: Yup.number().test("end_date", "EndTime should be > startTime", function (value) {
            //     const startDate = this.resolve(Yup.ref("start_date"));
            //     return Number(value) > Number(startDate)
            // }),
            // estimated_dex_listing_date: Yup.number().test("estimated_dex_listing_date", "Estimated dex listing date >=  endTime + 24 hours", function (value) {
            //     const endDates = this.resolve(Yup.ref("end_dates"));
            //     console.log(endDates,'endDates');
            //     console.log(new Date(endDates[endDates.length - 1]), 'data hai')
            //     return Number(value) >= moment(new Date(endTimeState.add('1', 'day'))).unix();
            // }),
            estimated_dex_listing_date: Yup.date().required(),
            preSaleToken: Yup.string().test('preSaleToken', 'Presale token is required!', function (value) {
                if (presaleDetails?.sale_without_token == 1) {
                    const validationSchema = Yup.string()
                        .required('Presale token is required!')
                        .test('tokenAddressForPair-test', 'Invalid presale token', (address) => {
                            return validateAddress(address);
                        })
                    try {
                        validationSchema.validateSync(value);
                    } catch (error) {
                        throw new Yup.ValidationError(error.message, value, 'preSaleToken');
                    }
                }
                return true;
            }),
        }),
        onSubmit: async (data) => {
            try {   
                let isError = false;
                data.rounds.forEach((round, index)=>{
                    if(round.startTime < moment(new Date(dayjs())).unix()){
                        toast.error("StartTime should be > CurrentTime", { toastId: 'start_time_>_current_time' })
                        isError = true;
                    }else if(Number(round.endTime) < Number(round.startTime)){
                        toast.error("endTime should be > startTime", { toastId: 'end_time_>_start_time' })
                        isError = true;
                    } else if(index > 0){
                        if(round.startTime < data.rounds[index - 1].endTime){
                        toast.error("start date should be > previous end date", { toastId: 'start_time>previous_end_date' })
                        isError = true;       
                        }
                    }
                })

                if(isError) return

                if (presaleDetails?.sale_without_token == 0) {
                    startPresale()
                } else {
                    if(presaleDetails?.sale_without_token==1 && (Number(isAllowanceData)|| isAllowanceData==undefined)<=0){
                        if(presaleDetails?.saleType == 'Presale' && ethers.utils.formatUnits(String(balanceOf?.valueOf() || 0), presaleDetails?.custom_fund_token_decimal || 0).toString() < _tokenRequiredForCreatingNormalPresale()){
                            return  toast.error("Required balance is not available!",{toastId:'reqBTid'})
                         }
                        else if(presaleDetails?.saleType != 'Presale' && balanceOf?.valueOf() < BigInt(ethers.utils.parseUnits(_tokensRequiredForCreatingFairSale(), preSaleToken?.decimals))){   //eslint-disable-line no-undef
                            return toast.error("Required balance is not available!",{toastId:'reqBTid'})
                         }
                        else{
                            approveToken()
                        }
                    }else{
                        startPresaleWithoutToken()
                    }
                }
                // extendLiquidity()
            } catch (error) {
            } finally {
            }
        },
    });

    function handleDateChangeEstDexList(dateString) {
        formikStartPresale.setFieldValue("estimated_dex_listing_date", moment(new Date(dateString)).unix())
    }



    const onErrorStartPresale = (error) => {
        PostErrorLogAction({ address: account?.address || 0, other_detail: { error } });
        console.log("13 Error",error)
        let errStr = error.toString().slice(0, 53)
        if (errStr === "TransactionExecutionError: User rejected the request.") {
            toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT)
        }else if(error.toString().includes("insufficient allowance")){
            toast.error("insufficient allowance")
          }else if(error.toString().includes("transfer amount exceeds balance")){
            toast.error("transfer amount exceeds balance")
          }else if(error.toString().includes("PreSaleINITIALIZED")){
            toast.error("Presale already started!",{toastId:'pAlStaTid'})
          }
         else {
            toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG,{toastId:'sometingWTid'});
        }
    }
  
    const onSuccessStartPresale = (data) => {

    }


    const { data: dataPresaleStart, isLoading: loadingPresaleStart, isSuccess: suceessPresaleStart, isError: errorPresaleStart, write: startPresale } = useContractWrite({
        address: PRESALE_MANAGER_ADDRESS,
        abi: PreSaleManager.abi,
        functionName: 'startPreSale',
        args: [presaleDetails?.preSale, formikStartPresale.values.rounds.map(round=> round.startTime), formikStartPresale.values.rounds.map(round=> round.endTime), formikStartPresale.values.estimated_dex_listing_date],
        onError: onErrorStartPresale,
        onSuccess: onSuccessStartPresale,
    })



    const { data: dataPresaleStartWithoutToken, isLoading: loadingStPreWithoutToken, isSuccess: successWithoutToken, isError: errorStPreWithoutToken, write: startPresaleWithoutToken } = useContractWrite({
        address: PRESALE_MANAGER_ADDRESS,
        abi: PreSaleManager.abi,
        functionName: 'startWithoutTokenPreSale',
        args: [presaleDetails?.preSale, formikStartPresale.values.preSaleToken, formikStartPresale.values.rounds.map(round=> round.startTime), formikStartPresale.values.rounds.map(round=> round.endTime), formikStartPresale.values.estimated_dex_listing_date],
        onError: onErrorStartPresale,
        onSuccess: onSuccessStartPresale,
    })


    const onApproveReceipt = async (data) => {
    }


    const { data: preSaleToken, isSuccess: isTokenFetched } = useToken({
        address: formikStartPresale.values.preSaleToken,
      });


      const { data:data_Presale_Dex, isError:isErrorPresale_Dex, isLoading:isLoading_Presale_Dex,refetch:refetchPresale_Dex } = useContractReads({
        contracts: [
          {
            address: (config.SUPPORTED_NETWORKS.some(network => network.id == chain?.id))
             ? presaleDetails?.preSale
            : undefined,
            abi: FairLaunch.abi,
            functionName: 'currentPreSaleRate',
         },
          {
            address: (config.SUPPORTED_NETWORKS.some(network => network.id == chain?.id))
            ? presaleDetails?.preSale
            : undefined,
            abi: FairLaunch.abi,
            functionName: 'currentDEXRate',
          },
        ],
      })
    

      
    const onSuccessWaitForTransaction = async (data) => {

        let body = formikStartPresale.values
        body.owner = account.address;
        body.preSale = presaleDetails?.preSale;
        body.sale_without_token = presaleDetails?.sale_without_token;
        body.name = preSaleToken?.name ||"";
        body.token_symbol = preSaleToken?.symbol || "";
        body.supply = String(preSaleToken?.totalSupply?.value) || "";
        body.token_decimal = preSaleToken?.decimals || "";

        if(presaleDetails?.saleType != SALE_TYPE.PRESALE){
            let refetch_data_Presale_Dex = await refetchPresale_Dex()
            refetch_data_Presale_Dex= refetch_data_Presale_Dex?.data;
    
            body.preSaleRate = String(refetch_data_Presale_Dex[0].result)
            body.dexListingRate = String(refetch_data_Presale_Dex[1].result)
            body.presaleAmount = String(ethers.utils.parseUnits(ethers.utils.formatEther(String(presaleDetails?.preSaleAmount)), preSaleToken?.decimals))
            if(presaleDetails?.isTeamVesting==1){
                        body.vestingAmount = String(ethers.utils.parseUnits(ethers.utils.formatEther(String(presaleDetails?.vestingAmount)), preSaleToken?.decimals))
                      }
        }
        

        let response = await presaleStartAction(body)
        if (response) {
            toast.success("Presale Started!")
            getPresaleFuncWithoutLoading()
        }
    }





    // Waiting for tx to mine.
    const { isLoading: isLoadingWaitForTranasaction } = useWaitForTransaction({
        hash: dataPresaleStart?.hash,
        onSettled: onApproveReceipt,
        onSuccess: onSuccessWaitForTransaction
    });
    const { isLoading: isLoadingWaitForTranasactionWithoutToken } = useWaitForTransaction({
        hash: dataPresaleStartWithoutToken?.hash,
        onSettled: onApproveReceipt,
        onSuccess: onSuccessWaitForTransaction
    });




    //   Approve Token
    const onSuccessAllowance = (data) => {

    }

    const onErrorAllowance = (error) => {
        console.log("onErrorAllowance", error);
        PostErrorLogAction({ address: account?.address || 0, other_detail: { error } });
        // setErrors(true)
    }


    const { data: isAllowanceData, isError: isErrorAllowance, isLoading: isLoadingAllowance, refetch: refetchAllowance } = useContractRead({
        address: formikStartPresale.values.preSaleToken != "" ? formikStartPresale.values.preSaleToken : undefined,
        abi: SimpleToken.abi,
        functionName: "allowance",
        args: [account.address, presaleDetails?.preSale],
        onSuccess: onSuccessAllowance,
        onError: onErrorAllowance
    })

    const onSuccessApprove = (data) => {
        refetchAllowance()
    }

    const onTokenFetchError = async (err) => {
        // toast.error(err.message);
    };

    /// Setup for getting balance of connected wallet address.
    const { data: balanceOf } = useContractRead({
        /// PreSale token address
        // @ts-ignore
        address: formikStartPresale.values.preSaleToken,
        abi: SimpleToken.abi,
        functionName: "balanceOf",
        args: [account.address],
        onError: onTokenFetchError,
    });

    const onApproveError = async (error) => {
        console.log("onApproveError",error);
        PostErrorLogAction({ address: account?.address || 0, other_detail: { error } });
        let errStr = error.toString().slice(0, 53)
        if (errStr === "TransactionExecutionError: User rejected the request.") {
            toast.error(TRANSACTIONMSGS.REJECTEDAPPROVING)
        } else {
            toast.error('Failed in approving!');
        }

    };


    const {
        data: approvedData,
        write: approveToken,
        isLoading: isLoadingApprove,
        isSuccess: isApprovedSuccess,
    } = useContractWrite({
        /// PreSale token address
        // @ts-ignore
        address: formikStartPresale.values.preSaleToken,
        abi: SimpleToken.abi,
        functionName: "approve",
        args: [presaleDetails?.preSale, presaleDetails?.saleType=='Presale'?balanceOf?.valueOf():BigInt(ethers.utils.parseUnits(_tokensRequiredForCreatingFairSale(), preSaleToken?.decimals))],  //eslint-disable-line no-undef
        onError: onApproveError,
        onSuccess: onSuccessApprove
    });

    const onSuccessWaitForTransactionApprove = (data) => {
        // toast.success(SUCCESSMSGS.APPROVED_SUCCESS)
        // refetchAllowance()
        startPresaleWithoutToken()
    }

    const onTokenApproveReceipt = (receipt) => {
        toast.success(SUCCESSMSGS.APPROVED_SUCCESS)
        refetchAllowance()
    }

    // Waiting for tx to mine.
    const { isLoading: isLoadingWaitForTranasactionApprove } = useWaitForTransaction({
        hash: approvedData?.hash,
        onSettled: onTokenApproveReceipt,
        onSuccess: onSuccessWaitForTransactionApprove
    });


    return (
        <>
            {formikStartPresale.values?.rounds?.map((round, index)=>(
                <div key={`round-${index}`} className="mt-5">
                    <div>Round {index + 1} {index === 0 && '(Default)'}</div>
                    <div className="form-group mt-4">
                    <div id="approve_token_form">
                        <label htmlFor="liquidity-lock form-label">Sale Start Time:</label>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                        >
                            <DemoContainer
                                components={["MobileDateTimePicker", "MobileDateTimePicker"]}
                                PopperProps={{
                                    sx: popperSx
                                }}
                            >
                                <MobileDateTimePicker 
                                    className="form-control"
                                    // label={'Start Date (UTC)'}
                                    openTo="hours"
                                    // name="startTime"
                                    name={`startTime[${index}]`}
                                    onChange={(dateString)=>{
                                        formikStartPresale.setFieldValue(`rounds[${index}].startTime`, moment(new Date(dateString)).unix())
                                    }}
                                    defaultValue={startTime}
                                    PopperProps={{
                                        sx: popperSx
                                    }}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                        </div>
                        {formikStartPresale.errors?.rounds && formikStartPresale.touched?.rounds && formikStartPresale.errors?.rounds[index]?.startTime && formikStartPresale.touched?.rounds[0]?.startTime ? (
                            <span className="text-danger">{formikStartPresale.errors?.rounds[index].startTime}</span>
                        ) : null}
                    </div>

                    <div className="form-group mt-4">
                        <div id="approve_token_form">
                        <label htmlFor="liquidity-lock form-label">Sale End Time:</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                                components={["MobileDateTimePicker", "MobileDateTimePicker"]}
                            >
                                <MobileDateTimePicker
                                    className="form-control"
                                    // label={'End Date (UTC)'}
                                    openTo="hours"
                                    // name="endTime"
                                    name={`end_dates[${index}]`}
                                    // onChange={handleDateChangeEnd}
                                    onChange={(dateString)=>{
                                        formikStartPresale.setFieldValue(`rounds[${index}].endTime`, moment(new Date(dateString)).unix())
                                    }}
                                    defaultValue={endTime}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                        </div>
                        {formikStartPresale.errors?.rounds && formikStartPresale.touched?.rounds && formikStartPresale.errors?.rounds[index]?.endTime && formikStartPresale.touched?.rounds[index].endTime ? (
                            <span className="text-danger">{formikStartPresale.errors?.rounds[index].endTime}</span>
                        ) : null}
                    </div>
                </div>
            ))}
            <div className="form-group mt-4 text-white">
                <div id="approve_token_form">
                    <label htmlFor="liquidity-lock form-label">Estimate Finalize Date:</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                            components={["MobileDateTimePicker", "MobileDateTimePicker"]}
                        >
                            <MobileDateTimePicker
                                className="form-control"
                                // label={'Estimated Dex Listing Date (Local)'}
                                openTo="hours"
                                name="dexlistingTime"
                                defaultValue={estimatedDexListingTime}
                                onChange={handleDateChangeEstDexList}
                            />
                            </DemoContainer>
                    </LocalizationProvider>
                </div>
                {formikStartPresale.errors.estimated_dex_listing_date && formikStartPresale.touched.estimated_dex_listing_date ? (
                <span className="text-danger">{formikStartPresale.errors.estimated_dex_listing_date}</span>
                ) : null}
            </div>

            {
                presaleDetails?.sale_without_token == 1 &&
                <div className="form-group mt-4" hidden={presaleDetails?.launch_stea}>
                    <label htmlFor="liquidity-lock form-label">Token Address</label>

                    <div className="contribute_amount_input">
                    <input type="text" className={formikStartPresale.errors.preSaleToken && formikStartPresale.touched.preSaleToken  && 'invalid'} id="liquidity-lock"
                        name="preSaleToken"
                        // onChange={({target})=>{setExtendLiquidityLock(target.value)}}
                        onChange={formikStartPresale.handleChange}
                    />
                    </div>
                    {formikStartPresale.errors.preSaleToken && formikStartPresale.touched.preSaleToken ? (
                        <span className="text-danger">{formikStartPresale.errors.preSaleToken}</span>
                    ) : null}
                </div>
            }


            {/* {
                presaleDetails?.sale_without_token==1 && (Number(isAllowanceData)|| isAllowanceData==undefined)<=0
                ?
                <button type="submit" className="btn btn btn-info btn-block mt-3 text-white btn_managepool"
                disabled={isLoadingApprove||isLoadingWaitForTranasactionApprove }
                onClick={approveToken}

            >
                {
                    isLoadingApprove||isLoadingWaitForTranasactionApprove ? 'Approving...' : 'Set Timers & Contract'
                }   </button>
                : */}

                <button type="submit" className="btn btn btn-info btn-block mt-3 text-white btn_managepool"
                disabled={loadingPresaleStart || isLoadingWaitForTranasaction|| loadingStPreWithoutToken||isLoadingWaitForTranasactionWithoutToken || isLoadingApprove||isLoadingWaitForTranasactionApprove}
                onClick={(event) => {
                    event.preventDefault();
                    if(presaleDetails?.sale_without_token==1 && formikStartPresale?.values?.preSaleToken==""){
                        toast.error("Presale token address is required!",{toastId:'ptrTid'})
                    }
                    else if(!preSaleToken && presaleDetails?.sale_without_token==1){
                        toast.error("Invalid presale token",{toastId:'invalidPTId'})
                    }else{
                        formikStartPresale.handleSubmit();
                    }
                }}

            >
                {
                    loadingPresaleStart || isLoadingWaitForTranasaction||loadingStPreWithoutToken||isLoadingWaitForTranasactionWithoutToken ? 'Presale Starting' :isLoadingApprove||isLoadingWaitForTranasactionApprove?'Approving...':'Set Timers & Contract'
                }   </button>
            {/* } */}

      
        </>
    )
}