import { useAccount, useContractWrite, useWaitForTransaction } from "wagmi"
import { SUCCESSMSGS, TRANSACTIONMSGS } from "../../utils/messages"
import { STAKE_MANAGER } from "../../_constant"
import StackingManagerABI from "../../_constant/StakingManager.json";
import { getAccount, getNetwork } from "@wagmi/core"  
import { toast } from "react-toastify";
import { PostErrorLogAction, cancelStakeAction } from "../../redux/apiActions/api.action";
import { SyncLoader } from "react-spinners";

export default function CancelStack ({stakeDetails, getPresaleFuncWithoutLoading}){

    const {  address,isConnected } = useAccount();
    const { chain } = getNetwork()

    const STACK_MANAGER_ADDRESS = STAKE_MANAGER[chain?.network]

    
    const onErrorCancelPresale = (error) => {
      PostErrorLogAction({ address: address || 0, other_detail: { error } });
      console.log('27 Error', error);
        let errStr = error.toString().slice(0, 25)
        if (errStr === "TransactionExecutionError") {
          toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT)
        }else if(error.toString().includes("Reward distribution required")){
          toast.error("Reward distribution required!",{toast:'rediReTId'})
        } else {
          toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG)
        }
      }
    
      const onSuccessCancelPresale = async () => {
     
      }
    

  const { data: dataCancelStake, isLoading: isLoadingCancelPresale, isSuccess: isSuccessCancelPresale, isError: isErrorCancelPresale, write: cancelPresaleWrite } = useContractWrite({
    address: STACK_MANAGER_ADDRESS,
    abi: StackingManagerABI.abi,
    functionName: 'cancel',
    args: [stakeDetails?.stakeContract],
    onError: onErrorCancelPresale,
    onSuccess: onSuccessCancelPresale,
  })

  const onSuccessCancelWFT = async()=>{
   let bodyData = { stakeContract: stakeDetails?.stakeContract, address: address }
        let response = await cancelStakeAction(bodyData)
        if (response) {  
          toast.success(SUCCESSMSGS.STACK_CANCELED)
          getPresaleFuncWithoutLoading()
        }
  }

  const { isLoading: isLoadingCancelWFT } = useWaitForTransaction({
    hash: dataCancelStake?.hash,
    onSuccess: onSuccessCancelWFT
});




    return(
        <>
                <button type="submit" 
                className="btn btn-danger btn-block mt-4 modal-open btn_managepool" 
                disabled={isLoadingCancelPresale||isLoadingCancelWFT}
                // onClick={()=>{cancelPresaleWrite()}}
                href="#modal"
                data-bs-toggle="modal"
                data-bs-target="#cancelPresaleModal"
                >{isLoadingCancelPresale||isLoadingCancelWFT?<SyncLoader color={'#3498DB'} size={10} /> :'Cancel'}</button>

      <div className="modal" id="cancelPresaleModal">
        <div className="modal-dialog">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" hidden={false} />
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <div id="myDropdown"
                className="dropdown-content px-2 py-4"
              >
                <h5 className="text-center text-blue mb-4">Are you sure?</h5>
                <div className="row row-cols-1 row-cols-sm-2">
                  <div className="col">
                    <label className="fieldlabels">This stake will be cancelled.</label>
                  </div>
                  <div style={{ overflow: "auto" }}>
                    <div className="d-flex gap-3 pt-4 py-2 justify-content-center">
                      <button
                        type="button"
                        id="prevBtnClose"
                        className="btn btn-danger m-0"
                        data-bs-dismiss="modal"
                        disabled={isLoadingCancelPresale }
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        id="nextBtn"
                        className="btn btn-primary m-0"
                        disabled={isLoadingCancelPresale }
                        onClick={() => {
                          var button = document.getElementById("prevBtnClose");
                          button.click();
                          cancelPresaleWrite()
                        }}
                      >
                        Ok
                      </button>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
        </>
    )
}