import { useState } from "react"
import { useContractRead, useContractWrite, useNetwork, useWaitForTransaction } from "wagmi"
import PreSaleManager from "../../_constant/PreSaleManager.json";
import Presale from "../../_constant/PreSale.json";
import FairLaunch from "../../_constant/FairLaunch.json";
import { SUCCESSMSGS, TRANSACTIONMSGS } from "../../utils/messages";
import { toast } from "react-toastify";
import * as Yup from "yup"
import { useFormik } from "formik";
import { getAccount, getNetwork } from "@wagmi/core"
import { PostErrorLogAction, extendLiquidityLockAction } from "../../redux/apiActions/api.action";
import { FinalizePool } from "./FinalizePool";
import { PresaleStart } from "./PresaleStart";
import { AddWhiteList } from "./AddWhitelist";
import moment from "moment";
import { ClaimLP } from "./ClaimLP";
import { ethers } from "ethers";
import { PRESALE_MANAGER_LIMIT_ADDRESSESS, PRESALE_STATUS } from "../../_constant";
import { ClaimTeamVesting } from "./ClaimTeamVesting";
import { SyncLoader } from "react-spinners";

export function ManagePool({ preSale, isCanceled, isLoadingUpdatePresale, isLoadingCancelPresale, isLoadingCancelStealthPresale, liquidityLockupDays, launch_stealth_sale, presaleDetails, getPresaleFuncWithoutLoading, isDataFundRaised,fundRaisingTokenDecimal }) {

  const { chain, chains } = useNetwork();

  const PRESALE_MANAGER_ADDRESS = PRESALE_MANAGER_LIMIT_ADDRESSESS[chain?.network]

  const account = getAccount()

  const formikExtend = useFormik({
    enableReinitialize: true,
    initialValues: {
      liqidity_lock: ""
    },
    validationSchema: Yup.object().shape({
      liqidity_lock: Yup.number().positive("Liquidity lock should be positive!!").min(1,"Liquidity lock should be greater than 0!")
        // .required('Liquidity lock is required'),

    }),
    onSubmit: async (data) => {
      try {
        if(data?.liqidity_lock==""){
          toast.error("Liquidity lock is required",{toastId:'llraTid'})
        }else{
          extendLiquidity()
        }
      } catch (error) {
      } finally {
      }
    },
  });

  const onSuccessReadExtend = async (data) => {

    // if (liquidityLockupDays != Number(data) / 86400) {
    //   let bodyData = { address: account.address, preSale: preSale, days: Number(data-dataLiqidityAddsOn) / 86400 }
    //   await extendLiquidityLockAction(bodyData)
    // }

  }
  const { data: dataExtenddd, isError: ReceiveTokensisError, isLoading: ReceiveTokensisLoading, refetch: refetchReadExtend } = useContractRead({
    address: preSale,
    abi:  presaleDetails?.saleType =='Presale'?Presale.abi:FairLaunch.abi,
    functionName: "liquidityLockedUntilInSec",
    onSuccess: onSuccessReadExtend
  })


  const onErrorCancelPresale = (error) => {
    console.log('Error 70', error);
    PostErrorLogAction({ address: account?.address || 0, other_detail: { error } });
    let errStr = error.toString().slice(0, 25)
    if (errStr === "TransactionExecutionError") {
      toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT)
    }
  }

  const onSuccessCancelPresale = async(data) => {
  }

  const { data: dataExtend, isLoading: isLoadingExtend, isSuccess: isSuccessExtent, isError: isErrorExtend, write: extendLiquidity } = useContractWrite({
    address: PRESALE_MANAGER_ADDRESS,
    abi: PreSaleManager.abi,
    functionName: 'extendLockInPeriod',
    args: [preSale, formikExtend.values.liqidity_lock],
    onError: onErrorCancelPresale,
    onSuccess: onSuccessCancelPresale,
  })

  const onApproveReceiptExtend = async (data, err) => {
    if (err) {
      console.error("Err", err);
    } else {
      let refetchedExtendData = await refetchReadExtend()
      refetchedExtendData= refetchedExtendData.data;

      let refetchedLiquidityAddson = await refetchLiqidityAddsOn()
      refetchedLiquidityAddson = refetchedLiquidityAddson.data;
      if (liquidityLockupDays != Number(refetchedExtendData) / 86400) {
        let bodyData = { address: account.address, preSale: preSale, days: Number(refetchedExtendData-refetchedLiquidityAddson) / 86400 }
        await extendLiquidityLockAction(bodyData)
      }
      toast.success(SUCCESSMSGS.LIQUIDITY_LOCAK_EXTENDED)
      formikExtend.values.liqidity_lock = ""
    }
  };

  const onSettledReceiptExtend = (data) => {
    // console.log("Data",data);
  }

  const { isLoading: isLoadingWaitForTranasaction } = useWaitForTransaction({
    hash: dataExtend?.hash,
    onSettled: onSettledReceiptExtend,
    onSuccess: onApproveReceiptExtend
  });

  const currentUnixTime = moment().unix();


  // liquidityLockedUntilInSec

  const onSuccessLiqLockInSec = () => {

  }

  const onErrorLiqLockInSec = () => {

  }

  const { data: dataLiqLockInSec, isError: isErrorLiqLockInSec, isLoading: isLoadingLiqLockInSec, refetch: refetchLiqLockInSec } = useContractRead({
    address: presaleDetails?.preSale,
    abi:  presaleDetails?.saleType =='Presale'?Presale.abi:FairLaunch.abi,
    functionName: "liquidityLockedUntilInSec",
    onSuccess: onSuccessLiqLockInSec,
    onError: onErrorLiqLockInSec
  })

  const onSuccessLiqidityAddsOn = (data) => {
    // console.log("onSuccessLiqidityAddsOn",data)
  }
  const onErrorLiqidityAddsOn = (error) => {
    // console.log("onErrorLiqidityAddsOn",error)
  }

  const { data: dataLiqidityAddsOn, isError: isErrorAllowance, isLoading: isLoadingAllowance, refetch: refetchLiqidityAddsOn } = useContractRead({
    address: presaleDetails?.preSale,
    abi:  presaleDetails?.saleType =='Presale'?Presale.abi:FairLaunch.abi,
    functionName: "liquidityAddedOn",
    onSuccess: onSuccessLiqidityAddsOn,
    onError: onErrorLiqidityAddsOn
  })

  // console.log('dataLiqLockInSec',dataLiqLockInSec)
  
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 gap-2">
          {/* <button className="btn btn-primary btn-block mt-3">Set AntiSniper</button> */}
          {
              presaleDetails?.preSaleOwner == account?.address && presaleDetails.is_approved !== PRESALE_STATUS.rejected &&
              <button
                className="btn btn-warning btn-block mt-3 modal-open btn_managepool"
                href="#modal"
                data-bs-toggle="modal"
                data-bs-target="#updatePoolModal"
                disabled={isLoadingUpdatePresale}
              >{isLoadingUpdatePresale ?  <SyncLoader color={'#3498DB'} size={10} /> : 'Update'}</button>
          }

          {
             (isCanceled == 0 && isDataFundRaised!=undefined && Number(ethers.utils.formatUnits((isDataFundRaised), Number(fundRaisingTokenDecimal))) <  ethers.utils.formatUnits(String(presaleDetails?.softCap || 0),Number(fundRaisingTokenDecimal))) && presaleDetails?.preSaleOwner == account?.address
             &&
            <button
              className="btn btn-danger btn-block mt-3 modal-open btn_managepool"
              href="#modal"
              data-bs-toggle="modal"
              data-bs-target="#cancelPresaleModal"
              disabled={isLoadingCancelPresale || isLoadingCancelStealthPresale}
            >{isLoadingCancelPresale || isLoadingCancelStealthPresale ?  <SyncLoader color={'#3498DB'} size={10} /> : 'Cancel'}
            </button>
          }

          {
            !!presaleDetails?.is_approved && presaleDetails?.setSaleTime == 0 && presaleDetails?.preSaleOwner == account?.address &&
            <PresaleStart presaleDetails={presaleDetails} getPresaleFuncWithoutLoading={getPresaleFuncWithoutLoading} />
          }

          {
               (presaleDetails?.isWhiteList == "true" && presaleDetails?.isCanceled != 1 && currentUnixTime < presaleDetails?.endTime) && currentUnixTime < moment.unix(presaleDetails?.startTime).add(Number(presaleDetails?.whiteListTimer), 'minutes').unix() && presaleDetails?.preSaleOwner == account?.address &&
            <AddWhiteList presaleDetails={presaleDetails} getPresaleFuncWithoutLoading={getPresaleFuncWithoutLoading} />
          }

          {!!presaleDetails?.listOnDex && (
            <>
              {
                presaleDetails?.preSaleOwner == account?.address &&
                        <div className="form-group mt-4">
                          <label htmlFor="liquidity-lock form-label">Extend Liquidity lock (in days)</label>
                          <div className="contribute_amount_input">
                          <input type="number"
                            className={`${formikExtend.errors.liqidity_lock && formikExtend.touched.liqidity_lock && 'invalid'}`}
                            id="liquidity-lock"
                            name="liqidity_lock"
                            // onChange={({target})=>{setExtendLiquidityLock(target.value)}}
                            onChange={formikExtend.handleChange}
                            value={formikExtend.values.liqidity_lock}
                          />
                          </div>
                        </div>
              }

              {formikExtend.errors.liqidity_lock && formikExtend.touched.liqidity_lock ? (
                <span className="text-danger">{formikExtend.errors.liqidity_lock}</span>
              ) : null}
              {
                presaleDetails?.preSaleOwner == account?.address &&
              <button type="submit" className="btn btn-info btn-block mt-4 btn_managepool" disabled={isLoadingExtend || isLoadingWaitForTranasaction}
                onClick={(event) => {
                  event.preventDefault();
                  formikExtend.handleSubmit();
                }}
              >{isLoadingExtend || isLoadingWaitForTranasaction ?  <SyncLoader color={'#3498DB'} size={10} />: 'Extend Lock'}</button>
              }
            </>
          )}

          {
            isCanceled === 0 &&
            presaleDetails?.isFinalized === 0 &&
            presaleDetails?.setSaleTime === 1 &&
            presaleDetails?.estimatedDexListingTime < currentUnixTime &&
            Number(ethers.utils.formatUnits(isDataFundRaised || 0, Number(fundRaisingTokenDecimal))) >=
                ethers.utils.formatUnits(String(presaleDetails?.softCap || 0), Number(fundRaisingTokenDecimal))
            &&
            (presaleDetails?.preSaleOwner == account?.address || presaleDetails?.stealth_wallet == account?.address)
            &&
            <FinalizePool preSale={preSale} launch_stealth_sale={launch_stealth_sale} getPresaleFuncWithoutLoading={getPresaleFuncWithoutLoading} presaleDetails={presaleDetails} refetchReadExtend={refetchReadExtend} liquidityLockupDays={liquidityLockupDays} dataLiqidityAddsOn={dataLiqidityAddsOn} refetchLiqidityAddsOn={refetchLiqidityAddsOn}/>
          }

          {
            (!!presaleDetails?.listOnDex && Number(dataLiqidityAddsOn) != 0) && (currentUnixTime >= Number(dataLiqLockInSec)) && presaleDetails?.isFinalized == 1 && presaleDetails?.preSaleOwner == account?.address &&
            <ClaimLP preSale={presaleDetails?.preSale} refetchLiqidityAddsOn={refetchLiqidityAddsOn} refetchLiqLockInSec={refetchLiqLockInSec} />
          }
          {
            (presaleDetails?.isFinalized == 1 && presaleDetails?.isTeamVesting==1) && presaleDetails?.preSaleOwner == account?.address &&
            <ClaimTeamVesting preSale={presaleDetails?.preSale} refetchLiqidityAddsOn={refetchLiqidityAddsOn} refetchLiqLockInSec={refetchLiqLockInSec} />
          }

        </div>
      </div>
    </div>
  )
}