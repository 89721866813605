import React from "react";
import { useAccount, useContractWrite, useNetwork, useWaitForTransaction } from "wagmi";
import PreSaleManager from "../../_constant/PreSaleManager.json";
import { PRESALE_MANAGER_ADDRESSESS } from "../../_constant";
import { toast } from "react-toastify";
import { SUCCESSMSGS, TRANSACTIONMSGS } from "../../utils/messages";
import { SyncLoader } from "react-spinners";
import { PostErrorLogAction } from "../../redux/apiActions/api.action";


export function ClaimTeamVesting({preSale, refetchLiqidityAddsOn, refetchLiqLockInSec}) {
    const { chain, chains } = useNetwork();
    const PRESALE_MANAGER_ADDRESS = PRESALE_MANAGER_ADDRESSESS[chain?.network]
    const { address } = useAccount();

    const onErrorClaimTeamVesting = (error)=>{
        PostErrorLogAction({ address: address || 0, other_detail: { error } });
        console.log('9 Error', error);
        let errStr = error.toString().slice(0, 53)
        if (errStr === "TransactionExecutionError: User rejected the request.") {
            toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT)
        }else if(error.toString().includes('CannotUnlockLiquidityNow')){
            toast.error("Cannot Unlock Liquidity Now!")
        }else if (error.toString().includes("FirstReleasePeriodNotReached")){
            toast.error("First Release Period Not Reached!",{toastId:'frpnRTId'})
        }else if(error.toString().includes("WaitTillReleaseDate")){
            toast.error("Wait till next release date!",{toastId:'waitTReDa'})
        }
        else{
            toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG)
        }
    }


    const onSuccessClaimTeamVesting = ()=>{

    }

    const {
        data: dataClaimTeamVesting,
        write: writeClaimTeamVesting,
        isLoading: isLoadingClaimTeamVesting,
        isSuccess: isSuccessClaimLPTokens,
      } = useContractWrite({
        address: PRESALE_MANAGER_ADDRESS,
        abi: PreSaleManager.abi,
        functionName: "releaseVestingTeamTokens",
        args:[preSale],
        onError: onErrorClaimTeamVesting,
        onSuccess: onSuccessClaimTeamVesting
      });

      const onSuccessWaitForTransaction = (data) => {
        toast.success(SUCCESSMSGS.CLAIM_TEAM_VESTING)
        refetchLiqidityAddsOn()
        refetchLiqLockInSec()
      }

      const onApproveReceipt = ()=>{

      }
    
      // Waiting for tx to mine.
      const { isLoading: isLoadingWaitForTranasaction } = useWaitForTransaction({
        hash: dataClaimTeamVesting?.hash,
        onSettled: onApproveReceipt,
        onSuccess: onSuccessWaitForTransaction
      });

    return(
        <>
        {/* <div className="container"> */}
            <div className="row justify-content-center">
                <div className="col-12 gap-2">


                    <button
                        className="btn btn-success btn-block mt-3 modal-open btn_managepool"
                    disabled={isLoadingClaimTeamVesting||isLoadingWaitForTranasaction}
                    onClick={()=>{writeClaimTeamVesting()}}
                    >{isLoadingClaimTeamVesting||isLoadingWaitForTranasaction? <SyncLoader color={'#3498DB'} size={10} />:'Claim Team Vesting'}</button>
                </div>
            </div>
        {/* </div> */}
    </>
    )

}