import CopyToClipboard from "react-copy-to-clipboard";
import { BASE_URL, listedOnDexLinks, networkLinks } from "../../_constant";
import { toast } from "react-toastify";
import Countdown from "../../Components/CountDown/CountDown";
import moment from "moment";
import { bscTestnet } from "viem/chains";
import { Tooltip } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from 'clipboard-copy';


export function TokenInfo({estimatedDexListingTime,preSaleToken,launch_stealth_sale,setSaleTime,isFinalized,presale_chain, buyToken, isCanceled, listOnDex}){
  const currentUnixTime = moment().unix();
    return(
        <div className="custom_card">
        <div className="p-3">
          <div className="progress_bar mt-2">
            <div className="text-center">
            {isFinalized==1 && 
                                Number(
                                    moment(new Date()).unix()
                                  ) >estimatedDexListingTime && listOnDex ?
                                <p>Listed on Dex</p>
                                :
                                isFinalized==1 && Number(
                                  moment(new Date()).unix()
                                )  > estimatedDexListingTime && !listOnDex ?
                                <p>Finalized</p>

                                :
                                <>
              <p className="mb-0">Estimated Finalize Time:</p>
              <span>
                {
                  isCanceled ?
                    <div>Presale Cancelled</div>
                  :
                  setSaleTime == "0" ? "TBA":
                <Countdown
                  unixTime={
                    Number(
                      moment(new Date()).unix()
                    ) >=
                      Number(estimatedDexListingTime)
                      ? estimatedDexListingTime
                      : Number(
                        moment(
                          new Date()
                        ).unix()
                      ) < estimatedDexListingTime
                        ? estimatedDexListingTime
                        : ""
                  }
                />
                }
              </span>
              </>
                              }
            </div>
            {
              launch_stealth_sale ==0 &&
            <div>
    <p className="mb-0">Token Address</p>
        <div className="d-flex">
          <span
            onClick={() => {
              window.open(
                `${networkLinks[presale_chain]}/address/${preSaleToken}`
              );
            }}
            style={{
              fontSize: "13px",
              cursor: "pointer",
              color: "#3498DB",
              marginRight: "10px", // Add margin to create space
            }}
            onMouseEnter={(e) => {
              e.target.style.textDecoration = "underline";
            }}
            onMouseLeave={(e) => {
              e.target.style.textDecoration = "none";
            }}
          >
            {preSaleToken}
          </span>
          {/* <div class="copy-container">
            <div class="tooltip">Copy</div> */}         
      <Tooltip arrow title="Copy">
        <ContentCopyIcon style={{width:'18',cursor:'pointer'}} onClick={() => {
            copy(preSaleToken).then(()=>{
              toast.success("Copied");
            })
          }}/>
    </Tooltip>







          {/* </div> */}
        </div>

            </div>
            }
          </div>

          <ul className="presale_address_ui estimated_listing_time mt-2">
            
            {
              //DynamicChain
              (presale_chain==bscTestnet.id && !!listOnDex) &&

              <li className="d-flex">
                <p>Listed On</p>
                {
                  isFinalized ?
                  <a href={`${listedOnDexLinks['PancakeSwap']}=${preSaleToken}&outputCurrency=${buyToken}`}  target="_blank" className="d-flex align-items-center"><img src="images/PancakeSwap.png" alt className="me-2" />PancakeSwap</a>
                  :
                  <div className="d-flex align-items-center">
                  <img src={BASE_URL+"images/PancakeSwap.png"} alt="" className="me-2" />
                  PancakeSwap
                </div>
                }
              </li>

            }
              {/* <li className="d-flex">
                <p>Chart</p>
                <a href="#" className="d-flex align-items-center"><img src="images/Poocoin.png" alt className="me-2" />Poocoin</a>
              </li>
              <li className="d-flex">
                <p>Chart</p>
                <a href="#" className="d-flex align-items-center"><img src="images/ave-ai.png" alt className="me-2" />AVE.AI</a>
              </li>
              <li className="d-flex">
                <p>Chart</p>
                <a href="#" className="d-flex align-items-center"><img src="images/geckoTerminal.png" alt className="me-2" />GeckoTerminal</a>
              </li>
              <li className="d-flex">
                <p>Chart</p>
                <a href="#" className="d-flex align-items-center"><img src="images/dexCheck.png" alt className="me-2" />DexCheck</a>
              </li> */}
            </ul>
        </div>
      </div>
    )
}