import { memo } from 'react';
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from '@web3modal/ethereum';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { Web3Modal } from '@web3modal/react';
import config from '../config';

/// Initializing publicClient
// const { publicClient } = configureChains(chains, [
//   w3mProvider({ projectId: PROJECT_ID }),
// ]);
const PROJECT_ID = config.INFURAID;

/// Initializing wagmiConfig
// const { provider } = configureChains(chains, [w3mProvider({ projectId })]);
// const wagmiClient = createClient({
//   autoConnect: true,
//   connectors: w3mConnectors({ projectId, version: 1, chains }),
//   provider,
// });
// const ethereumClient = new EthereumClient(wagmiClient, chains);

// /// Return the main walletconnect provider.
// const WalletProvider = ({ children }) => {
//   return (
//     <>
//         <WagmiConfig client={wagmiClient}>
//              {children}
//         </WagmiConfig>

//         <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
//       </>

//   );
// };

// export default memo(WalletProvider);

const { publicClient } = configureChains(config.ACTIVE_CHAINS, [
  w3mProvider({ projectId: PROJECT_ID }),
]);
/// Initializing wagmiConfig
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({
    projectId: PROJECT_ID,
    version: 2,
    chains: config.ACTIVE_CHAINS,
  }),
  publicClient,
});

/// Initializing ethereum client
const ethereumClient = new EthereumClient(wagmiConfig, config.ACTIVE_CHAINS);

/// Interface Props
//   interface IProps {
//     children: ReactNode;
//   }

/// Return the main walletconnect provider.
const WalletProvider = ({ children }) => {
  return (
    <WagmiConfig config={wagmiConfig}>
      {children}
      <Web3Modal
        themeVariables={{
          '--w3m-z-index': '99999999',
        }}
        projectId={PROJECT_ID}
        ethereumClient={ethereumClient}
      />
    </WagmiConfig>
  );
};
export default memo(WalletProvider);
