import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ethers } from "ethers";
import { useNetwork, useAccount, useContractWrite, useContractRead, useWaitForTransaction, useBalance } from "wagmi";
import moment from "moment";
import Countdown from "../../Components/CountDown/CountDown";
import { getAccount } from '@wagmi/core'
import {  formatUnits } from "viem";
import { Claim } from "./Claim";
import PreSale from "../../_constant/PreSale.json";
import FairLaunch from "../../_constant/FairLaunch.json";
import { BuyPresaleFairLaunch } from "./BuyPresale/FairLaunch";
import { SALE_TYPE } from "../../_constant";
import { SyncLoader } from "react-spinners";
import config from "../../config";


function CnbtFairLaunchDetails ({presaleDetails,startEndIn,isDataFundRaised,investDetails,getInvestDetailsFunc,referchFundraise,chainId,bonusSpotLeft,loading,withdraw,isLoadingWithdraw,emergencyWithdraw,isLoadingEmgcyWithDraw,getPresaleFuncWithoutLoading,updateDexAndPresaleRateDB,refetchInFBC}){
    const { chain, chains } = useNetwork();
    const account = getAccount()
  
    const { isDisconnected, isConnected, address } = useAccount();

    const [progreeBar,_progressBar]=useState(0)

    const not_custom_then_symbol = config.SUPPORTED_NETWORKS.find(network => network.id == presaleDetails?.chain)?.symbol;

    const fundRaisingTokenDecimal = presaleDetails?.fund_releasing_token == "Custom" || presaleDetails?.fund_releasing_token == "BUSD" || presaleDetails?.fund_releasing_token =='USDT' ? presaleDetails?.custom_fund_token_decimal : 18;
    const fundRaisingTokenSymbol = presaleDetails?.fund_releasing_token == "Custom" ||
      presaleDetails?.fund_releasing_token == "BUSD" || presaleDetails?.fund_releasing_token == "USDT"
      ? presaleDetails?.custom_fund_raising_token_symbol
      : not_custom_then_symbol || '';
  
      
      const refetchData = async()=>{
      
        let referchFundraiseData =await referchFundraise() 
        referchFundraiseData = referchFundraiseData?.data
  
        if(referchFundraiseData && presaleDetails?.hardCap){
          if (presaleDetails?.isHardCap==1 && presaleDetails?.hardCap!=undefined) {
            const fundRaised = Number(formatUnits(referchFundraiseData, fundRaisingTokenDecimal));
            const hardCap = Number(formatUnits(presaleDetails?.hardCap, fundRaisingTokenDecimal));
            const progress = (fundRaised * 100) / hardCap;
            _progressBar(progress)
          }
          else if(BigInt(referchFundraiseData) > BigInt(presaleDetails?.softCap)){  // eslint-disable-line no-undef
                const fundRaised = Number(formatUnits(referchFundraiseData, fundRaisingTokenDecimal));
                const progress = (fundRaised * 100) / fundRaised;
                _progressBar(progress)
          } else{
                const fundRaised = Number(formatUnits(referchFundraiseData, fundRaisingTokenDecimal));
                const softCap = Number(formatUnits(presaleDetails?.softCap,fundRaisingTokenDecimal));
                const progress = (fundRaised * 100) / softCap;
                _progressBar(progress)
          } 
        }
  
      }


    useEffect(()=>{
      refetchData()
    },[isDataFundRaised,presaleDetails?.hardCap,presaleDetails?.softCap])
  
  
    const currentUnixTime = moment().unix();
  
  


    const { data:dataCurrentDex, isError, isLoading } = useContractRead({
        address: (config.SUPPORTED_NETWORKS.some(network => network.id === chain?.id))
            ? presaleDetails?.preSale
            : undefined,
        abi: presaleDetails?.saleType =='Presale'?PreSale.abi:FairLaunch.abi,
        functionName: 'currentDEXRate',
        args: [address],
    })


      return(
          
          <div className="contribute_r_section">
          <div className="custom_card">
            <div className="p-3">
              <div className="progress_bar mt-2">
                <div className="text-center">
                  {(presaleDetails?.setSaleTime == 1 && (Number(currentUnixTime) < Number(presaleDetails?.endTime) && presaleDetails?.isCanceled == 0)) && <p className="mb-0" style={{ color: "white" }}>{" "}{startEndIn}:</p>}
                  {
                    presaleDetails?.setSaleTime == 1 ?
                      <span>
  
                        {
                          presaleDetails?.isCanceled == 1 ?
                            'Cancelled'
                            :
                            presaleDetails?.isFinalized == 1 && 
                            currentUnixTime >=  presaleDetails?.estimatedDexListingTime ?
                            <p>LIQ Unlocked</p>
                            :
                            Number(
                              moment(new Date()).unix()
                            ) > presaleDetails?.estimatedDexListingTime ?
                              <p>Listed on Dex</p>
                              :
                              startEndIn =="Sale Ended"?
                              <div>Sale Ended</div>:
                              <Countdown
                                unixTime={
                                  Number(
                                    moment(new Date()).unix()
                                  ) >=
                                    Number(presaleDetails?.startTime)
                                    ? presaleDetails?.endTime
                                    : Number(
                                      moment(
                                        new Date()
                                      ).unix()
                                    ) < presaleDetails?.startTime
                                      ? presaleDetails?.startTime
                                      : ""
                                }
                              />
                        }
                      </span>
                      :
                      presaleDetails?.isCanceled == 1 ? 'Presale Cancelled' : "TBA"
                  }
                </div>
  
                <div className="progress mt-4" style={{ height: 10 }}>
                  <div
                    className="progress-bar"
                    style={{ width: `${progreeBar}%`, height: 10 }}
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p>{isDataFundRaised && 
                  ethers.utils.formatUnits(
                    String(isDataFundRaised || 0),
                    Number(fundRaisingTokenDecimal)
                  ) || 0
                  }{" "}
                    {(fundRaisingTokenSymbol)}
                  </p>
                  <p>
                      {
                        presaleDetails?.isHardCap==1 
                        ?
                        Number(ethers.utils.formatUnits(String(presaleDetails?.hardCap), fundRaisingTokenDecimal))
                        :
                        isDataFundRaised > presaleDetails?.softCap
                        ? 
                        ethers.utils.formatUnits(
                          String(isDataFundRaised || 0),
                          Number(fundRaisingTokenDecimal) 
                        )
                        :
                        ethers.utils.formatUnits(
                          String(presaleDetails?.softCap || 0),
                          Number(fundRaisingTokenDecimal) 
                        )
                      }
                      {" "}
                    {(fundRaisingTokenSymbol)}
                  </p>
                </div>
              </div>
              {
                moment(new Date()).unix() >= Number(presaleDetails?.startTime) && moment(new Date()).unix() <= Number(presaleDetails?.endTime) ?
                  presaleDetails?.isWhiteList === "true" && presaleDetails?.whitelisted?.split(",").includes(account.address) ||
                    presaleDetails?.isWhiteList === "false" && presaleDetails?.chain == chain?.id && presaleDetails?.isCanceled==0
                    ? <BuyPresaleFairLaunch presaleDetails={presaleDetails} getInvestDetailsFunc={getInvestDetailsFunc} referchFundraise={referchFundraise} getPresaleFuncWithoutLoading={getPresaleFuncWithoutLoading} updateDexAndPresaleRateDB={updateDexAndPresaleRateDB} total_invested ={investDetails?.totalRecord || 0} refetchInFBC={refetchInFBC}/>
                    : null
                  : null
              }
              {
                presaleDetails?.isWhiteList == "true" && currentUnixTime > moment.unix(presaleDetails?.startTime).add(Number(presaleDetails?.whiteListTimer), 'minutes').unix() && !presaleDetails?.whitelisted?.split(",").includes(account.address) && moment(new Date()).unix() <= Number(presaleDetails?.endTime) && presaleDetails?.chain == chain?.id && presaleDetails?.isCanceled==0 ?
                <BuyPresaleFairLaunch presaleDetails={presaleDetails} getInvestDetailsFunc={getInvestDetailsFunc} referchFundraise={referchFundraise} getPresaleFuncWithoutLoading={getPresaleFuncWithoutLoading} updateDexAndPresaleRateDB={updateDexAndPresaleRateDB} total_invested ={investDetails?.totalRecord || 0} refetchInFBC={refetchInFBC}/>
                  : ''
              }
  
              <ul className="presale_address_ui">
                <li className="d-flex justify-content-between">
                  <p>Maximum Buy</p>
                  <p>
                    {
                      presaleDetails?.isMaxBuy==1 ?
                        ethers.utils.formatUnits(String(presaleDetails?.maxBuy || 0), fundRaisingTokenDecimal || 0).toString()
                        :
                        "∞"
                    }
                    {" "}
                    {
                      fundRaisingTokenSymbol
                    }
                  </p>
                </li>
                {
                  presaleDetails?.saleType==SALE_TYPE.HYPER &&
                <li className="d-flex justify-content-between">
                  <p>Minimum Buy</p>
                  <p>
                    {
                        ethers.utils.formatUnits(String(presaleDetails?.minBuy || 0), fundRaisingTokenDecimal || 0).toString()
                    }
                    {" "}
                    {
                      fundRaisingTokenSymbol
                    }
                  </p>
                </li>
                }
                <li className="d-flex justify-content-between">
                  <p>Total Contributors</p>
                  <p>{investDetails?.totalRecord || 0}</p>
                </li>
                {
                  presaleDetails?.saleType == SALE_TYPE.HYPER && 
                <li className="d-flex justify-content-between">
                  <p>Max Contributors</p>
                  <p>{presaleDetails?.maxContributors || 0}</p>
                </li>
                }
                <li className="d-flex justify-content-between">
                  <p>AVG Contribution</p>
                  <p>{investDetails?.totalRecord == 0 ? '0' : String(investDetails?.averageMyContribution)?.replace(/\.?0*$/, '') || 0} {" "}
                    {fundRaisingTokenSymbol}
                  </p>
                </li>
                <li className="d-flex justify-content-between">
                <p>Presale Rate per 1 {" "}
                  {fundRaisingTokenSymbol}
                </p>
                <p>

                  {
                    (presaleDetails?.preSaleRate && presaleDetails?.token_decimal) &&
                    Number(ethers.utils.formatUnits(String(presaleDetails?.preSaleRate), Number(presaleDetails?.token_decimal))).toFixed(2)
                  }
                  {" "}
                  {presaleDetails?.name} /{" "}
                  {fundRaisingTokenSymbol}
                </p>
              </li>
              <li className="d-flex justify-content-between">
                <p>Listing Rate per 1 {" "}
                  {fundRaisingTokenSymbol}
                </p>
                <p>
                  {
                    (presaleDetails?.dexListingRate && presaleDetails?.token_decimal) &&
                    (Number(ethers.utils.formatUnits(String(presaleDetails?.dexListingRate), Number(presaleDetails?.token_decimal)))).toFixed(2)
                  } 
                  {" "}
                  {presaleDetails?.name} /{" "}
                  {fundRaisingTokenSymbol}
                </p>
              </li>
              

                {
                  presaleDetails?.isbonusSale==1 &&
                  <>
                <li className="d-flex justify-content-between">
                  <p>Min Bonus Buy Amount</p>
                  <p>
                    {/* {presaleDetails?.minBonusBuyAmount}  */}
                    {
                      (presaleDetails?.minBonusBuyAmount && presaleDetails?.custom_fund_token_decimal) &&
                      ethers.utils.formatUnits(String(presaleDetails?.minBonusBuyAmount), Number(presaleDetails?.custom_fund_token_decimal))
                        .toString()
                    }
                    {" "}
                    {fundRaisingTokenSymbol}
                  </p>
                </li>
                <li className="d-flex justify-content-between">
                  <p>Bonus Received</p>
                  <p>{presaleDetails?.bonusReceivedPercentage} %</p>
                </li>
                <li className="d-flex justify-content-between">
                  <p>Bonus Spots Available</p>
                  <p>{presaleDetails?.noOfBonusEligibleInvestors}</p>
                </li>
                <li className="d-flex justify-content-between">
                  <p>Bonus Spots Left</p>
                  <p>{bonusSpotLeft || 0}</p>
                </li>
                </>
                 } 

                <li className="d-flex justify-content-between pb-0">
                  <p>My Contribution</p>
                  <p>{String(investDetails?.MyContribution)?.replace(/\.?0*$/, '') || 0} {" "}
                    {fundRaisingTokenSymbol}
                  </p>
                </li>
              </ul>
              {/* <ul>
                <li className="d-flex justify-content-between">
                  <p className="mb-0">My Reserved Tokens</p>
                  <p className="mb-0">{parseFloat(investDetails?.ReceiveTokens) || 0} {presaleDetails?.token_symbol} </p>
                </li>
              </ul> */}
              {
                (investDetails?.MyContribution != 0
                  &&
                  loading == false && chain?.id == presaleDetails?.chain
                )
  
                &&
                <ul>
                  {
                    presaleDetails?.isCanceled == 1 &&
                      <button 
                        className="btn emergency_with_btn mt-2 mx-auto"
                        onClick={() => { withdraw() }}
                        disabled={isLoadingWithdraw}
                      > {isLoadingWithdraw ? <SyncLoader color="#3498DB" size={10}/> : 'Withdraw'}</button>
                   }
                    {/* :
                      (currentUnixTime < presaleDetails?.endTime) &&
                      <button 
                        className="btn emergency_with_btn mt-2 mx-auto"
                        onClick={() => { emergencyWithdraw() }}
                        disabled={isLoadingEmgcyWithDraw}
                      > {isLoadingEmgcyWithDraw ? <SyncLoader color="#3498DB" size={10}/> : 'Emergency Withdraw'}</button>
                  } */}
                </ul>
              }
              {
                isDataFundRaised &&
                investDetails?.MyContribution != 0 && presaleDetails?.isFinalized == 1 
                && ethers.utils.formatUnits(String(isDataFundRaised) || 0,fundRaisingTokenDecimal) >= ethers.utils.formatUnits(String(presaleDetails?.softCap||0,fundRaisingTokenDecimal))
                &&
                investDetails?.isClaimed==0
                &&
                <Claim preSale={presaleDetails?.preSale} getInvestDetailsFunc={getInvestDetailsFunc} />
              }
            </div>
          </div>
        </div>
      )
}

export default CnbtFairLaunchDetails;