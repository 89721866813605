import React, { useState } from "react";
import { useContractRead,  useToken, useWaitForTransaction } from "wagmi";
import { getAccount, getNetwork } from "@wagmi/core"
import SimpleToken from "../../_constant/SimpleToken.json";
import AirdropABI from "../../_constant/Airdrop.json";
import { AIRDROP_CONTRACT_ADDRESSS, APP_NAME_TITLE, PRESALE_MANAGER_ADDRESS, convertScientificToDecimal, isNumberKey, validateAddress } from "../../_constant";
import { constants, ethers } from "ethers";
import * as Yup from "yup"
import { useFormik } from "formik";
import {useContractWrite} from "wagmi";
import { toast } from "react-toastify";
import { ERRORMSGS, TRANSACTIONMSGS } from "../../utils/messages";
import { DispSameTokToAddValidator, disperseDifferentTokenToAddressesValidator } from "../../validators/Airdrop/Airdrop.validation";
import Tbnb from "./Tbnb";
import { SyncLoader } from "react-spinners";
import { PostErrorLogAction } from "../../redux/apiActions/api.action";

// Airdrop
function Airdrop() {

    document.title =`${APP_NAME_TITLE} - Airdrop`

    const [isChecked, setIsChecked] = useState(true);
    const [isCheckedBnb, setIsCheckedBnb] = useState(true);

    const account = getAccount()


    const [token,_token] = useState("")

    const onSuccessPresaleToken = (data) => {
        // console.log("onSuccessPresaleToken", data)
    
      }

      const onTokenFetchError = (error)=>{
        console.log("onTokenFetchError",error)
        PostErrorLogAction({ address: account?.address || 0, other_detail: { error } });
      }
    
      const { data: preSaleToken, isSuccess: isTokenFetched,isError:isErrorToken,isLoading:isLoadingToken,isFetching:isFetchingToken } = useToken({
        address: token,
        onError: onTokenFetchError,
        onSuccess: onSuccessPresaleToken,
      });


  /// Setup for getting balance of connected wallet address.
  const { data: balanceOf } = useContractRead({
    /// PreSale token address
    // @ts-ignore
    address: token,
    abi: SimpleToken.abi,
    functionName: "balanceOf",
    args: [account.address],
    watch: true,
    onError: onTokenFetchError,
  });

  const onErrorAllowance = (error) => {
    console.log("onErrorAllowance", error);
    PostErrorLogAction({ address: account?.address || 0, other_detail: { error } });
  }



      const { data: isAllowanceData, isError: isErrorAllowance, isLoading: isLoadingAllowance, refetch: refetchAllowance } = useContractRead({
        address: token != "" ? token : undefined,
        abi: SimpleToken.abi,
        functionName: "allowance",
        args: [account.address, AIRDROP_CONTRACT_ADDRESSS],
        watch: true,
        // onSuccess: onSuccessAllowance,
        onError: onErrorAllowance
      })

      const AirDropValidationSchema = Yup.lazy(()=>{
            if(isChecked){
                return DispSameTokToAddValidator;
            }else{
                return disperseDifferentTokenToAddressesValidator;
            }
      })
      const formikAirdrop = useFormik({
        enableReinitialize: true,
        initialValues: {
          addresses: "",
          amount:'',
          diff_amount: ""
        },
        validationSchema:AirDropValidationSchema,
        onSubmit: async (data) => {
            if(!account.isConnected){
                return toast.error(ERRORMSGS.LOGINWITHYOURWALLET,{toastId:'connectWithTId'})
            }
            const usersAmount = convertScientificToDecimal(data.amount);
            let amountMultiplyAddressLen = (usersAmount * data.addresses.replace(/\s+/g, '').trim().split(",").length)
            amountMultiplyAddressLen= convertScientificToDecimal(amountMultiplyAddressLen)
          try {
            if(isAllowanceData < String(ethers.utils
                .parseUnits(String(amountMultiplyAddressLen), Number(preSaleToken?.decimals)))
                .toString() || Number(isAllowanceData) == 0){
                    if(!isChecked){
                      approveToken({ args:[AIRDROP_CONTRACT_ADDRESSS,ethers.utils.parseUnits(String(formikAirdrop.values.diff_amount.replace(/\s+/g, '').trim().split(",").reduce((sum, value) => Number(sum) + Number(value))|| 0), preSaleToken?.decimals || 0).toString()] })
                    }else{
                        approveToken({args:[AIRDROP_CONTRACT_ADDRESSS,ethers.utils.parseUnits(String(formikAirdrop?.values?.amount * formikAirdrop.values.addresses.replace(/\s+/g, '').trim().split(",").length || 0), preSaleToken?.decimals || 0).toString()]})
                    }
            }else{
                if(isChecked){
                    DispSameTokToAdd({ args: [token, formikAirdrop.values.addresses.replace(/\s+/g, '').trim().split(","),ethers.utils.parseUnits(String(usersAmount), preSaleToken?.decimals || 0).toString()]})
                }
                else{
                    const parsedAmounts = formikAirdrop.values.diff_amount.replace(/\s+/g, '').trim().split(",").map(element => {
                        if(Object.keys(formikAirdrop.errors).length == 0 &&  element != ""){
                            const parsedValue = ethers.utils.parseUnits(String(element), preSaleToken?.decimals);
                            return parsedValue.toString();
                        }else{
                            return 0
                        }
                      });

                    DispDiffTokToAdd({ args: [token, formikAirdrop.values.addresses.replace(/\s+/g, '').trim().split(","),parsedAmounts]})
                }
            }

            //   resetForm(); // Reset the form after submission
          } catch (error) {
            // Handle errors
          } finally {
            // Perform any necessary cleanup or actions
          }
        },
      });


    //   disperseSameTokenToAddresses

    const onErrorDispSameTokToAdd = (error)=>{
        PostErrorLogAction({ address: account?.address || 0, other_detail: { error } });
        let errStr = error.toString().slice(0, 53)
        console.log('3 Error', error);
        if (errStr === "TransactionExecutionError: User rejected the request.") {
            toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT)
        }else if(error.toString().includes("transaction exceeds the balance of the account")){
            toast.error(ERRORMSGS.NOTENOUGHBALALNCE)
        } else if(error.toString().includes("insufficient allowance")){
            toast.error(ERRORMSGS.NOTENOUGHBALALNCE)
        }
        else {
           toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG);
        }
    }

    const onSuccessDispSameTokToAdd = (error)=>{

    }

    const {
        data: dataDispSameTokToAdd,
        write: DispSameTokToAdd,
        isLoading: isLoadingDispSameTokToAdd,
        isSuccess: isSuccessDispSameTokToAdd,
      } = useContractWrite({
        address: AIRDROP_CONTRACT_ADDRESSS,
        abi: AirdropABI.abi,
        functionName: "disperseSameTokenToAddresses",
        // args: [token, formikAirdrop.values.addresses.split(","),ethers.utils.parseUnits(String(formikAirdrop.values.amount || 0), preSaleToken?.decimals || 0).toString()]
        onError: onErrorDispSameTokToAdd,
        onSuccess: onSuccessDispSameTokToAdd
      });


      const onSuccessWaitForTransaction = (data) => {
        refetchAllowance()
        _token("")
        toast.success("Airdrop sent!")
        formikAirdrop.setFieldValue("amount", "");
        formikAirdrop.setFieldValue("addresses", "");
        formikAirdrop.setErrors({})
    }

      const { data, isError: isErrorWaitForTrans, isLoading: isLoadingWaitForTransDispSameTokToAdd } = useWaitForTransaction({
        hash: dataDispSameTokToAdd?.hash,
        onSuccess: onSuccessWaitForTransaction
    })


          //   If Allowance < Amount then Approve
          const onSuccessApprove = (data) => {
          }

        const onApproveError = (error)=>{
            console.log('Error 61', error);
            PostErrorLogAction({ address: account?.address || 0, other_detail: { error } });

            let errStr = error.toString().slice(0, 53)
            if (errStr === "TransactionExecutionError: User rejected the request.") {
            toast.error(TRANSACTIONMSGS.REJECTEDAPPROVING)
            } else {
            toast.error('Failed in approving!');
            }
        }



    // let requiredApprove  = 0;
    // if(!isChecked){
    //     requiredApprove=  ethers.utils.parseUnits(String(formikAirdrop.values.diff_amount.split(",").reduce((sum, value) => BigInt(sum) + BigInt(value))|| 0), preSaleToken?.decimals || 0).toString() // eslint-disable-line no-undef
    // }else{
    //     requiredApprove = ethers.utils.parseUnits(String(formikAirdrop?.values?.amount * formikAirdrop.values.addresses.split(",").length || 0), preSaleToken?.decimals || 0).toString()
    // }

    const {
        data: approvedData,
        write: approveToken,
        isLoading: isLoadingApprove,
        isSuccess: isApprovedSuccess,
    } = useContractWrite({
        address: token,
        abi: SimpleToken.abi,
        functionName: "approve",
        // args: [AIRDROP_CONTRACT_ADDRESSS, requiredApprove],
        onError: onApproveError,
        onSuccess: onSuccessApprove
    });

    const onSuccessWaitForTransactionApprove = (data) => {
        toast.success("Approved!")
        refetchAllowance()

        const usersAmount = convertScientificToDecimal(formikAirdrop.values.amount);
        let amountMultiplyAddressLen = (usersAmount * formikAirdrop.values.addresses.replace(/\s+/g, '').trim().split(",").length)
        amountMultiplyAddressLen= convertScientificToDecimal(amountMultiplyAddressLen)

        if(isChecked){
            DispSameTokToAdd({ args: [token, formikAirdrop.values.addresses.replace(/\s+/g, '').trim().split(","),ethers.utils.parseUnits(String(usersAmount), preSaleToken?.decimals || 0).toString()]})
        }
        else{
            const parsedAmounts = formikAirdrop.values.diff_amount.replace(/\s+/g, '').trim().split(",").map(element => {
                if(Object.keys(formikAirdrop.errors).length == 0 &&  element != ""){
                    const parsedValue = ethers.utils.parseUnits(String(element), preSaleToken?.decimals);
                    return parsedValue.toString();
                }else{
                    return 0
                }
              });

            DispDiffTokToAdd({ args: [token, formikAirdrop.values.addresses.replace(/\s+/g, '').trim().split(","),parsedAmounts]})
        }
    }
    const { isLoading: isLoadingWaitForTranasaction } = useWaitForTransaction({
        hash: approvedData?.hash,
        // onSettled: onApproveReceipt,
        onSuccess: onSuccessWaitForTransactionApprove
    });



    // disperseDifferentTokenToAddresses


    const {
        data: dataDispDiffTokToAdd,
        write: DispDiffTokToAdd,
        isLoading: isLoadingDispDiffTokToAdd,
        isSuccess: isSuccessDispDiffTokToAdd,
      } = useContractWrite({
        address: AIRDROP_CONTRACT_ADDRESSS,
        abi: AirdropABI.abi,
        functionName: "disperseDifferentTokenToAddresses",
        // args: [token, formikAirdrop.values.addresses.split(","),parsedAmounts],
        onError: onErrorDispSameTokToAdd,
        onSuccess: onSuccessDispSameTokToAdd
      });

      const onSuccessWaitForTransactionDiff = (data) => {
        toast.success("Airdrop sent!")
        refetchAllowance()
        _token("")
        formikAirdrop.setFieldValue("amount", "");
        formikAirdrop.setFieldValue("addresses", "");
        formikAirdrop.setFieldValue("diff_amount","")
        formikAirdrop.setErrors({})
    }

      const { dataDiff, isError: isErrorWaitForTransDiff, isLoading: isLoadingWaitForTransDispDiffTokToAdd } = useWaitForTransaction({
        hash: dataDispDiffTokToAdd?.hash,
        onSuccess: onSuccessWaitForTransactionDiff
    })



    const [isFocusAddressList,_isFocusAddressList] = useState(false)

    const handleFocusAddressList = () => {
        _isFocusAddressList(true);
    };

    const handleBlurAddressList = () => {
        _isFocusAddressList(false);
    };

    const [isFocusAmountDiff,_isFocusAmountDiff] = useState(false)
    const handleFocusDiffAm  = ()=>{
        _isFocusAmountDiff(true)
    }
    const handleBlurDiffAm  = ()=>{
        _isFocusAmountDiff(false)
    }


    return (
        <div>
            <div classname="container-fluid">
                <div classname="row">
                    <div className="col-xxl-7 col-lg-10 col-md-12 mx-auto">
                        <ul class="nav nav-tabs mb-3" id="ex1" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a
                                    class="nav-link active"
                                    id="ex2-tab-1"
                                    data-bs-toggle="tab"
                                    href="#ex2-tabs-1"
                                    role="tab"
                                    aria-controls="ex2-tabs-1"
                                    aria-selected="true"
                                >Tokens</a>
                            </li>
                            <li className="nav-item">
                                <a
                                    class="nav-link"
                                    id="ex2-tab-2"
                                    data-bs-toggle="tab"
                                    href="#ex2-tabs-2"
                                    role="tab"
                                    aria-controls="ex2-tabs-2"
                                    aria-selected="false"
                                >tBNB</a>
                            </li>
                        </ul>


                        <div className="approve_token_card">
                        <div class="tab-content" id="ex2-content">
                            <div
                                class="tab-pane fade show active"
                                id="ex2-tabs-1"
                                role="tabpanel"
                                aria-labelledby="ex2-tab-1"
                            >

                                <div className="form-group p-4">
                                    <label htmlFor="tokenAddress">Token Address</label>
                                    <input type="text" className={`create_token_input airdrop_input ${((!validateAddress(token) && token!="")|| isErrorToken) && 'invalid'}`} id="tokenAddress" value={token} 
                                    onChange={({target})=>{_token(target.value)}}
                                    />
                                {
                                   ((!validateAddress(token) && token!="")|| isErrorToken) && <span className="text-danger">Invalid token address</span>
                                }
                                {
                                    (isLoadingToken || isFetchingToken) && validateAddress(token)  &&  
                                    <div className="mt-2">
                                            <SyncLoader color="#3498DB" size={10}/>
                                     </div>
                                }
                                </div>
                                {/* <div className="alert alert-success">
                                    Whitelist (0x3c085b4903C77a5BD9170445A8BF9055fb1B56eC)
                                    <button className="btn btn-sm btn-secondary" type="button">Copy</button>
                                </div> */}
{/* 
                                    {
                                        (isLoadingToken || isFetchingToken) && validateAddress(token)  &&
                                        <div className="token_details_section_bottom " style={{ width: "50%", margin: "auto" }}>
                                         <SyncLoader color="#3498DB" size={10}/>
                                         </div>
                                    } */}

                                {
                                    preSaleToken &&
                                <div className="token_details_section_bottom">
                                    <hr />
                                    <div className="col col-md-8 mx-auto">

                                    <section className="mb-4">
                                        <div>
                                            <label>Token Details</label>
                                        </div>
                                        <ul className="text-white mt-1">
                                            <li className="mb-2 d-flex flex-wrap align-items-center justify-content-between airdroplist">
                                                Name<span>{preSaleToken?.name}</span>
                                            </li>
                                            <li className="mb-2 d-flex flex-wrap align-items-center justify-content-between airdroplist">
                                                Symbol<span>{preSaleToken?.symbol}</span>
                                            </li>
                                            <li className="mb-2 d-flex flex-wrap align-items-center justify-content-between airdroplist">
                                               Balance
                                                <span>
                                                { ethers.utils.formatUnits(String(balanceOf||0), preSaleToken?.decimals || 0).toString()}
                                                </span>
                                            </li>
                                            <li className="mb-2 d-flex flex-wrap align-items-center justify-content-between airdroplist">
                                               Allowance
                                                <span>
                                                { ethers.utils.formatUnits(String(isAllowanceData||0), preSaleToken?.decimals || 0).toString()}
                                                </span>
                                            </li>
                                        </ul>
                                        {/* <hr /> */}
                                    </section>
                                    <section className="airdrop_section">
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                {/* <div className="form-check">
                                                    <input className="form-check-input" 
                                                    
                                                    onChange={(event) => {
                                                        setIsChecked(event.target.checked);
                                                    }} type="checkbox" id="sameAmountCheckbox" defaultChecked />
                                                    <label className="form-check-label" htmlFor="sameAmountCheckbox">Same Amount for each address</label>
                                                </div> */}
                                                    <div className="material-switch d-flex align-items-center">
                                                    <input className="form-check-input" 
                                                    onChange={(event) => {
                                                        setIsChecked(event.target.checked);
                                                    }} type="checkbox" id="sameAmountCheckbox" defaultChecked />
                                                          <label htmlFor="sameAmountCheckbox" className="label-default" />
                                                          <p className="mb-0 me-3">Same Amount for each address</p>
                                                         </div>
                                            </div>
                                            <br />
                                            <div className="col-md-12 mb-3">
                                                {isChecked &&
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="totalAmount">Total Amount for airdrop</label>
                                                        <input type="text" 
                                                                style={{marginBottom:'0px !important'}}
                                                                // className="create_token_input form-control" 
                                                                className={`create_token_input airdrop_input ${formikAirdrop.errors.amount && formikAirdrop.touched.amount && 'invalid'}`}
                                                                id="totalAmount"
                                                                name="amount"
                                                                 value={formikAirdrop.values.amount}
                                                                //  onChange={formikAirdrop.handleChange}
                                                                onChange={(e) => {
                                                                    const inputValue = isNumberKey(e.target.value);
                                                                    // const numericValue = inputValue.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except dot (.)
                                                                    const numericValue = inputValue; // Remove non-numeric characters except dot (.)
                                                                    if (numericValue !== false) {
                                                                        if (e.target.value.length < preSaleToken?.decimals+2) {
                                                                            formikAirdrop.handleChange({
                                                                                target: {
                                                                                    name: "amount",
                                                                                    value: numericValue,
                                                                                },
                                                                            });
                                                                        }
                                                                    }
                                    
                                    
                                                                }}
                                                        />
                                                                  {formikAirdrop.errors.amount && formikAirdrop.touched.amount ? (
                                                            <span className="text-danger">{formikAirdrop.errors.amount}</span>
                                                        ) : null}
                                                    </div>
                                                }
                                                <div className="form-group">
                                                <div id="approve_token_form" className="text-start">
                                                    <label className="fieldlabels">Address list</label>
                                                    <textarea 
                                                     rows={6} 
                                                        className={`form-control ${formikAirdrop.errors.addresses && formikAirdrop.touched.addresses ? 'input_invalid':'sort_by_Project'}`} 
                                                        id="addressList" 
                                                        name="addresses" 
                                                          value={formikAirdrop.values.addresses}
                                                          onChange={formikAirdrop.handleChange}
                                                          onFocus={handleFocusAddressList}
                                                          onBlur={handleBlurAddressList}
                                                          placeholder={
                                                            isFocusAddressList?
                                                                "0x314ab97b76e39d63c78d5c86c2daf8eaa306b,\n0x271bffabd0f79b8bd4d7a1c245b7ec5b576ea,\n0x141ca95b6177615fb1417cf70e930e102bf8f"
                                                                :
                                                                'Address list'
                                                          }
                                                    />
                                                      {formikAirdrop.errors.addresses && formikAirdrop.touched.addresses ? (
                                                            <span className="text-danger">{formikAirdrop.errors.addresses}</span>
                                                        ) : null}
                                                        </div>
                                                </div>
                                                {
                                                    !isChecked &&
                                                <div className="form-group mt-3">
                                                    <div id="approve_token_form" className="text-start">
                                                    <label className="fieldlabels">Amount list</label>
                                                    <textarea rows={6} className={`form-control ${formikAirdrop.errors.diff_amount && formikAirdrop.touched.diff_amount ? 'input_invalid':'sort_by_Project'}`} id="addressList" name="diff_amount" 
                                                          value={formikAirdrop.values.diff_amount}
                                                          onChange={formikAirdrop.handleChange}
                                                          placeholder={isFocusAmountDiff?"1.2345,45,0.112":'Amount'}
                                                          onBlur={handleBlurDiffAm}
                                                          onFocus={handleFocusDiffAm}
                                                    />
                                                      {formikAirdrop.errors.diff_amount && formikAirdrop.touched.diff_amount ? (
                                                            <span className="text-danger">{formikAirdrop.errors.diff_amount}</span>
                                                        ) : null}
                                                </div>
                                                </div>
                                                }
                                            </div>
                                            <button type="button" className="btn btn-primary mt-4" style={{ width: "100px", margin: "auto" }}
                                            onClick={(event)=>{
                                                event.preventDefault();
                                                formikAirdrop.handleSubmit();
                                            }}
                                            disabled={isLoadingAllowance || isLoadingDispSameTokToAdd||isLoadingWaitForTransDispSameTokToAdd||isLoadingDispDiffTokToAdd||isLoadingWaitForTransDispDiffTokToAdd||isLoadingApprove||isLoadingWaitForTranasaction}
                                            >{isLoadingApprove||isLoadingWaitForTranasaction?'Approving...':isLoadingDispSameTokToAdd||isLoadingWaitForTransDispSameTokToAdd||isLoadingDispDiffTokToAdd||isLoadingWaitForTransDispDiffTokToAdd?'Sending...':'OK'}</button>
                                        </div>

                                    </section>
                                </div>
                                </div>
                                }
                            </div>

                                <Tbnb preSaleToken={preSaleToken}/>
                            {/* <div class="tab-pane fade" id="ex2-tabs-2" role="tabpanel" aria-labelledby="ex2-tab-2">
                                <div className="token_details_section_bottom" style={{ width: "50%", margin: "auto" }}>
                                    <section className="mt-4">
                                        <div className="row">
                                            <p className="text-center fw-bold" style={{color:"white"}}>You have tBNB</p>
                                              <div className="col-md-12 mb-3">
                                                <div className="form-check">
                                                    <input className="form-check-input" onChange={(event) => {
                                                        setIsCheckedBnb(event.target.checked)
                                                    }} type="checkbox" id="sameAmountCheckbox" defaultChecked />
                                                    <label className="form-check-label" htmlFor="sameAmountCheckbox">Same Amount for each address</label>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="col-md-12 mb-3">
                                                {isCheckedBnb && 
                                                <div className="form-group mb-3">
                                                    <label htmlFor="totalAmount">Total Amount for airdrop</label>
                                                    <input type="number" className="form-control" id="totalAmount" defaultValue={0} />
                                                </div>
                                                }
                                                <div className="form-group">
                                                    <label htmlFor="addressList">Address list</label>

                                                    <textarea style={{ background: "transparent", color: "white" }} rows={3} className="form-control" id="addressList" placeholder="0x314ab97b76e39d63c78d5c86c2daf8eaa306b182
0x271..." defaultValue={""} />
                                                </div>
                                            </div>
                                            <button type="button" className="btn btn-primary mt-4" style={{ width: "100px", margin: "auto" }}>OK</button>
                                        </div>

                                    </section>
                                </div>
                            </div> */}

                        </div>
                        </div>
                        {/* <div className="tab-content">
                            <div id="tokens" className="tab-pane fade show active">
                                <div className="form-group">
                                    <label htmlFor="tokenAddress">Token Address</label>
                                    <input type="text" className="form-control" id="tokenAddress" defaultValue="0x94593efd41804cd258ce18d219cc80b6b198c8c4" />
                                </div>
                               
                                <div className="token_details_section_bottom">
                                    <hr />
                                    <section>
                                        <div>
                                            <label>Token Details</label>
                                        </div>
                                        <ul className="text-white mt-1">
                                            <li className="d-flex flex-wrap align-items-center justify-content-between">
                                                Name<span>Name</span>
                                            </li>
                                            <li className="d-flex flex-wrap align-items-center justify-content-between">
                                                Symbol<span>symbol</span>
                                            </li>
                                            <li className="d-flex flex-wrap align-items-center justify-content-between">
                                                Total Supply
                                                <span>
                                                    10000
                                                </span>
                                            </li>
                                        </ul>
                                        <hr />
                                    </section>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="sameAmountCheckbox" defaultChecked />
                                            <label className="form-check-label" htmlFor="sameAmountCheckbox">Same Amount for each address</label>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="totalAmount">Total Amount for airdrop</label>
                                            <input type="number" className="form-control" id="totalAmount" defaultValue={0} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="addressList">Address list</label>
                                    <textarea className="form-control" id="addressList" placeholder="0x314ab97b76e39d63c78d5c86c2daf8eaa306b182
0x271..." defaultValue={""} />
                                </div>
                            </div>
                            <div id="tbnb" className="tab-pane fade">
                               
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Airdrop;
