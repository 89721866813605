import { useFormik } from "formik";
import { STAKE_MANAGER, blockInvalidChar, isNumberKey } from "../../_constant";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import StackingManagerABI from "../../_constant/StakingManager.json";
import { getAccount, getNetwork } from "@wagmi/core"
import { useAccount, useContractRead, useContractWrite, useWaitForTransaction } from "wagmi"
import { SUCCESSMSGS, TRANSACTIONMSGS } from "../../utils/messages";
import { toast } from "react-toastify";
import SimpleToken from "../../_constant/SimpleToken.json";
import { ethers } from "ethers";
import { PostErrorLogAction, myStakeAction, stakeAction } from "../../redux/apiActions/api.action";
import StackPoolABI from "../../_constant/StakePool.json";
import moment from "moment";

export default function Stake({ stackDetails, mystackGet,my_staking, getStakeDetails,startTime, endTime }) {

    const { address, isConnected } = useAccount();
    const { chain } = getNetwork()

    const STACK_MANAGER_ADDRESS = STAKE_MANAGER[chain?.network]

    const amountValidateSchema = Yup.object().shape({
        amount: Yup.number()
            .min(stackDetails && ethers.utils
                .formatUnits(String(stackDetails?.minAmountToStake || 0), JSON.parse(stackDetails?.stackTokenInfo)?.decimals || 0)
                .toString(),'Amount should be greater than or equal to min stake!').max(ethers.utils
                    .formatUnits(String(stackDetails?.hardCap || 0), JSON.parse(stackDetails?.stackTokenInfo)?.decimals || 0)
                    .toString(),'Amount should be less than or equal to hard cap!'),
    });


    const formik = useFormik({
        initialValues: {
            amount: ""
        },
        validationSchema: amountValidateSchema,
        onSubmit: async (data) => {
            try {

                if(data.amount==""){
                    return toast.error("Stake Amount is required!")
                }

                if(Number(ethers.utils
                    .formatUnits(String(isAllowanceData), Number(JSON.parse(stackDetails?.stackTokenInfo)?.decimals || 0))) < data?.amount){
                    approveToken({args:[stackDetails?.stakeContract,
                        String(ethers.utils.parseUnits(String(Number(data?.amount)),Number(stackDetails && JSON.parse(stackDetails?.stackTokenInfo)?.decimals || 0)))
                      ]})
                }else{
                    Stack({ args: [stackDetails?.stakeContract,
                        ethers.utils
                        .parseUnits(String(formik.values?.amount), Number(JSON.parse(stackDetails?.stackTokenInfo)?.decimals || 0))
                        .toString()    
                    ] })
                }
            } catch (error) {
            }
        }
    })


    const { data: isAllowanceData, isError: isErrorAllowance, isLoading: isLoadingAllowance, refetch: refetchAllowance } = useContractRead({
        address: stackDetails?.stakeToken,
        abi: SimpleToken.abi,
        functionName: "allowance",
        args: [address, stackDetails?.stakeContract],
        // onSuccess: onSuccessAllowance,
        // onError: o
      })

      const onApproveError = async (error) => {
        console.log("Error 75", error);
        PostErrorLogAction({ address: address || 0, other_detail: { error } });
        let errStr = error.toString().slice(0,53)
        if(errStr === "TransactionExecutionError: User rejected the request."){
          toast.error(TRANSACTIONMSGS.REJECTEDAPPROVING)
        }else{
        toast.error('Failed in approving!');
        }
      
      };

      const onSuccessApprove  = ()=>{

      }
    

        // Approve Token
        const {
            data: approvedData,
            write: approveToken,
            isLoading:isLoadingApprove,
            isSuccess: isApprovedSuccess,
          } = useContractWrite({
            /// PreSale token address
            // @ts-ignore
            abi: SimpleToken.abi,
            functionName: "approve",
            address:stackDetails?.stakeToken,
            onError: onApproveError,
            onSuccess:onSuccessApprove
          });

          const onSuccessWaitForTransaction = (data)=>{
            Stack({ args: [stackDetails?.stakeContract,
                ethers.utils
                .parseUnits(String(formik.values?.amount), Number(JSON.parse(stackDetails?.stackTokenInfo)?.decimals || 0))
                .toString()    
            ] })
            }
              /// Any api call
            const onApproveReceipt = async (data, err) => {
                toast.success(SUCCESSMSGS.APPROVED_SUCCESS)
            };

          const {isLoading:isLoadingWFTApprove} = useWaitForTransaction({
            hash: approvedData?.hash,
            onSettled: onApproveReceipt,
            onSuccess:onSuccessWaitForTransaction
          });


    const onStakeError = (error) => {
        PostErrorLogAction({ address: address || 0, other_detail: { error } });
        console.log('30 Error', error);
        let errStr = error.toString().slice(0, 25)
        if (errStr === "TransactionExecutionError") {
            toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT)
        }else if(error.toString().includes("stakeAmountIsLessThanMinStake")){
            toast.error("stake amount is less than min stake!")
        }else if(error.toString().includes("HardCapReached")){
            toast.error("Hard cap reached!")
        }else if(error.toString().includes("transfer amount exceeds balance")){
            toast.error("transfer amount exceeds balance!")
        }else if(error.toString().includes("insufficient allowance")){
            toast.error("insufficient allowance",{toastId:'insffAllTId'})
        }
         else {
            toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG)
        }
    }

    const { data: mystackAmountFC,refetch:refetchMyStackAmountFC } = useContractRead({
        address: stackDetails?.stakeContract,
        abi: StackPoolABI.abi,
        functionName: "balanceOf",
        args: [address],
        watch: true,
      });

    const {
        data: StakeData,
        write: Stack,
        isLoading: isLoadingStake,
        isSuccess: isSuccessStake,
    } = useContractWrite({
        address: STACK_MANAGER_ADDRESS,
        abi: StackingManagerABI.abi,
        functionName: 'stake',
        onError: onStakeError,
        // onSuccess: onSuccessApprove
    });

    const onSuccessStakeWFT = async(data) => {
        let refetchedAmount =await refetchMyStackAmountFC();
        refetchedAmount = refetchedAmount.data;
        
        let bodyData = {stakeContract:stackDetails?.stakeContract,reward_amount:ethers.utils.formatUnits(String(refetchedAmount), Number(JSON.parse(stackDetails?.stackTokenInfo)?.decimals || 0)),address:address,chain:chain?.id}
        const response = await stakeAction(bodyData)
        if(response){
            formik.resetForm()
            toast.success("Staked")
            mystackGet()
            getStakeDetails()
        }
    }

    const { isLoading: isLoadingStakeWFT } = useWaitForTransaction({
        hash: StakeData?.hash,
        onSuccess: onSuccessStakeWFT
    });




    const amountUnstakeValidateSchema = Yup.object().shape({
        amount: Yup.number()
            .max(my_staking?.amount,'Amount should be less than or equal to staked amount!')
            // .min(stackDetails && ethers.utils
            //     .formatUnits(String(stackDetails?.minAmountToStake || 0), JSON.parse(stackDetails?.stackTokenInfo)?.decimals || 0)
            //     .toString(),'Amount should be greater than or equal to min stake!'),
    });

    // Unstack
    const formikUnstake = useFormik({
        initialValues: {
            amount: ""
        },
        validationSchema: amountUnstakeValidateSchema,
        onSubmit: async (data) => {
            try {
                    // UnStack({ args: [stackDetails?.stakeContract,
                    //     ethers.utils
                    //     .parseUnits(String(data?.amount), Number(JSON.parse(stackDetails?.stackTokenInfo)?.decimals || 0))
                    //     .toString()    
                    // ] })
                    if(data.amount==""){
                        return toast.error("Unstake amount is required!",{toastId:'samirTid'})
                    }
                    UnStack({ args: [stackDetails?.stakeContract,
                        ethers.utils
                        .parseUnits(String(data?.amount), Number(JSON.parse(stackDetails?.stackTokenInfo)?.decimals || 0))
                        .toString()    
                    ] })
            } catch (error) {
            }
        }
    })

    const {
        data: UnStakeData,
        write: UnStack,
        isLoading: isLoadingUnStake,
        isSuccess: isSuccessUnStake,
    } = useContractWrite({
        address: STACK_MANAGER_ADDRESS,
        abi: StackingManagerABI.abi,
        functionName: 'unStake',
        onError: onStakeError,
        // onSuccess: onSuccessApprove
    });

    const onSuccessUnStakeWFT = async() => {
        let refetchedAmount =await refetchMyStackAmountFC()
        refetchedAmount=refetchedAmount.data;
        let bodyData = {stakeContract:stackDetails?.stakeContract,reward_amount:ethers.utils.formatUnits(String(refetchedAmount), Number(JSON.parse(stackDetails?.stackTokenInfo)?.decimals || 0)),address:address,chain:chain?.id}
        const response = await stakeAction(bodyData)
        if(response){
            formikUnstake.resetForm()
            toast.success("Unstaked")
            mystackGet()
            getStakeDetails()
        }

    }

    const { isLoading: isLoadingUnStakeWFT } = useWaitForTransaction({
        hash: UnStakeData?.hash,
        onSuccess: onSuccessUnStakeWFT
    });



    // balanceOf      

    const { data: balanceOf } = useContractRead({
        /// PreSale token address
        // @ts-ignore
        address: stackDetails?.stakeToken,
        abi: SimpleToken.abi,
        functionName: "balanceOf",
        args: [address],
        // onError: onTokenFetchError,
    });

  const currentUnixTime = moment().unix();
    
    return (
        <>

            {
                 (currentUnixTime >= startTime && endTime > currentUnixTime) &&
                 <>
            <div className="col mt-3">
                <div className="contribute_amount_input">
                    <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={blockInvalidChar}
                        name="amount"
                        placeholder="Stake amount"
                        className={`no-arrow ${formik.errors.amount && 'invalid'}`}
                        // onChange={formik.handleChange}
                        value={formik.values.amount}
                        // onChange={(e) => {
                        //     const inputValue = isNumberKey(e.target.value);
                        //     const numericValue = inputValue; // Remove non-numeric characters except dot (.)
                        //     if (numericValue !== false) {
                        //         if (e.target.value.length < 21) {
                        //             formik.handleChange({
                        //                 target: {
                        //                     name: "amount",
                        //                     value: numericValue,
                        //                 },
                        //             });
                        //         }
                        //     }


                        // }}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            const decimalPart = inputValue.split(".")[1];
                            let limitedNumericValue = inputValue;
                            if (decimalPart && decimalPart.length > 18) {
                              limitedNumericValue = inputValue.split(".")[0] + "." + decimalPart.slice(0, 18);
                            }
                            formik.handleChange({
                              target: {
                                name: "amount",
                                value: limitedNumericValue,
                              },
                            });
                          }}
                          disabled={isLoadingApprove||isLoadingWFTApprove || isLoadingStakeWFT||isLoadingStake }
                    />
                </div>
                <p className="text-danger">{formik.errors.amount}</p>
            </div>

            <div className="col">
                <button 
                // className={`btn_buy btn`} 
                className="btn btn-primary btn-block mt-0 btn_managepool"
                type="button"
                    onClick={(e) => {
                        e.preventDefault()
                        formik.handleSubmit()
                    }
                    }
                    disabled={isLoadingApprove||isLoadingWFTApprove || isLoadingStakeWFT||isLoadingStake 
                        || Number(balanceOf)==0 || Number(formik.values.amount) > Number(ethers.utils
                        .formatUnits(String(balanceOf || 0), stackDetails && Number(JSON.parse(stackDetails?.stackTokenInfo)?.decimals || 0)))
                    }
                >
                    {isLoadingApprove || isLoadingWFTApprove ? 'Approving...':isLoadingStake ||isLoadingStakeWFT ?'Staking...': 'Stake'}
                </button>
            </div>
                 </>
            }



            {/* Unstack */}
            {
                my_staking && Number(my_staking?.amount) > 0 &&
                <>
            <div className="col mt-4">
                <div className="contribute_amount_input">
                    <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={blockInvalidChar}
                        name="amount"
                        placeholder="Unstake amount"
                        className={`no-arrow ${formikUnstake.errors.amount && 'invalid'}`}
                        // onChange={formik.handleChange}
                        value={formikUnstake.values.amount}
                        // onChange={(e) => {
                        //     const inputValue = isNumberKey(e.target.value);
                        //     const numericValue = inputValue; // Remove non-numeric characters except dot (.)
                        //     if (numericValue !== false) {
                        //         if (e.target.value.length < 21) {
                        //             formikUnstake.handleChange({
                        //                 target: {
                        //                     name: "amount",
                        //                     value: numericValue,
                        //                 },
                        //             });
                        //         }
                        //     }


                        // }}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            const decimalPart = inputValue.split(".")[1];
                            let limitedNumericValue = inputValue;
                            if (decimalPart && decimalPart.length > 18) {
                              limitedNumericValue = inputValue.split(".")[0] + "." + decimalPart.slice(0, 18);
                            }
                            formikUnstake.handleChange({
                              target: {
                                name: "amount",
                                value: limitedNumericValue,
                              },
                            });
                          }}
                          disabled={isLoadingUnStake ||isLoadingUnStakeWFT}
                    />
                </div>
                <p className="text-danger">{formikUnstake.errors.amount}</p>
            </div>

            <div className="col">
                <button 
                // className={`btn_buy btn`} 
                className="btn btn-primary btn-block mt-0 btn_managepool"
                type="button"
                    onClick={(e) => {
                        e.preventDefault()
                        formikUnstake.handleSubmit()
                    }
                    }
                    disabled={isLoadingUnStake ||isLoadingUnStakeWFT}
                >
                    {isLoadingUnStake ||isLoadingUnStakeWFT ?'Unstaking...': 'Unstake'}
                </button>
            </div>
                </>
            }


        </>
    )
}