import { ethers } from "ethers";
import ReactApexChart from "react-apexcharts"
import { SALE_TYPE } from "../../_constant";
import { useEffect, useState } from "react";

export function TokenomicsPieChart({ presaleDetails }) {

  function abbreviateNumber(value) {
    

    const newValue = Math.abs(value) >= 1.0e+9
      ? (Math.abs(value) / 1.0e+9).toFixed(2) + "B"
      : Math.abs(value) >= 1.0e+6
        ? (Math.abs(value) / 1.0e+6).toFixed(2) + "M"
        : Math.abs(value) >= 1.0e+3
          ? (Math.abs(value) / 1.0e+3).toFixed(2) + "k"
          : Math.abs(value).toFixed(2);

    return newValue;
  }

  if (!presaleDetails) {
    return null;
  }

  const {
    hardCap,
    preSaleRate,
    custom_fund_token_decimal,
    token_decimal,
    saleType,
    preSaleAmount,
    preSaleRatePercentage,
    isTeamVesting,
    isPreSaleVesting,
    supply,
    vestingAmount,
    dexLiquidityPercentage,
    rounds
  } = presaleDetails;

  console.log(presaleDetails, 'rounds')
  const hardCapBigInt = BigInt(hardCap);   // eslint-disable-line no-undef
  const rateBigInt = BigInt(preSaleRate);   // eslint-disable-line no-undef

  // const amountActual =
  //   (hardCapBigInt * rateBigInt) / BigInt(10) ** BigInt(custom_fund_token_decimal); // eslint-disable-line no-undef

  // const presaleAmountFormatted = ethers.utils.formatUnits(
  //   amountActual.toString(),
  //   token_decimal
  // );

  // const totalFormatted = ethers.utils.formatUnits(Math.floor(supply || 0), token_decimal);

  // const presaleAmountValue =
  //   saleType === SALE_TYPE.PRESALE
  //     ? presaleAmountFormatted
  //     : ethers.utils.formatUnits(String(preSaleAmount), Number(token_decimal || 0));



      let number = parseFloat(supply); // Convert string to float
      let roundedNumber = Math.floor(number); // Round down the number
      let roundedSupply = roundedNumber.toLocaleString('fullwide', {useGrouping:false});// Convert the number to a string without scientific notation
      

      // -------
      
      let amountActual = 0n;
      rounds.forEach((round)=>{
        const rate = BigInt(round.preSaleRate);
        amountActual += (hardCapBigInt * rate) / BigInt(10) ** BigInt(custom_fund_token_decimal); // eslint-disable-line no-undef
      })
      const presaleAmountFormatted = ethers.utils.formatUnits(
        amountActual.toString(),
        token_decimal
      );
      
      const presaleAmountValue =
      saleType === SALE_TYPE.PRESALE
        ? presaleAmountFormatted
        : ethers.utils.formatUnits(String(preSaleAmount), Number(token_decimal || 0));
  
      // -------
  const total = Number(ethers.utils.formatUnits(roundedSupply || 0, token_decimal));

  const Presale = saleType === SALE_TYPE.PRESALE
    ? presaleAmountFormatted
    : ethers.utils.formatUnits(String(preSaleAmount), Number(token_decimal || 0));

  const PresalePercent = saleType === SALE_TYPE.PRESALE
    ? Number((presaleAmountFormatted * 100) / total).toFixed(2)
    : Number((Number(ethers.utils.formatUnits(String(preSaleAmount), Number(token_decimal || 0))) * 100) / total).toFixed(2);


  let dynamicPresaleRatePer;

  if (saleType == SALE_TYPE.PRESALE) {
    const dexLiquidityPercentage1 = BigInt(presaleDetails?.dexLiquidityPercentage); // eslint-disable-line no-undef
    const dexListingRate = BigInt(presaleDetails?.dexListingRate); // eslint-disable-line no-undef
    const amountActualToLiq =
      (BigInt(hardCap) * BigInt(dexListingRate) * BigInt(dexLiquidityPercentage1)) /    // eslint-disable-line no-undef
      BigInt(10) ** BigInt(Number(custom_fund_token_decimal) + 2);  // eslint-disable-line no-undef

    const amountFormattedToLiq = ethers.utils.formatUnits(
      amountActualToLiq.toString(),
      Number(token_decimal)
    );
    dynamicPresaleRatePer = amountFormattedToLiq
  } else {
    const fundRaisingTokenDecimal = token_decimal;
    const fairLaunchTokenForLiq = ((BigInt(preSaleAmount) * BigInt(preSaleRatePercentage) * BigInt(dexLiquidityPercentage)) / BigInt(10000));     // eslint-disable-line no-undef
    const parsefFair = ethers.utils.formatUnits(String(BigInt(fairLaunchTokenForLiq)), Number(fundRaisingTokenDecimal || 0)).toString()  // eslint-disable-line no-undef
    dynamicPresaleRatePer = parsefFair
  }

  const Liquidity = dynamicPresaleRatePer;


  const LiquidityPercent = Number((Liquidity * 100) / total);
  

  const total_vesting_amount =
    Number(ethers.utils
      .formatUnits(String(vestingAmount), Number(token_decimal || 0)))

  const total_vesting_amount_percent = Number(total_vesting_amount * 100) / total;

  const Locked =
    (isTeamVesting == 1 && isPreSaleVesting == 1)
      ?
      Number(presaleAmountValue) + Number(ethers.utils.formatUnits(String(vestingAmount), Number(token_decimal || 0)))
      :
      isTeamVesting == 1 ?
        Number(ethers.utils.formatUnits(String(vestingAmount), Number(token_decimal || 0)))
        :
        isPreSaleVesting == 1 ? presaleAmountValue
          :
          0

  const LockedPercentage = (Locked * 100) / total;


  const useSupply = presaleDetails?.saleType == SALE_TYPE.PRESALE ? 
   presaleDetails?.launch_stealth_sale == 1 ? presaleDetails?.supply :
  (presaleDetails?.supply && presaleDetails?.token_decimal) &&
  ethers.utils.formatUnits(String(presaleDetails?.supply), Number(presaleDetails?.token_decimal))
    .toString()
  : presaleDetails?.supply


  const Unlocked = useSupply - (Number(presaleAmountValue) + Number(Liquidity) + Number(total_vesting_amount) + Number(Locked))

  
  const UnlockedPercentage = (Unlocked * 100) / total;

  

      
  let chartData = [];
  
  if(presaleDetails?.tokenomics && presaleDetails.tokenomics.length > 0){
    chartData = [
      ...presaleDetails.tokenomics.map(item=> ({ label: item.name, value: Number(item.value)})),
      { label: "Unlocked", value: useSupply - presaleDetails.tokenomics.reduce((pre, curr)=> pre + Number(curr.value), 0)}
    ]
  }else {
    if(Number(presaleAmountValue) !== 0){
      chartData.push({ label: 'Presale', value: Number(presaleAmountValue)})
    }
    if(Number(Liquidity) !== 0){
      chartData.push({ label: 'Liquidity', value: Number(Liquidity)})
    }
    if(Number(total_vesting_amount) !== 0){
      chartData.push({ label: 'Team Vesting', value: Number(total_vesting_amount)})
    }
    // if(Number(Locked) !== 0){
      //   chartData.push({ label: 'Locked', value: Number(Locked)})
      // }
      if(Number(Unlocked) !== 0){
        chartData.push({ label: 'Unlocked', value: Number(Unlocked)})
      }
    }


  console.log(chartData, 'chartData')
  return (
    // <div className="container d-flex justify-content-center">

    <ReactApexChart
    // height={'100%'}
    // width={'100%'}

      options={{
        colors: ['#E9E968','#42A1AE', '#56D948','#F05D5D', '#B874F3', '#7D9AFB'],
        // colors: ['#a74af9', '#219a15', '#09adb3' , '#377179', "#ff4843"],
        chart: {
          type: 'donut',
          foreColor: '#ffffff',
        },
        // labels: ['Presale', 'Liquidity', 'Team Vesting', 'Locked', 'Unlocked'],
        labels: chartData.map(item=> item.label),
      
        dataLabels: {
          enabled: false,
          // style:{fontSize: '10px'},
          dropShadow: {
            blur: 3,
            opacity: 0.8,

          },
          // formatter: (value)=>{}
        },
        responsive: [
          {
            breakpoint: 1189,
            options: {
              chart: {
                // width: 350,
                height:400
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 480,
            options: {
              chart: {
                // width: 350,
                height:400
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 320,
            options: {
              chart: {
                width: '100%',
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
        stroke: {
          width: 0,
        },
        states: {
          hover: {
            filter: 'none'
          }
        },
        plotOptions: {
          pie: {
            customScale: "0.9",
            donut: {
              size: "80%",
              labels: {
                show: true,
                total: {
                  show: true,
                  label: 'Total',

                  // formatter: function (w) {
                  //   // return w.globals.seriesTotals.reduce((a, b) => {
                  //   //   return a + b;
                  //   // }, 0);
                  //   return presaleDetails?.launch_stealth_sale == 1 ? presaleDetails?.supply :
                  //   (presaleDetails?.supply && presaleDetails?.token_decimal) &&
                  //   ethers.utils.formatUnits(String(presaleDetails?.supply), Number(presaleDetails?.token_decimal))
                  //     .toString()
                  // },
                  
                  formatter: function (w) {
                    console.log(w, 'total')
                    // return '100%'
                    const totalValue = presaleDetails?.launch_stealth_sale === 1
                      ? presaleDetails?.supply
                      : (presaleDetails?.supply && presaleDetails?.token_decimal) &&
                      ethers.utils.formatUnits(String(presaleDetails?.supply), Number(presaleDetails?.token_decimal)).toString();
                      if(totalValue.length > 13){
                        return Number(totalValue).toExponential(0);
                      }else{
                        return abbreviateNumber(totalValue);
                      }
                  },

                  style: {
                    colors: '#fff', // Set label color to white
                  },
                },
                value:{
                  show:true,
                  fontFamily:'Rajdhani Medium', //want some bold comment this
                  fontWeight:'bold',
                  fontSize: "30px",
                  formatter:function(val){
                    console.log(val, 'value')
                    const number = `${val < 0 ? '-' : ''}` + abbreviateNumber(val);
                    const formattedNumber = String(number).replace(/\.?0*$/, '');
                    if (formattedNumber.length > 13) {
                        return Number(val).toExponential(0);
                    }
                    return formattedNumber;
                  }
                }
              },
            },
          },
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'left',
          offsetY: 10,
          formatter: function(seriesName, opts) {
            var percent = opts.w.globals.seriesPercent[opts.seriesIndex];
            return '<span style="display:inline-block;width:120px;text-align:left;">' + seriesName + ': ' + Number(percent).toFixed(2) + '%</span>';
          }
        },
        tooltip: {
          enabled: false,
          // hideEmptySeries: true,
          // fillSeriesColor: false,
          // theme: false,

          style: {
            fontSize: '14px',
            fontFamily: undefined,
            // border:'1px solid red'
          },
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex }) {
              // console.log(chartData[seriesIndex], value, series, seriesIndex, dataPointIndex, 'value searies')
              return value || chartData[seriesIndex].value;
              // if (seriesIndex === 0) {
              //   // return abbreviateNumber(parseFloat(presaleAmountValue));
              //   return presaleAmountValue;
              // } else if (seriesIndex === 1) {
              //   // return abbreviateNumber(parseFloat(Liquidity));
              //   return Liquidity;
              // } else if (seriesIndex === 2) {
              //   // return abbreviateNumber(parseFloat(total_vesting_amount));
              //   return total_vesting_amount;
              // } else if (seriesIndex === 3) {
              //   // return abbreviateNumber(parseFloat(Locked));
              //   return Locked
              // } else {
              //   // return abbreviateNumber(parseFloat(Unlocked));
              //   return Unlocked;
              // }
            },
          },
        },
      }}
      // series={[Number(presaleAmountValue),20,30,0,10]}
      series={chartData.map(item=> item.value)}
      type="donut"
    />
      // </div>
  )
}