import React, { useState } from 'react';
import Sidebar from '../sidebar/Sidebar';
import Header from '../header/header';
// import Footer from "../footer/footer";

export default function Layout(props) {
  const [isSidebarHalf, setIsSidebarHalf] = useState(false);

  const handleSidebarToggle = (e) => {
    setIsSidebarHalf(!isSidebarHalf);
  };

  return (
    <>
      <Header handleSidebarToggle={handleSidebarToggle} />
      <Sidebar isSidebarHalf={isSidebarHalf} />
      <div
        className={`body-content side-bar  ${
          isSidebarHalf ? 'side-bar-half' : ''
        }`}
      >
        {props.children}
      </div>
      {/* <Footer /> */}
    </>
  );
}
