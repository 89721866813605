import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Application from "./Application";
import ServiceContextProvider from "./context/service.context";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import * as userSlice from "./redux/reducers/user";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import app from './messaging_init_in_sw'
function App() {
  // const analytics = getAnalytics(app);
  const dispatch = useDispatch();

  const [notificationToken, setNotificationToken] = useState(null);
  // const messaging = getMessaging(app);
  // useEffect(() => {
  //   const storedToken = localStorage.getItem("notificationToken");
  //   if (storedToken) {
  //     setNotificationToken(storedToken);
  //   }
  //   const activateMessages = async () => {
  //     const permission = await Notification.requestPermission();
  //     if (permission === "granted") {
  //       const token = await getToken(messaging, {
  //         vapidKey: "BEGldjOE5ohFfKpt49-TvxVtjtp9J73157FV_3C4-gG3_e1zY-KU0rUyGODwNnbiQak5Q259YPxBbmVIofLLDuc",
  //       }).catch((error) => console.log("error generating token", error));

  //       if (token) {
  //         localStorage.setItem("notificationToken", token);
  //         setNotificationToken(token);
  //       }
  //       if (!token) console.log("no token");
  //     }
  //   };
  //   activateMessages();
  //   onMessage(messaging, (message) => {
  //     console.log(message)
  //     toast.success(message?.data?.title, message?.data?.body);
  //   });
  // }, [messaging, notificationToken]);
  
  dispatch(
    userSlice.search("")
  );

  return (
    <>
      <ToastContainer hideProgressBar={true} autoClose={2000} theme="colored" style={{
        fontSize: 'smaller'
      }}
        closeButton={false}
      />
      <ServiceContextProvider>
        <Router>
          <Application notificationToken={notificationToken} />
        </Router>
      </ServiceContextProvider>


    </>
  );
}

export default App;
