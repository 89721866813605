import { ethers } from "ethers";
import { BASE_URL, PerkCategories, SALE_TYPE, networkImagesByChain } from "../../_constant";
import moment from "moment";
import { useEffect, useState } from "react";
import { formatUnits } from "viem";
import PersaleCountDown from "../../Components/CountDown/PersaleCountDown";
export default function QuickView({ presales, fundRaisedData, loading }) {
  console.log(presales, 'presales')
  const [currentUnixTime, setCurrentUnixTime] = useState(moment().unix());
  return (
    <div className="col">
      {!loading &&
        <div class="container mt-3">
          <div className="table-responsive">
            <table class="table quick_view_table">
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Hc</th>
                  <th>Coin</th>
                  <th>Estimate MC</th>
                  {/* <th>BuyTax</th> */}
                  {/* <th>SellTax</th> */}
                  <th>KYC/Audit/Vetted</th>
                  <th>Status</th>
                  <th>Links</th>
                  {/* <th>TG Status</th> */}
                  {/* <th>TW Status</th> */}
                  <th>CountDown</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {presales && presales.length > 0 ? (
                  presales.map((data, key) => {
                    
                    let currentRound;
                            
                    if(data?.endTimes){
                      data?.endTimes?.split(',')?.forEach((endTime, index)=>{
                        if(!currentRound && endTime >= currentUnixTime){
                          currentRound = { roundNumber: index+1, startTime: data.startTimes.split(',')[index], endTime };
                          return; 
                        }
                      })
                    }
                    const startTime = Number(currentRound?.startTime || 0);
                    const endTime = Number(currentRound?.endTime || 0);
                    console.log(startTime, endTime, 'startTime')
                    // const startTime = Number(data?.startTime);
                    // const endTime = Number(data?.endTime);

                    let classNameD = "sale_upcoming";
                    let statusText = "Upcoming";
                    let startEndIn = "TBA";

                    if (currentUnixTime >= startTime && endTime > currentUnixTime) {
                      classNameD = "sale_live";
                      statusText = "Sale Live";
                      startEndIn = "Sale Ends In";
                    }

                    if (data?.isCanceled == 1) {
                      statusText = 'Sale Cancelled'
                      classNameD = "sale_upcoming";
                    } else if (data?.setSaleTime == 0) {
                      statusText = 'TBA'
                    } else if (data?.isFinalized == 1) {
                      statusText = 'Listed on dex'
                      startEndIn = 'Listing Time'
                      classNameD = 'listed_on_dex'
                    }
                    else if (currentUnixTime >= startTime && endTime > currentUnixTime) {
                      classNameD = "sale_live";
                      statusText = "Sale Live";
                      startEndIn = "Sale Ends In";
                    } else if (currentUnixTime < startTime) {
                      classNameD = "sale_upcoming";
                      statusText = "Upcoming";
                      startEndIn = "Sale Start In";
                    } else if (currentUnixTime > endTime) {
                      statusText = "Sale Ended";
                      classNameD = 'sale_end';
                      startEndIn = "Sale Ended";

                    }

                    let progressbarFair;
                    if (fundRaisedData != undefined) {
                      if (data?.isHardCap == 1 && data?.hardCap != undefined && fundRaisedData != undefined) {
                        const fundRaised = Number(ethers.utils.formatUnits(fundRaisedData[key]?.result || 0, data?.custom_fund_token_decimal));
                        const hardCap_format = Number(ethers.utils.formatUnits(data?.hardCap, data?.custom_fund_token_decimal));
                        progressbarFair = (fundRaised * 100) / hardCap_format;
                        // _progressBar(progress)
                      }
                      else if (BigInt(fundRaisedData[key]?.result) > BigInt(data?.softCap)) {  // eslint-disable-line no-undef
                        const fundRaised = Number(ethers.utils.formatUnits(fundRaisedData[key]?.result || 0, data?.custom_fund_token_decimal));
                        progressbarFair = (fundRaised * 100) / fundRaised;
                        // _progressBar(progress)
                      } else {
                        const fundRaised = Number(ethers.utils.formatUnits(fundRaisedData[key]?.result || 0, data?.custom_fund_token_decimal));
                        const softCap_format = Number(ethers.utils.formatUnits(data?.softCap, data?.custom_fund_token_decimal));
                        progressbarFair = (fundRaised * 100) / softCap_format;
                        // _progressBar(progress)
                      }
                    }


                    const preogressBar = presales?.saleType == SALE_TYPE.PRESALE ? fundRaisedData != undefined && Number(ethers.utils.formatUnits(String(fundRaisedData[key]?.result), data?.custom_fund_token_decimal) * 100 / Number(ethers.utils.formatUnits(String(data?.hardCap), data?.custom_fund_token_decimal))) : progressbarFair;

                    return (
                      <tr>
                        <td>
                          <img
                            src={networkImagesByChain[data?.chain]}
                            className="img-fluid"
                            alt="icon"
                            width={28}
                          />
                        </td>
                        <td>
                          {
                            (data?.sale_without_token == 1 && startTime == "0") ?
                              data?.sale_title :
                              data?.launch_stealth_sale == true ? data?.sale_title :
                                data?.name}

                        </td>
                        <td>
                          {ethers.utils.formatUnits(String(data?.hardCap), data?.custom_fund_token_decimal)}
                        </td>
                        <td>BNB</td>
                        <td>{Number(data?.estimated_mc).toFixed(2)}</td>
                        {/* <td>-</td> */}
                        {/* <td>-</td> */}
                        <td>
                          <ul className="d-flex gap-1">
                            {
                              data?.perk != PerkCategories.STANDARD ?
                                <>
                                  <li>
                                    <img
                                      src="images/presale/tick.png"
                                      className="img-fluid"
                                      alt="icon"
                                      width={20}
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src="images/presale/tick.png"
                                      className="img-fluid"
                                      alt="icon"
                                      width={20}
                                    />
                                  </li>
                                </>
                                :
                                <>
                                  <li>
                                    <img
                                      src="images/presale/close.png"
                                      className="img-fluid"
                                      alt="icon"
                                      width={20}
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src="images/presale/close.png"
                                      className="img-fluid"
                                      alt="icon"
                                      width={20}
                                    />
                                  </li>
                                </>
                            }
                            {
                              data?.isTeamVesting == 1 ?
                                <li>
                                  <img
                                    src="images/presale/tick.png"
                                    className="img-fluid"
                                    alt="icon"
                                    width={20}
                                  />
                                </li>
                                :
                                <li>
                                  <img
                                    src="images/presale/close.png"
                                    className="img-fluid"
                                    alt="icon"
                                    width={20}
                                  />
                                </li>

                            }
                            {/* <li>
                              <img
                                src="images/presale/close.png"
                                className="img-fluid"
                                alt="icon"
                                width={20}
                              />
                            </li>
                            <li>
                              <img
                                src="images/presale/close.png"
                                className="img-fluid"
                                alt="icon"
                                width={20}
                              />
                            </li> */}
                            {/* <li>
                              <img
                                src="images/presale/tick.png"
                                className="img-fluid"
                                alt="icon"
                                width={20}
                              />
                            </li> */}
                          </ul>
                        </td>
                        <td>
                          <div className="progress" style={{ backgroundColor: 'rgb(33, 43, 54)' }}>
                            <div className="progress-bar progress-bar-info text-danger" role="progressbar" aria-valuenow={90} aria-valuemin={0} aria-valuemax={100} style={{ width: `${(Number(moment(new Date()).unix()) >= Number(startTime)) ? Number(preogressBar || 0) : 0}%`, overflow: 'visible' }}>
                              {
                                (fundRaisedData != undefined && Number(ethers.utils.formatUnits(String(fundRaisedData[key]?.result), Number(data?.custom_fund_token_decimal))) < Number(ethers.utils.formatUnits(String(data?.softCap), Number(data?.custom_fund_token_decimal)))) && currentUnixTime > endTime && data?.isCanceled == 0 && data?.setSaleTime == 1 ?
                                  "Sale Failed"
                                  :
                                  Number(moment(new Date()).unix()) >= Number(startTime) ?
                                    <>
                                      <span className="text-light">{preogressBar || 0}</span>
                                    </>
                                    :
                                    <span className="text-warning">Upcoming</span>
                              }
                            </div>
                          </div>

                        </td>
                        <td>
                          <ul className="d-flex gap-1" style={{ cursor: 'pointer' }}>
                            {
                              data?.telegramUrl != "" &&
                              <li
                                onClick={() => { window.open(data?.telegramUrl) }}
                              >
                                <img
                                  src="images/presale/telegram.png"
                                  className="img-fluid"
                                  alt="icon"
                                  width={20}
                                />
                              </li>
                            }
                            {
                              data?.twitterUrl != "" &&
                              <li
                                onClick={() => { window.open(data?.twitterUrl) }}
                              >
                                <img
                                  src="images/presale/twitter.png"
                                  className="img-fluid"
                                  alt="icon"
                                  width={20}
                                />
                              </li>
                            }
                            {
                              data?.websiteUrl != "" &&
                              <li
                                onClick={() => { window.open(data?.websiteUrl) }}
                              >
                                <img
                                  src="images/presale/wordwide.png"
                                  className="img-fluid"
                                  alt="icon"
                                  width={20}
                                />
                              </li>
                            }
                            {/* <li>
                                <img
                                  src="images/presale/group.png"
                                  className="img-fluid"
                                  alt="icon"
                                  width={20}
                                />
                              </li> */}
                          </ul>
                        </td>
                        {/* <td>-</td> */}
                        {/* <td>-</td> */}
                        <td>
                          {(fundRaisedData != undefined && Number(ethers.utils.formatUnits(String(fundRaisedData[key]?.result), Number(data?.custom_fund_token_decimal))) < Number(ethers.utils.formatUnits(String(data?.softCap), Number(data?.custom_fund_token_decimal)))) && currentUnixTime > endTime && data?.isCanceled == 0 && data?.setSaleTime == 1 ?  //eslint-disable-line no-undef
                            <span className="text-danger">Failed</span>
                            :
                            data?.isCanceled === 1 ?
                              <span className="text-secondary">Cancelled</span>
                              :
                              data?.isFinalized === 1 && moment(new Date()).unix() > data?.estimatedDexListingTime ?
                                "Listed"
                                :
                                data?.setSaleTime === 1 ? (
                                  <>
                                    {(Number(moment(new Date()).unix()) < data?.estimatedDexListingTime && data?.isFinalized == 1) ? (
                                      <span>
                                        <PersaleCountDown unixTime={data?.estimatedDexListingTime} />
                                      </span>
                                    ) : (
                                      <>
                                        {Number(moment(new Date()).unix()) >= Number(startTime) && Number(moment(new Date()).unix()) < Number(endTime) ? (
                                          // <PersaleCountDown unixTime={data?.endTime} />
                                          'Live'
                                        ) : Number(moment(new Date()).unix()) < Number(startTime) ? (
                                          <PersaleCountDown unixTime={startTime} />
                                        ) : 'Ended'}
                                      </>
                                    )}
                                  </>
                                )
                                  : 'Upcoming'

                          }
                        </td>
                        <td>
                          <a href={`${BASE_URL}presale?presale_id=${data?.preSale}&chainId=${data?.chain}`} target="_blank">
                            View
                          </a>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr
                  //  className="d-flex justify-content-center w-100 fs-7"
                   >
                  <td colSpan={10} className="text-center">

                    No Data
                  </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      }
    </div>
  )
}