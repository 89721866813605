import config from "../../config";
import { fetch } from "../axios.service"

export const addErrorLog = data => {
  return fetch("post", `${config.server_base_url}/error-log`, data);
};

export const PostPresale = data => {
  return fetch("post", `${config.server_base_url}/post_presale`, data);
};

export const PostSpecialSale = data => {
  return fetch("post", `${config.server_base_url}/post-special-sale`, data);
};

export const getPresaleService = (data) => {
  return fetch("get", `${config.server_base_url}/get_presale?active_tab=${data.activeTab}&wallet=${data.wallet}&chain_id=${data.chain_id}&search=${data.search}&sortBy=${data.sortBy}&filterBy=${data.filterBy}&pageSize=${data.pageSize}&currentPage=${data.currentPage}${data.mode === 'test' ? '&mode=test' : ''}`, {}, {});
}

export const getSpecialSaleService = (data) => {
  return fetch("get", `${config.server_base_url}/special-sales?active_tab=${data.activeTab}&wallet=${data.wallet}&chain_id=${data.chain_id}&search=${data.search}&sortBy=${data.sortBy}&filterBy=${data.filterBy}&pageSize=${data.pageSize}&currentPage=${data.currentPage}`, {}, {});
}

export const favouriteService = (data, params) => {
  return fetch("post", `${config.server_base_url}/favourite`, data);
}

export const remnderService = (data, params) => {
  return fetch("post", `${config.server_base_url}/webpush`, data);
}
export const createTokenService = (data) => {
  return fetch("post", `${config.server_base_url}/create_token`, data);
}


export const getcurrentBnbPriceService = (data) => {
  return fetch("get", `${config.server_base_url}/coin_price?symbol=${data?.symbol}`, {}, {});
}


export const getPresaleDetailService = (data) => {
  return fetch("get", `${config.server_base_url}/presale_details/${data.id}`, {}, {});
}

export const getSpecialDetailService = (data) => {
  return fetch("get", `${config.server_base_url}/special_details/${data.id}`, {}, {});
}

export const postInvestService = (data) => {
  return fetch("post", `${config.server_base_url}/invest`, data);
}

export const saveBlockTransactionsService = (data) => {
  return fetch("post", `${config.server_base_url}/block_transactions`, data);
}

export const getInvestDetails = (data) => {
  return fetch("get", `${config.server_base_url}/invest_details?address=${data.address}&preSale=${data.preSale}&chain=${data.chain}`, {}, {});
}

export const deleteContributionService = (data) => {
  return fetch("put", `${config.server_base_url}/delete-contribution`, data, {});
}

export const updatePresaleDetails = (data) => {
  return fetch("put", `${config.server_base_url}/update-presale-info`, data, {});
}

export const cancelPresaleService = (data) => {
  return fetch("put", `${config.server_base_url}/cancel-preasale`, data, {});
}

export const extendLiquidityLockService = (data) => {
  return fetch("put", `${config.server_base_url}/extend-liquidity-lock`, data, {});
}


export const presaleStartService = (data) => {
  return fetch("put", `${config.server_base_url}/presale-start`, data, {});
}


export const addWhiteListedService = (data) => {
  return fetch("put", `${config.server_base_url}/add-whitelist`, data, {});

}

export const addWhiteListedServiceSpecial = (data) => {
  return fetch("put", `${config.server_base_url}/add-whitelist-special`, data, {});

}

export const removeWhitelistService = (data) => {
  return fetch("put", `${config.server_base_url}/remove-whitelist`, data, {});
}

export const removeWhitelistSpecialService = (data) => {
  return fetch("put", `${config.server_base_url}/remove-whitelist-special`, data, {});
}


export const finalizePoolService = (data) => {
  return fetch("put", `${config.server_base_url}/finalize-pool`, data, {});
}

export const claimSpecialSaleService = (data) => {
  return fetch("put", `${config.server_base_url}/claim-special-sale`, data, {});
}

export const allowClaimService = (data) => {
  return fetch("put", `${config.server_base_url}/allow-claim`, data, {});
}

export const ClaimedService = (data) => {
  return fetch("put", `${config.server_base_url}/claimed`, data, {});
}

export const getPresaleReminder = (data, saleType) => {
  return fetch("get", `${config.server_base_url}/webpush/${data}?saleType=${saleType}`);
}
export const deletePresaleReminder = (data) => {
  return fetch("delete", `${config.server_base_url}/webpushes/${data}`);
}
export const deleteSinglePresaleReminder = (data) => {
  return fetch("delete", `${config.server_base_url}/webpush/${data}`);
}

export const updateLiqAddsOnTimestamp = (data)=>{
  return fetch("put", `${config.server_base_url}/update-liqadds-on-timestamp`,data,{});

}

export const updatePresaleRDexListingR = (data)=>{
  return fetch("put", `${config.server_base_url}/update-prerate-dexlirate`,data,{});
}

export const getHeighlightedPresalesService = (data)=>{
  return fetch("get", `${config.server_base_url}/heighlighted-presales`,{},{});
}

export const lockService = (data)=>{
  return fetch("post", `${config.server_base_url}/lock`, data);

}

export const getLocksService = (data) => {
  return fetch("get", `${config.server_base_url}/locks?chain=${data.chain}&address=${data.address || ""}&type=${data.type || ""}&search=${data.search}&pageSize=${data.pageSize || 12}&currentPage=${data.currentPage || 1}`);
}

export const getMyLocksService = (data) => {
  return fetch("get", `${config.server_base_url}/my-locks?chain=${data.chain}&address=${data.address || ""}&type=${data.type || ""}&search=${data.search}&pageSize=${data.pageSize || 12}&currentPage=${data.currentPage || 1}`);
}


export const getALockService = (data) => {
  return fetch("get", `${config.server_base_url}/lock?token=${data.token}`);
}

export const updateRatesAfterFinalizeService = (data)=>{
  return fetch("put", `${config.server_base_url}/update-rates-affinalize`,data,{});
}

export const unlockService =(data)=>{
  return fetch("post", `${config.server_base_url}/unlocked`,data)
  
}


export const postStakingService =(data)=>{
  return fetch("post", `${config.server_base_url}/post-staking`,data)
  
}



export const homeInfoService = () => {
  return fetch("get", `${config.server_base_url}/home-info`);
}


export const getStakingService = (data) => {
  return fetch("get", `${config.server_base_url}/get-staking?active_tab=${data.activeTab}&wallet=${data.wallet}&reward=${data.reward}&chain_id=${data.chain_id}&status=${data.status}&filterBy=${data.filterBy}&pageSize=${data.pageSize}&currentPage=${data.currentPage}`, {}, {});
}

export const getStakeDetails = (data) => {
  return fetch("get", `${config.server_base_url}/stack-details/${data?.stakeContract}`, {}, {});
}

export const extendLockerTime =(data)=>{
  return fetch("post", `${config.server_base_url}/extend-locker`,data)
  
}

export const updateStakingDetails = (data) => {
  return fetch("put", `${config.server_base_url}/update-staking-details`, data, {});
}

export const cancelStakeService = (data) => {
  return fetch("put", `${config.server_base_url}/cancel-stake`, data, {});
}


export const depositeWithdrawalStak = (data) => {
  return fetch("put", `${config.server_base_url}/deposite-reward`, data, {});
}


export const stakeService =(data)=>{
  return fetch("post", `${config.server_base_url}/stake`,data)
}

export const myStack = (data)=>{
  return fetch("get", `${config.server_base_url}/my-stake?address=${data.address}&stakeContract=${data.stakeContract}&chain=${data.chain}`, {}, {});
}

export const updateLastDReward =(data)=>{
  return fetch("put", `${config.server_base_url}/update-last-dis-reward-af-de`,data,{})
}

export const updateLastDisTime =(data)=>{
  return fetch("put", `${config.server_base_url}/update-last-dis-time`,data,{})
}

export const getStakedDetailsService= (data)=>{
  return fetch("get", `${config.server_base_url}/stake-details?stakeContract=${data.stakeContract}`, {}, {});
}


export const tokenIsStakeService = (data)=>{
  return fetch("get", `${config.server_base_url}/token-is-stake-address?address=${data.address}`, {}, {});
}

export const updateAuditService =(data)=>{
  return fetch("put", `${config.server_base_url}/update-audit`,data,{})
}

export const updateSaleStaus =(data)=>{
  return fetch("put", `${config.server_base_url}/change-whitelist-status`,data,{})
}

export const sendOTPSubsc =(data)=>{
  return fetch("post", `${config.server_base_url}/subscription/otp`,data,{})
}

export const subscipRegisterService =(data)=>{
  return fetch("post", `${config.server_base_url}/subscription/register`,data,{})
}

export const getPackageListService = (data)=>{
  return fetch("get", `${config.server_base_url}/get-package-list`, {}, {});
}

export const checkRegisterService = (data)=>{
  return fetch("get", `${config.server_base_url}/subscription/check_register?address=${data?.address}`, {}, {});
}

export const subscribe =(data)=>{
  return fetch("post", `${config.server_base_url}/subscribe`,data,{})
}

export const checkSubscription =(data)=>{
  return fetch("get", `${config.server_base_url}/check-subscription?address=${data.address}`,{},{})
}

export const packageDetails =(data)=>{
  return fetch("get", `${config.server_base_url}/package-detail?package_id=${data.package_id}`,{},{})
}

export const checkAvailability =(data)=>{
  return fetch("get", `${config.server_base_url}/check-availability?package_id=${data?.package_id}&address=${data?.address}`,{},{})
}

export const checkPayent =(data)=>{
  return fetch("get", `${config.server_base_url}/check-payment?address=${data?.address}`,{},{})
}

export const subsDetailsService =(data)=>{
  return fetch("get", `${config.server_base_url}/subs-detatils?address=${data?.address}`,{},{})
}

export const storeHash =(data)=>{
  return fetch("post", `${config.server_base_url}/subscription/store-hash`,data,{})
}

export const transactionError =(data)=>{
  return fetch("post", `${config.server_base_url}/subscription/transaction-logsh`,data,{})
}

export const getCoinPriceService =(data)=>{
  return fetch("post", `${config.server_base_url}/calculate_helper`,data,{})
}

export const checkReferrerService =(data)=>{
  return fetch("get", `${config.server_base_url}/check-referrer?username=${data?.username}`,{},{})
}

export const getSubsDetailsService =(data)=>{
  return fetch("get", `${config.server_base_url}/subsc-details?address=${data?.address}&id=${data.id}`,{},{})
}