import React, { useEffect, useState } from "react";
import { useAccount, useContractWrite, useNetwork, useWaitForTransaction } from "wagmi";
import PreSaleManager from "../../_constant/PreSaleManager.json";
import { PRESALE_MANAGER_ADDRESSESS } from "../../_constant";
import { toast } from "react-toastify";
import { SUCCESSMSGS, TRANSACTIONMSGS } from "../../utils/messages";
import moment from "moment";
import { SyncLoader } from "react-spinners";
import { PostErrorLogAction } from "../../redux/apiActions/api.action";


export function ClaimLP({preSale, refetchLiqidityAddsOn, refetchLiqLockInSec}) {

    const { chain, chains } = useNetwork();
    const { address } = useAccount();
    const PRESALE_MANAGER_ADDRESS = PRESALE_MANAGER_ADDRESSESS[chain?.network]

    const onErrorClaimLPTokens = (error)=>{
        PostErrorLogAction({ address: address || 0, other_detail: { error } });
        console.log('8 Error', error);
        let errStr = error.toString().slice(0, 53)
        if (errStr === "TransactionExecutionError: User rejected the request.") {
            toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT)
        }else if(error.toString().includes('CannotUnlockLiquidityNow')){
            toast.error("Cannot Unlock Liquidity Now")
        }else if(error.toString().includes("InsufficientTokenBalance")){
            toast.error("Already claimed!",{toastId:'istbTid'})
        }
        else{
            toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG)
        }
    }


    const onSuccessClaimLPTokens = ()=>{

    }

    const {
        data: dataClaimLPTokens,
        write: writeClaimLPTokens,
        isLoading: isLoadingClaimLPTokens,
        isSuccess: isSuccessClaimLPTokens,
      } = useContractWrite({
        address: PRESALE_MANAGER_ADDRESS,
        abi: PreSaleManager.abi,
        functionName: "claimLPTokens",
        args:[preSale],
        onError: onErrorClaimLPTokens,
        onSuccess: onSuccessClaimLPTokens
      });

      const onSuccessWaitForTransaction = (data) => {
        toast.success(SUCCESSMSGS.CLAIM_LP_CUCCESS)
        refetchLiqidityAddsOn()
        refetchLiqLockInSec()
      }

      const onApproveReceipt = ()=>{

      }
    
      // Waiting for tx to mine.
      const { isLoading: isLoadingWaitForTranasaction } = useWaitForTransaction({
        hash: dataClaimLPTokens?.hash,
        onSettled: onApproveReceipt,
        onSuccess: onSuccessWaitForTransaction
      });

    
      const [dataLiqLockInSec,_dataLiqLockInSec]=useState()
    

        const getDataLockInSec = async()=>{
        const dataLiqLockInSecData =await refetchLiqLockInSec()
        _dataLiqLockInSec(dataLiqLockInSecData.data)
        }

        useEffect(()=>{
            getDataLockInSec()
        },[])

   const currentUnixTime = moment().unix();

    return (
        <>
        {
            currentUnixTime >= Number(dataLiqLockInSec) &&
            // <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 gap-2">


                        <button
                        className="btn btn-success btn-block mt-4 modal-open btn_managepool"
                        disabled={isLoadingClaimLPTokens||isLoadingWaitForTranasaction}
                        onClick={()=>{writeClaimLPTokens()}}
                        >{isLoadingClaimLPTokens||isLoadingWaitForTranasaction? <SyncLoader color={'#3498DB'} size={10} />:'Claim LP'}</button>


                    </div>
                </div>
            // </div>
        }
        </>
    )
}