import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ethers } from "ethers";
import { useNetwork, useAccount, useContractWrite, useContractRead, useWaitForTransaction, useBalance } from "wagmi";
import moment from "moment";
import Countdown from "../../Components/CountDown/CountDown";
import { getAccount } from '@wagmi/core'
import { BuyPresale } from "./BuySpecial";
import { Claim } from "./Claim";
import { ShowRecv } from "./ShowRecev";
import { SyncLoader } from "react-spinners";
import config from "../../config";

function CnbtPresaleDetails({presaleDetails,startEndIn,progreeBar,isDataFundRaised,investDetails,getInvestDetailsFunc,referchFundraise,chainId,bonusSpotLeft,loading,withdraw,isLoadingWithdraw,emergencyWithdraw,isLoadingEmgcyWithDraw,refetchInFBC}){
  const { chain, chains } = useNetwork();
  const account = getAccount()

  const { isDisconnected, isConnected, address } = useAccount();

  const not_custom_then_symbol = config.SUPPORTED_NETWORKS.find(network => network.id == presaleDetails?.chain)?.symbol;

  const currentUnixTime = moment().unix();

  const fundRaisingTokenDecimal = presaleDetails?.fund_releasing_token == "Custom" || presaleDetails?.fund_releasing_token == "BUSD" || presaleDetails?.fund_releasing_token =='USDT' ? presaleDetails?.custom_fund_token_decimal : 18;
  const fundRaisingTokenSymbol = presaleDetails?.fund_releasing_token == "Custom" ||
    presaleDetails?.fund_releasing_token == "BUSD" || presaleDetails?.fund_releasing_token == "USDT"
    ? presaleDetails?.custom_fund_raising_token_symbol
    : not_custom_then_symbol || '';

    const fundRaisaed = isDataFundRaised ? ethers.utils.formatUnits(String(isDataFundRaised), fundRaisingTokenDecimal) : 0
    const hardCap = ethers.utils.formatUnits(String(presaleDetails?.hardCap), fundRaisingTokenDecimal)


    return(
        
        <div className="contribute_r_section">
        <div className="custom_card">
          <div className="p-3">
            <div className="progress_bar mt-2">
              <div className="text-center">
                {(presaleDetails?.setSaleTime == 1 && (Number(currentUnixTime) < Number(presaleDetails?.endTime) && presaleDetails?.isCanceled == 0)) && <p className="mb-0" style={{ color: "white" }}>{" "}{startEndIn}:</p>}
                {
                  presaleDetails?.setSaleTime == 1 ?
                    <span>


                      {
                        startEndIn == "Sale Finished"?
                        <>
                            <span>Special Sale Finished</span>
                        </>
                        :
                        presaleDetails?.isCanceled == 1 ?
                          'Cancelled'
                          :
                          presaleDetails?.isCollected == 1 && 
                            presaleDetails?.isAllowclaim == 1?
                            <p>Claim Allowed</p>
                            :
                          currentUnixTime >=  presaleDetails?.estimatedClaimTime ?
                          <p>Special Sale Ended</p>
                          :
                          Number(
                            moment(new Date()).unix()
                          ) > presaleDetails?.estimatedClaimTime ?
                            <p>Special Sale Ended</p>
                            :
                            startEndIn =="Sale Ended"?
                            <div>Special Sale Ended</div>:
                            <Countdown
                              unixTime={
                                Number(
                                  moment(new Date()).unix()
                                ) >=
                                  Number(presaleDetails?.startTime)
                                  ? presaleDetails?.endTime
                                  : Number(
                                    moment(
                                      new Date()
                                    ).unix()
                                  ) < presaleDetails?.startTime
                                    ? presaleDetails?.startTime
                                    : ""
                              }
                            />
                      }
                    </span>
                    :
                    presaleDetails?.isCanceled == 1 ? 'Presale Cancelled' : "TBA"
                }
              </div>

              <div className="progress mt-4" style={{ height: 10 }}>
                <div
                  className="progress-bar"
                  style={{ width: `${progreeBar}%`, height: 10 }}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p>{isDataFundRaised && ethers.utils.formatUnits(String(isDataFundRaised), fundRaisingTokenDecimal)}{" "}
                  {(fundRaisingTokenSymbol)}
                </p> 
                <p> {presaleDetails?.softCap && ethers.utils.formatUnits(String(presaleDetails?.hardCap), fundRaisingTokenDecimal)}{" "}
                  {(fundRaisingTokenSymbol)}
                </p>
              </div>
            </div>
     
            {
              moment(new Date()).unix() >= Number(presaleDetails?.startTime) && moment(new Date()).unix() <= Number(presaleDetails?.endTime) ?
                presaleDetails?.isWhiteList === "true" && presaleDetails?.whitelisted?.split(",").includes(account.address) ||
                  presaleDetails?.isWhiteList === "false" && presaleDetails?.chain == chain?.id && presaleDetails?.isCanceled==0 && fundRaisaed!=hardCap
                  ? <BuyPresale presaleDetails={presaleDetails} getInvestDetailsFunc={getInvestDetailsFunc} referchFundraise={referchFundraise} refetchInFBC={refetchInFBC}/>
                  : null
                : null
            }
            {
              presaleDetails?.isWhiteList == "true" && currentUnixTime > moment.unix(presaleDetails?.startTime).add(Number(presaleDetails?.whiteListTimer), 'minutes').unix() && !presaleDetails?.whitelisted?.split(",").includes(account.address) && moment(new Date()).unix() <= Number(presaleDetails?.endTime) && presaleDetails?.chain == chain?.id && presaleDetails?.isCanceled==0 && fundRaisaed!=hardCap ?
                <BuyPresale presaleDetails={presaleDetails} getInvestDetailsFunc={getInvestDetailsFunc} referchFundraise={referchFundraise} refetchInFBC={refetchInFBC}/>
                : ''
            }

          {
              (isDataFundRaised && fundRaisingTokenDecimal &&
              investDetails?.MyContribution != 0 && presaleDetails?.isCollected == 1 
              && ethers.utils.formatUnits(String(isDataFundRaised), fundRaisingTokenDecimal) >= Number(ethers.utils.formatUnits(String(presaleDetails?.softCap || 0),Number(fundRaisingTokenDecimal)))
              &&
              investDetails?.isClaimed==0 && presaleDetails?.isAllowclaim==1)
              &&
              <Claim preSale={presaleDetails?.preSale} getInvestDetailsFunc={getInvestDetailsFunc} />
            }
            {
              (isDataFundRaised && fundRaisingTokenDecimal &&
                investDetails?.MyContribution != 0 && presaleDetails?.isCollected == 1 
                && ethers.utils.formatUnits(String(isDataFundRaised), fundRaisingTokenDecimal) >= Number(ethers.utils.formatUnits(String(presaleDetails?.softCap || 0),Number(fundRaisingTokenDecimal)))
                &&
                investDetails?.isClaimed==1)
                &&
                <ShowRecv ReceiveTokens={parseFloat(investDetails?.ReceiveTokens) || 0} token_symbol={presaleDetails?.token_symbol}/>
            }


            <ul className="presale_address_ui">
              <li className="d-flex justify-content-between">
                <p>Minimum Buy</p>
                <p>

                  {/* {
                    (presaleDetails?.minBuy && presaleDetails?.token_decimal) &&
                    ethers.utils.formatUnits(String(presaleDetails?.minBuy), Number(presaleDetails?.token_decimal))
                      .toString()
                  } */}
                  {ethers.utils.formatUnits(String(presaleDetails?.minBuy || 0), presaleDetails?.fund_releasing_token === 'BNB' || presaleDetails?.fund_releasing_token=='MATIC' ? 18 : presaleDetails?.custom_fund_token_decimal || 0).toString()}
                  {" "}
                  {
                    fundRaisingTokenSymbol
                  }
                </p>
              </li>
              <li className="d-flex justify-content-between">
                <p>Maximum Buy</p>
                <p>
                  {ethers.utils.formatUnits(String(presaleDetails?.maxBuy || 0), fundRaisingTokenDecimal || 0).toString()}
                  {" "}
                  {
                    fundRaisingTokenSymbol
                  }
                </p>
              </li>
              <li className="d-flex justify-content-between">
                <p>Special Sale Rate</p>
                <p>
                  {ethers.utils.formatUnits(String(presaleDetails?.specialSaleRate || 0), presaleDetails?.token_decimal || 0).toString()}
                  {" "}
                  {
                    fundRaisingTokenSymbol
                  }
                </p>
              </li>

              <li className="d-flex justify-content-between">
                <p>Presale Rate</p>
                <p>
                  {ethers.utils.formatUnits(String(presaleDetails?.preSaleRate || 0), presaleDetails?.token_decimal || 0).toString()}
                  {" "}
                  {
                    fundRaisingTokenSymbol
                  }
                </p>
              </li>
              <li className="d-flex justify-content-between">
                <p>Dex Rate</p>
                <p>
                  {ethers.utils.formatUnits(String(presaleDetails?.dexListingRate || 0), presaleDetails?.token_decimal || 0).toString()}
                  {" "}
                  {
                    fundRaisingTokenSymbol
                  }
                </p>
              </li>

              <li className="d-flex justify-content-between">
                <p>Total Contributors</p>
                <p>{investDetails?.totalRecord || 0}</p>
              </li>
              <li className="d-flex justify-content-between">
                <p>AVG Contribution</p>
                <p>{investDetails?.totalRecord == 0 ? '0' : parseFloat(investDetails?.averageMyContribution) || 0} {" "}
                  {fundRaisingTokenSymbol}
                </p>
              </li>
              {
                presaleDetails?.isbonusSale==1 &&
                <>
              <li className="d-flex justify-content-between">
                <p>Min Bonus Buy Amount</p>
                <p>
                  {/* {presaleDetails?.minBonusBuyAmount}  */}
                  {
                    (presaleDetails?.minBonusBuyAmount && presaleDetails?.custom_fund_token_decimal) &&
                    ethers.utils.formatUnits(String(presaleDetails?.minBonusBuyAmount), Number(presaleDetails?.custom_fund_token_decimal))
                      .toString()
                  }
                  {" "}
                  {fundRaisingTokenSymbol}
                </p>
              </li>
              <li className="d-flex justify-content-between">
                <p>Bonus Received</p>
                <p>{presaleDetails?.bonusReceivedPercentage} %</p>
              </li>
              <li className="d-flex justify-content-between">
                <p>Bonus Spots Available</p>
                <p>{presaleDetails?.noOfBonusEligibleInvestors}</p>
              </li>
              <li className="d-flex justify-content-between">
                <p>Bonus Spots Left</p>
                <p>{bonusSpotLeft || 0}</p>
              </li>
              </>
              }

              <li className="d-flex justify-content-between pb-0">
                <p>My Contribution</p>
                <p>{parseFloat(investDetails?.MyContribution) || 0} {" "}
                  {fundRaisingTokenSymbol}
                </p>
              </li>
            </ul>
            <ul>
              <li className="d-flex justify-content-between">
                <p className="mb-0">My Reserved Tokens</p>
                <p className="mb-0">{parseFloat(investDetails?.ReceiveTokens) || 0} {presaleDetails?.token_symbol} </p>
              </li>
            </ul>
            {
              (investDetails?.MyContribution != 0
                &&
                loading == false
                && 
                investDetails !=undefined
              )
                
              &&
              <ul>
                {
                  presaleDetails?.isCanceled == 1 ?
                    <button 
                      className="btn emergency_with_btn mt-2 mx-auto"
                      onClick={() => { withdraw() }}
                      disabled={isLoadingWithdraw}
                    > {isLoadingWithdraw ? 'Withdrawing....' : 'Withdraw'}</button>
                    :
                    currentUnixTime < presaleDetails?.endTime &&
                    <button 
                    className="btn emergency_with_btn mt-2 mx-auto"
                      onClick={() => { emergencyWithdraw() }}
                      disabled={isLoadingEmgcyWithDraw}
                    > {isLoadingEmgcyWithDraw ? <SyncLoader color="#3498DB" size={10}/> : 'Emergency Withdraw'}</button>
                }
              </ul>
            }
          </div>
        </div>
      </div>
    )
}

export default CnbtPresaleDetails;