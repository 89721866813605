import { useEffect } from "react"
import { homeInfoService } from "../../services/API/api.service"
import { useState } from "react"
import { APP_NAME_TITLE, BASE_URL, networkImagesByChain } from "../../_constant"
import { getHeighlightedPresalesAction } from "../../redux/apiActions/api.action"
import { useNavigate } from "react-router-dom"
import { SyncLoader } from "react-spinners"
import { toast } from "react-toastify"

export default function Home() {

    document.title = APP_NAME_TITLE

    const [info, setInfo] = useState({})

    const [loading,setLoading] = useState(false)

    const homeInfoAPI = async () => {
        try{
            const { data } = await homeInfoService()
            setInfo(data?.data)
        }catch(error){
            toast.error("Something went wrong, Please try again",{toastId:'swwPTid'});
        }

    }

    const [presales, setPresales] = useState([]);

    const getPresales = async () => {
        try{
            setLoading(true)
            const { data } = await getHeighlightedPresalesAction();
            setPresales(data?.data);
        }catch(err){

        }finally{
            setLoading(false)
        }
    
    };


    useEffect(() => {
        homeInfoAPI()
        getPresales();
    }, [])

    const navigate = useNavigate()

    return (

            <div className="container-fluid">
                {
                    loading || Object.keys(info).length === 0?
                    <div className="d-flex justify-content-center align-items-center vh-100">
                    <SyncLoader color="#3498DB" />
                  </div>
                    :
                    <>

<div className="row">
    <div className="stats_runing_bar d-flex align-items-center">
        <div className="d-flex justify-content-between align-items-center breaking-news w-100">
            <marquee className="news-scroll" behavior="scroll" direction="left" onmouseover="this.stop();" onmouseout="this.start();" scrollamount={5}>
                <ul className="d-flex align-items-center gap-4">
                    {presales?.map((presale, index) => (
                        <li 
                        onMouseEnter={() => {
                            const marquee = document.querySelector(".news-scroll");
                            marquee.stop();
                          }}
                          onMouseLeave={() => {
                            const marquee = document.querySelector(".news-scroll");
                            marquee.start();
                          }}
                        >
                            <button type="button" className="runing_bar_button d-flex align-items-center"
                                onClick={() => {
                                    navigate(`${BASE_URL}presale?presale_id=${presale?.preSale}&chainId=${presale?.chain}`)
                                }}
                            >
                                <img src={networkImagesByChain[presale?.chain]} className="img-fluid me-md-2" alt="icon" width={40} />
                                <div className>
                                    <h5 className="text-white">
                                        {
                                            (presale?.sale_without_token == 1 && presale?.startTime == "0") ?
                                                presale?.sale_title :
                                                presale?.launch_stealth_sale == true ? presale?.sale_title :
                                                    presale?.name}
                                    </h5>
                                    <p className="mb-0"><img src="images/icon/hot.png" className="img-fluid me-2" alt="icon" />{presale?.token_symbol}</p>
                                </div>
                            </button>
                        </li>
                    ))}
                </ul>
            </marquee>
        </div>
    </div>
</div>
{/* <div className="row">
    <div className="text-center mt-4">
        <img src="images/stats/logo-etherscan.svg" alt="icon" width={300} />
    </div>
</div> */}
<div className="row">
    <h2 className="text-white text-center mt-5">Stats</h2>
</div>
{/* Tab panes */}
<div className="container mt-5 px-0 px-lg-3">
    <div className="row row-cols-1 row-cols-xxxl-4 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 gap-y-4">
        <div className="col">
            <div className="custom_card p-4">
                {/* <div> */}
                    <div className="card_second_section_text w-100">
                        <p className="mb-0">Total Projects</p>
                    </div>
                    <div className="d-flex gap-2 align-items-center justify-content-between">
                    <h1>{loading ? 'Loading...':Number(info?.total_projects)?.toLocaleString()}</h1>
                        {/* <img src="images/stablz_stablz_icon.png" className="img-fluid" alt="icon" width={40} /> */}
                    {/* <div className="d-flex gap-2 align-items-center w-50 justify-content-end">
                        <i class="fa fa-line-chart" style={{color:'#56ca00'}}></i>
                        <p className="mb-0 white-space-nowrap">7.30 %</p>
                    </div> */}
                    </div>
                {/* </div> */}
            </div>
        </div>
        <div className="col">
            <div className="custom_card p-4">
                    <div className="card_second_section_text w-100">
                        <p className="mb-0">Total Generated Tokens</p>
                    </div>
                    <div className="d-flex gap-2 align-items-center justify-content-between">
                    <h1>{loading ? 'Loading...':Number(info?.total_tokens)?.toLocaleString()}</h1>
                        {/* <img src="images/stablz_stablz_icon.png" className="img-fluid" alt="icon" width={40} /> */}
                    {/* <div className="d-flex gap-2 align-items-center w-50 justify-content-end">
                        <i class="fa fa-line-chart" style={{color:'#56ca00'}}></i>
                        <p className="mb-0 white-space-nowrap">7.30 %</p>
                    </div> */}
                    </div>
            </div>
        </div>
        <div className="col">
            <div className="custom_card p-4">
                <div className="d-flex justify-content-between align-items-end">
                    <div className="card_second_section_text w-100">
                        <p className="mb-0">Project Raise Success Rate</p>
                        <h1>{loading? 'Loading..':Number(info?.success_ratio)?.toFixed(2)} %</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="row row-cols-1 row-cols-xxxl-4 row-cols-xxl-4 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 gap-y-4 mt-5">
        <div className="col">
            <button type="button" className="custom_card stats_presale_card p-4 green_card d-flex align-items-start"
                onClick={(e) => {
                    window.open(BASE_URL + 'presales')
                }
                }
            >
                <div className="text-start">
                    <h3>Presales</h3>
                    <p className="mb-0 text-white">Explores presales of any size and kind</p>
                </div>
                <div className="muibox_img">
                    <img src="images/stats/presales.png" alt="img" />
                </div>
            </button>
        </div>
        <div className="col">
            <button type="button" className="custom_card stats_presale_card p-4 purpal_card d-flex align-items-start"
                onClick={(e) => {
                    window.open(BASE_URL + 'stakes')
                }
                }
            >
                <div className="text-start">
                    <h3>Staking Pools</h3>
                    <p className="mb-0 text-white">Explore the staking pools and enjoy passive rewards from multiple projects</p>
                </div>
                <div className="muibox_img">
                    <img src="images/stats/staking.png" alt="img" />
                </div>
            </button>
        </div>
        <div className="col">
            <button type="button" className="custom_card stats_presale_card p-4 blue_card d-flex align-items-start"
                onClick={(e) => {
                    window.open(BASE_URL + 'create-token')
                }
                }
            >
                <div className="text-start">
                    <h3>Tokens</h3>
                    <p className="mb-0 text-white">Create your own Token with Audit included</p>
                </div>
                <div className="muibox_img">
                    <img src="images/stats/token.png" alt="img" />
                </div>
            </button>
        </div>
        <div className="col">
            <button type="button" className="custom_card stats_presale_card p-4 orange_card d-flex align-items-start"
                onClick={(e) => {
                    window.open(BASE_URL + 'airdrop')
                }
                }
            >
                <div className="text-start">
                    <h3>Airdrop</h3>
                    <p className="mb-0 text-white">Free tool for airdrops</p>
                </div>
                <div className="muibox_img">
                    <img src="images/stats/airdrop.png" alt="img" />
                </div>
            </button>
        </div>
        <div className="col">
            <button type="button" className="custom_card stats_presale_card p-4 blue_card d-flex align-items-start"
                onClick={(e) => {
                    window.open(BASE_URL + 'create-presale')
                }
                }
            >
                <div className="text-start">
                    <h3>Create Presale</h3>
                    <p className="mb-0 text-white">Create presale for you own project and enjoy all the benefits Launchpad offers</p>
                </div>
                <div className="muibox_img">
                    <img src="images/stats/create.png" alt="img" />
                </div>
            </button>
        </div>
        <div className="col">
            <button type="button" className="custom_card stats_presale_card p-4 orange_card d-flex align-items-start"
                onClick={(e) => {
                    window.open(BASE_URL + 'create-stake')
                }
                }
            >
                <div className="text-start">
                    <h3>Create Stake</h3>
                    <p className="mb-0 text-white">Create your own rewarding system</p>
                </div>
                <div className="muibox_img">
                    <img src="images/stats/create.png" alt="img" />
                </div>
            </button>
        </div>
        <div className="col">
            <button type="button" className="custom_card stats_presale_card p-4 green_card d-flex align-items-start">
                <div className="text-start">
                    <h3>Scanner Agent</h3>
                    <p className="mb-0 text-white">Become a Launchpad Agent and earn up to 50% commission</p>
                </div>
                <div className="muibox_img">
                    {/* <img src="images/stats/agent.png" alt="img" /> */}
                </div>
            </button>
        </div>
        <div className="col">
            <button type="button" className="custom_card stats_presale_card p-4 red_card d-flex align-items-start">
                <div className="text-start">
                    <h3>Scanner Alpha</h3>
                    <p className="mb-0 text-white">Join Launchpad's Alpha club by holding 180,000 GEMS</p>
                </div>
                <div className="muibox_img">
                    <img src="images/stats/alpha.png" alt="img" />
                </div>
            </button>
        </div>
    </div>
</div>
{/* <div className="row mt-5">
    <h2 className="text-white text-center mt-5 mb-4">Partners</h2>
    <div className="stats_runing_bar d-flex align-items-center">
        <div className="d-flex justify-content-between align-items-center breaking-news w-100">
            <marquee className="news-scroll" behavior="scroll" direction="left" onmouseover="this.stop();" onmouseout="this.start();" scrollamount={5}>
                <ul className="d-flex align-items-center gap-4 partners_img_stats">
                    <li>
                        <a href="javascript:void(0)" className="runing_bar_button d-flex align-items-center">
                            <img src="images/icon/shibelon.png" className="img-fluid" alt="icon" width={40} />
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" className="runing_bar_button d-flex align-items-center">
                            <img src="images/icon/shibelon.png" className="img-fluid" alt="icon" width={40} />
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" className="runing_bar_button d-flex align-items-center">
                            <img src="images/icon/shibelon.png" className="img-fluid" alt="icon" width={40} />
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" className="runing_bar_button d-flex align-items-center">
                            <img src="images/icon/shibelon.png" className="img-fluid" alt="icon" width={40} />
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" className="runing_bar_button d-flex align-items-center">
                            <img src="images/icon/shibelon.png" className="img-fluid" alt="icon" width={40} />
                        </a>
                    </li>
                </ul>
            </marquee>
        </div>
    </div>
</div> */}
                    </>
                }
            </div>

    )
}