import { useEffect, useMemo, useState } from "react"
import { BASE_URL, NO_REWARD, REWARD_TYPE, STAKE_MANAGER, networkImages, networkImagesByChain, networkLinks } from "../../_constant";
import { useAccount, useContractRead, useContractWrite, useNetwork, useWaitForTransaction } from "wagmi";
import { switchNetwork, fetchBalance } from "@wagmi/core";
import { useWeb3Modal } from "@web3modal/react";
import CnbtStackDetails from "./CnbtStackDetails";
import StackTokenInfo from "./StackTokenInfo";
import ManageStack from "./ManageStack";
import { PostErrorLogAction, getStakeDetailsAction, getStakedDetailsAction, myStakeAction, updateStakingDetailsAction } from "../../redux/apiActions/api.action";
import queryString from "query-string";
import { ethers } from "ethers";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { projectInfoSchemaStacking } from "../../validators/Staking/Staking";
import { SUCCESSMSGS, TRANSACTIONMSGS } from "../../utils/messages";
import StackingManagerABI from "../../_constant/StakingManager.json";
import { toast } from "react-toastify";
import { getAccount, getNetwork } from "@wagmi/core"
import SimpleToken from "../../_constant/SimpleToken.json";
import YouTube, { YouTubeProps } from 'react-youtube';
import StackPoolABI from "../../_constant/StakePool.json";
import ClaimRewards from "./ClaimRewards";
import discordSvg from "./discord.svg"
import { SyncLoader } from "react-spinners";
import Banner from "../PresaleDetails/Banner";
import { Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import config from "../../config";

export default function StackDetails() {


  const { address, isConnected } = useAccount();
  const { chain,chains } = getNetwork()
  const[videoId,setVideoId]= useState('')

  // const STACK_MANAGER_ADDRESS = STAKE_MANAGER[chain?.network]
  const STACK_MANAGER_ADDRESS = STAKE_MANAGER[chain?.network]


  const [loading, setLoading] = useState(false)

  const [my_staking,setMyStaking] =  useState({})

  const [stakeDetails, setStakeDetails] = useState()
  const getStackeDetails = async () => {
    try{
      setLoading(true)
      const { data } = await getStakeDetailsAction({ stakeContract: stakeContract })
      setStakeDetails(data?.data[0])
  
      if(isConnected){
        let bodyData = {address:address, stakeContract:data?.data[0].stakeContract,chain:chain?.id}
        const {data:mystakeData} = await myStakeAction(bodyData)
        setMyStaking(mystakeData?.data)
     }
  
      const videoId = extractVideoId(data?.data[0]?.youtubePresentationVideoUrl);
  
      setVideoId(videoId)
    }catch(error){
      navigate("/404")
    }finally{
      setLoading(false)
    }

  }

  const { search } = window.location;
  const { stakeContract } = queryString.parse(search);
  useEffect(() => {
    getStackeDetails()
  }, [])
  const onPlayerReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
  const opts = {
    height: "350",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      quality: 'highres',
    },
  };

  const getPresaleFuncWithoutLoading = async()=>{
    const { data } = await getStakeDetailsAction({ stakeContract: stakeContract })
    setStakeDetails(data?.data[0])

    if(isConnected){
      let bodyData = {address:address, stakeContract:data?.data[0].stakeContract,chain:chain?.id}
      const {data:mystakeData} = await myStakeAction(bodyData)
      setMyStaking(mystakeData?.data)
   }

    const videoId = extractVideoId(data?.data[0]?.youtubePresentationVideoUrl);

    setVideoId(videoId)
  }

  useEffect(() => {
    getPresaleFuncWithoutLoading()
  }, [address,chain?.id])
  
  function extractVideoId(url) {
    const regExp =
      /^(?:(?:https?:)?\/\/)?(?:(?:www|m)\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/|v\/|youtu\.be\/|\/v\/|user\/[^#]*#([^\/]*?\/)*)?([^\?&"'>]+)/i;
    const match = url.match(regExp);

    if (match && match[2]) {
      return match[2];
    }

    return null;
  }




  const currentUnixTime = moment().unix();
  const startTime = Number(stakeDetails?.startDateTime);
  const endTime = Number(stakeDetails?.endDateTime);
  let classNameD = "sale_upcoming";
  let statusText = "Upcoming";
  let startEndIn = "TBA";

  if (stakeDetails?.isCancel == 1) {
    statusText = "Sale cancelled"
  }
  else if (currentUnixTime >= startTime && endTime > currentUnixTime) {
    classNameD = "sale_live";
    statusText = "Sale Live";
    // startEndIn = "Sale Ends In";
  } else if (currentUnixTime < startTime) {
    classNameD = "sale_upcoming";
    statusText = "Upcoming";
    // startEndIn = "Sale Start In";
  } else if (currentUnixTime > endTime) {
    statusText = "Sale Ended";
    classNameD = "sale_end";
    // startEndIn = "Sale Ended"
  }

  // Live Sale Status Update
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [targetReached, setTargetReached] = useState(false);
  const [targetEnd, setTargetEnd] = useState(false);


  

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000); // Update the current time every second (adjust as needed)

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  useEffect(() => {
    if (!targetReached && currentTime >= stakeDetails?.startDateTime && currentTime < stakeDetails?.endDateTime) {
      setTargetReached(true);
      // Call your function here
      getPresaleFuncWithoutLoading()
    }
  }, [currentTime, targetReached, stakeDetails]);

  useEffect(() => {
    if (!targetEnd && currentTime >= stakeDetails?.endDateTime) {
      setTargetEnd(true);
      // Call your function here
      getPresaleFuncWithoutLoading()
    }
  }, [currentTime, targetEnd, stakeDetails]);


  const handleSwitchNetwork = async () => {
    try {
      if (stakeDetails?.chain != chain?.id) {
        // _isConfirm({id:chain,isConfirming:true})
        const network = await switchNetwork({
          chainId: Number(stakeDetails?.chain),
        });
      }
    } catch (error) {
      console.log("error", error);
      // _isConfirm({id:'',isConfirming:false})
    }
  };


  const Walletconnect = useMemo(() => {
    return (
      //     <>
      //       <WagmiConfig client={wagmiClient}>
      <HomePage presale_chain={stakeDetails?.chain} />
      //       </WagmiConfig>

      //       <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
      //     </>
    );
  });


  // Update presale Details
  const formikUpdatePool = useFormik({
    enableReinitialize: true,
    initialValues: {
      stakingLogoUrl: stakeDetails?.stakingLogoUrl,
      rewardLogoUrl: stakeDetails?.rewardLogoUrl,
      website_url: stakeDetails?.websiteUrl,
      twitter: stakeDetails?.twitterUrl,
      github: stakeDetails?.githubUrl,
      telegram: stakeDetails?.telegramUrl,
      discord: stakeDetails?.discordUrl,
      youtube: stakeDetails?.youtubePresentationVideoUrl,
      whitelist_link: stakeDetails?.whitelistContestUrl,
      description: stakeDetails?.projectDescription,
      bannerUrl: stakeDetails?.bannerUrl
    },
    validationSchema: projectInfoSchemaStacking,
    onSubmit: async (data) => {
      try {
        var button = document.getElementById("prevBtnCloseUpdate");
        button.click();
        updatePresale()
      } catch (error) {
      } finally {
      }
    },
  });

  const onErrorUpdatePresale = (error) => {
    PostErrorLogAction({ address: account?.address || 0, other_detail: { error } });
    console.log('29 Error', error);
    let errStr = error.toString().slice(0, 25)
    if (errStr === "TransactionExecutionError") {
      toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT)
    } else {
      toast.err(TRANSACTIONMSGS.SOMETHINGWENTWRONG)
    }
  }

  const onSuccessUpdatePresale = async () => {

  }

  
  const { data: dataUpdatePresale, isLoading: isLoadingUpdatePresale, isSuccess: isSuccessUpdatePresale, isError: isErrorUpdatePresale, write: updatePresale } = useContractWrite({
    address: STACK_MANAGER_ADDRESS,
    abi: StackingManagerABI.abi,
    functionName: 'updateProjectDetails',
    args: [stakeDetails?.stakeContract, {
      stakingLogoUrl: formikUpdatePool?.values?.stakingLogoUrl,
      rewardLogoUrl: formikUpdatePool?.values?.rewardLogoUrl,
      bannerUrl: formikUpdatePool.values?.bannerUrl,
      websiteUrl: formikUpdatePool.values?.website_url,
      twitterUrl: formikUpdatePool.values?.twitte,
      githubUrl: formikUpdatePool.values?.github,
      telegramUrl: formikUpdatePool.values?.telegram,
      discordUrl: formikUpdatePool.values?.discord,
      youtubePresentationVideoUrl: formikUpdatePool.values?.youtube,
      whitelistContestUrl: formikUpdatePool.values?.whitelist_link,
      projectDescription: formikUpdatePool.values?.description,
    }],
    onError: onErrorUpdatePresale,
    onSuccess: onSuccessUpdatePresale,
  })


  const onSuccssWFTUpdate =async ()=>{

    let updateDb = {
      stakingLogoUrl: formikUpdatePool.values?.stakingLogoUrl,
      rewardLogoUrl: formikUpdatePool?.values?.rewardLogoUrl,
      websiteUrl: formikUpdatePool.values?.website_url,
      twitterUrl: formikUpdatePool.values?.twitter || "",
      githubUrl: formikUpdatePool.values?.github || "",
      telegramUrl: formikUpdatePool.values?.telegram,
      discordUrl: formikUpdatePool.values?.discord,
      youtubePresentationVideoUrl: formikUpdatePool.values?.youtube,
      whitelistContestUrl: formikUpdatePool.values?.whitelist_link || "",
      projectDescription: formikUpdatePool.values?.description,
      bannerUrl: formikUpdatePool.values?.bannerUrl,
      address: address,
      stakeContract: stakeDetails?.stakeContract
    }

    let response = await updateStakingDetailsAction(updateDb)
    if (response) {
      toast.success(SUCCESSMSGS.STACK_INFO_UPDATED)
      getPresaleFuncWithoutLoading()
    }

  }

  const { data, isError: isErrorWaitForTrans, isLoading: isLoadingWFTUpdate } = useWaitForTransaction({
    hash: dataUpdatePresale?.hash,
    onSuccess: onSuccssWFTUpdate
})


  const navigate = useNavigate()

  const account = getAccount()

  const onSuccessApprove  = ()=>{

  }

  const onApproveError = async (error) => {
    console.log("Error 74", error);
    PostErrorLogAction({ address: account?.address || 0, other_detail: { error } });
    let errStr = error.toString().slice(0,53)
    if(errStr === "TransactionExecutionError: User rejected the request."){
      toast.error(TRANSACTIONMSGS.REJECTEDAPPROVING)
    }else{
    toast.error('Failed in approving!');
    }
  
  };

  const { data: isAllowanceData, isError: isErrorAllowance, isLoading: isLoadingAllowance, refetch: refetchAllowance } = useContractRead({
    address: stakeDetails?.stakeToken,
    abi: SimpleToken.abi,
    functionName: "allowance",
    args: [account.address, stakeDetails?.stakeContract],
    // onSuccess: onSuccessAllowance,
    // onError: o
  })


  const { data: balanceOf } = useContractRead({
    /// PreSale token address
    // @ts-ignore
    address: stakeDetails?.stakeToken,
    abi: SimpleToken.abi,
    functionName: "balanceOf",
    args: [account.address],
    // onError: onTokenFetchError,
  });

             // Approve Token
             const {
              data: approvedData,
              write: approveToken,
              isLoading:isLoadingApprove,
              isSuccess: isApprovedSuccess,
            } = useContractWrite({
              /// PreSale token address
              // @ts-ignore
              abi: SimpleToken.abi,
              functionName: "approve",
              address:stakeDetails?.stakeToken,
              onError: onApproveError,
              onSuccess:onSuccessApprove
            });
            const onSuccessWaitForTransaction = (data)=>{
              // console.log("onSuccessWaitForTransaction",data)
              }
                /// Any api call
              const onApproveReceipt = async (data, err) => {
                  toast.success(SUCCESSMSGS.APPROVED_SUCCESS)
                  refetchAllowance()
              };

            const {isLoading:isLoadingWFTApprove} = useWaitForTransaction({
              hash: approvedData?.hash,
              onSettled: onApproveReceipt,
              onSuccess:onSuccessWaitForTransaction
            });


            // lastDistributeTime
            const { data: lastDistributedTime } = useContractRead({
              /// PreSale token address
              // @ts-ignore
              address: stakeDetails?.stakeContract,
              abi: StackPoolABI.abi,
              functionName: "lastDistributeTime",
              // args: [stakeDetails?.stakeContract],
              watch: true,
              // onError: onTokenFetchError,
            });

            // console.log("lastDistributedTime",lastDistributedTime)


            const mystackGet = async()=>{
                // my-stake
                if(isConnected){
                    let bodyData = {address:address, stakeContract:stakeDetails?.stakeContract,chain:chain?.id}
                    const {data} = await myStakeAction(bodyData)
                    setMyStaking(data?.data)
                }
            }
          
            // useEffect(()=>{
            //     mystackGet()
            // },[stakeDetails])

            const { data: totalRewardsDistributed } = useContractRead({
              /// PreSale token address
              // @ts-ignore
              address: stakeDetails?.stakeContract,
              abi: StackPoolABI.abi,
              functionName: "totalRewardsDistributed",
              // args: [stakeDetails?.stakeContract],
              watch: true,
              // onError: onTokenFetchError,
            });
            

            const { data: myTotalRewardsData,refetch:refetchMyTotalRewards } = useContractRead({
              /// PreSale token address
              // @ts-ignore
              address: stakeDetails?.stakeContract,
              abi: StackPoolABI.abi,
              functionName: "withdrawnRewards",
              args: [account?.address],
              watch: true,
              // onError: onTokenFetchError,
            });


            const [stackedDetails,setStakedDetails] = useState({})
            const getStakeDetails = async()=>{
              let bodyData = {stakeContract:stakeContract}
              let {data} = await getStakedDetailsAction(bodyData)
              setStakedDetails(data?.data[0])
            }

            useEffect(()=>{
              getStakeDetails()
            },[])


            useEffect(() => {
              window.scrollTo(0, 0); 
            }, []);

            document.title = stakeDetails?.name ? `Stake ${stakeDetails?.name}` : 'Stake Pool'
            
  return (
    <>
      <>
        <div className="container-fluid px-lg-4">

          {loading ? (
                  <div className="d-flex justify-content-center align-items-center vh-100">
                  <SyncLoader color="#3498DB" />
                </div>
          ) : (
            <>
              {
                Object.keys(stackedDetails).length === 0 ?
                <div className="d-flex justify-content-center align-items-center vh-100">
                <SyncLoader color="#3498DB" />
              </div>
                :
                <>
              <div className="row mt-4">
                <div className="d-flex align-items-center tnbc_back_section">
                  {/* <Link to={BASE_URL}>
                  <i className="fa fa-arrow-left" aria-hidden="true" />
                </Link> */}
                  <a
                    onClick={() => {
                      { navigate(-1) ? navigate(-1) : navigate(BASE_URL) };
                    }}
                    // href={BASE_URL}
                    className="me-2"
                    style={{ cursor: 'pointer' }}
                  >
                    <i className="fa fa-arrow-left" aria-hidden="true" />
                  </a>
                  <p className="mb-0">Back</p>
                </div>
              </div>
              <div className="row mt-4 g-4">
                <div className="col-xxl-8 col-lg-8 col-md-12">
                  <div className="tnbc_custom_card">
                    <div className="custom_card">
                      <div className="card-top-img tnbc-card-top-img"
                        // style={{
                        //   backgroundImage: `url(${stakeDetails?.bannerUrl != ""
                        //     ? stakeDetails?.bannerUrl
                        //     : "https://testnet.gempad.app/static/bg4.png"
                        //     })`,
                        // }}
                      >
                      <Banner url={stakeDetails.bannerUrl}/>
                        <div className="p-3">
                          <div className={classNameD}>
                            <p className="mb-0">{statusText}</p>
                          </div>

                        </div>
                      </div>
                      <div className="p-3">
                        <div style={{ position: 'relative' }} className="card_second_section d-flex justify-content-between align-items-end">
                          <div className="card_second_section_text">
                            <div className="d-flex align-items-center">
                              <h5>
                                Stake {stakeDetails?.name}
                              </h5>
                              <ul className="ms-4 presale_social_icons_section d-flex align-items-center gap-2">
                              {
                                stakeDetails?.twitterUrl != "" &&
                                <li
                                  onClick={() => { window.open(stakeDetails.twitterUrl) }}
                                >
                                  <a href="#" className="text-blue fs-5"><i className="fa fa-twitter" aria-hidden="true" /></a>
                                </li>
                              }
                              {
                                stakeDetails?.telegramUrl != "" &&
                                <li
                                  onClick={() => { window.open(stakeDetails.telegramUrl) }}
                                >
                                  <a href="#" className="text-blue fs-5"><i className="fa fa-telegram" aria-hidden="true" /></a>
                                </li>
                              }
                              {
                                stakeDetails?.githubUrl != "" &&
                                <li
                                  onClick={() => { window.open(stakeDetails.githubUrl) }}
                                >
                                  <a href="#" className="text-blue fs-5"><i className="fa fa-github" aria-hidden="true" /></a>
                                </li>
                              }
                              {
                                stakeDetails?.websiteUrl != "" &&
                                <li
                                  onClick={() => { window.open(stakeDetails.websiteUrl) }}
                                >
                                  <a href="#" className="text-blue fs-5"><i className="fa fa-globe" aria-hidden="true" /></a>
                                </li>
                              }

                              {
                                stakeDetails?.discordUrl !== "" && (
                                  // <li onClick={() => { window.open(presaleDetails.discordUrl) }}>
                                  //   <a href="#" className="text-blue fs-5">
                                  //     <i className="" aria-hidden="true">
                                  //       <img src={DiscordSVG} alt="Discord" />
                                  //     </i>
                                  //   </a>
                                  // </li>
                                  <img src={discordSvg} alt="Discord" height={25} width={25} style={{ cursor: 'pointer' }} onClick={() => { window.open(stakeDetails.discordUrl) }} />
                                )
                              }


                            </ul>
                            </div>
                            <p className="mt-3">
                                Stake <span 
                                  onClick={() => {
                                    window.open(
                                      `${networkLinks[stakeDetails?.chain]}/address/${JSON.parse(stakeDetails.stackTokenInfo)?.address}`
                                    )
                                  }}
                                  style={{ cursor: 'pointer', color: 'rgb(255, 193, 7)', textDecoration: 'underline' }}
                                >
                                  {stakeDetails?.symbol} 
                                </span> 
                                {stakeDetails?.rewardToken !== NO_REWARD && (
                                  <>
                                  {" "}
                                  <span> to Earn</span>
                                  {" "}
                                  <span 
                                    onClick={() => {
                                      window.open(
                                        `${networkLinks[stakeDetails?.chain]}/address/${JSON.parse(stakeDetails.rewardTokenInfo)?.address}`
                                      )
                                    }}
                                    style={{ cursor: 'pointer', color: 'rgb(255, 193, 7)', textDecoration: 'underline' }}
                                  >
                                    {JSON.parse(stakeDetails.rewardTokenInfo)?.symbol}
                                  </span>
                                  </>
                                )}
                              </p>
                              <p className="mt-3" style={{whiteSpace:'break-spaces'}}>{stakeDetails?.projectDescription}</p>

                          </div>
                          <div style={{
                            position: "absolute",
                            top: "0px",
                            right: "0px"
                          }}>
                            <div className="card_second_section_img position" >
                              {/* <img src={presaleDetails?.logoUrl} alt="logo"
                              onError={(event) => {
                                event.target.src = "images/stablz_stablz_icon.png";
                                event.onerror = null;
                              }}
                            /> */}
                              <span><img src={networkImagesByChain[stakeDetails?.chain]} alt="icon" width={28} /></span>
                            </div>

                          </div>

                        </div>
                        <ul className="presale_address_ui mt-5">
                          {/* <li className="d-flex justify-content-between">
                          <p>Presale Address</p>
                          <div className="div_media_screen">
                            <p
                              className="mb-0 text-blue media_screen"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                window.open(
                                  `${networkLinks[97]}/address/${"0xxxx"}`
                                )
                              }
                            >
                              0xxxx
                            </p>
                            <span>
                              Do not send BNB directly to the presale address!
                            </span>
                          </div>
                        </li> */}
                {/* {    stakeDetails?.youtubePresentationVideoUrl != "" &&
                        <div className="text-center">
                          <YouTube videoId={videoId} opts={opts} onReady={onPlayerReady} />
                        </div>
                      } */}
                         {
                        stakeDetails?.youtubePresentationVideoUrl != "" && 
                        <div className="css-1on8bub">
                       <div className="video-responsive">
                          <iframe
                            width="853"
                            height="480"
                            src={`https://www.youtube.com/embed/${videoId}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                          />
                          </div>
                        </div>
                      }


                          <li className="d-flex justify-content-between">
                            <p>Stake Address</p>
                            {/* <p>0x3611C35779F022c13F4334760ac065CCFea3D396</p> */}
                            <p
                              className="mb-0 text-blue media_screen"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                window.open(
                                  `${networkLinks[stakeDetails?.chain]}/address/${stakeDetails?.stakeContract}`
                                )
                              }
                              onMouseEnter={(e) => {
                                e.target.style.textDecoration = "underline";
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.textDecoration = "none";
                              }}
                            >
                              {stakeDetails?.stakeContract}
                            </p>
                          </li>
                          <li className="d-flex justify-content-between">
                            <p>Reward Type</p>
                            <p>
                              {stakeDetails && Object.keys(REWARD_TYPE).find(key => REWARD_TYPE[key] == stakeDetails?.rewardType)}
                            </p>
                          </li>
                            {stakeDetails?.rewardType != REWARD_TYPE["Only if balance"] && stakeDetails?.rewardType !=REWARD_TYPE['No Reward']
                               &&
                              <li className="d-flex justify-content-between">
                                <p>

                                Reward Ratio ({ stakeDetails?.rewardToken && JSON.parse(stakeDetails?.rewardTokenInfo)?.symbol}/year)
                                </p>
                                <p>
                                  {
                                    stakeDetails && ethers.utils
                                      .formatUnits(String(stakeDetails?.rewardRatio || 0), JSON.parse(stakeDetails?.rewardTokenInfo)?.decimals || 0)
                                      .toString()?.replace(/\.?0*$/, '')
                                  }
                                </p>
                              </li>
                            }

                          <li className="d-flex justify-content-between">
                            <p>Min Amount to Stake</p>
                            <p>
                              {
                                stakeDetails && ethers.utils
                                  .formatUnits(String(stakeDetails?.minAmountToStake || 0), JSON.parse(stakeDetails?.stackTokenInfo)?.decimals || 0)
                                  .toString()?.replace(/\.?0*$/, '')
                              }
                            </p>
                          </li>
                          <li className="d-flex justify-content-between">
                            <p>Hard Cap</p>
                            <p>
                              {stakeDetails &&
                                ethers.utils
                                  .formatUnits(String(stakeDetails?.hardCap || 0), JSON.parse(stakeDetails?.stackTokenInfo)?.decimals || 0)
                                  .toString().replace(/\.?0*$/, '')
                              }
                            </p>
                          </li>
                          <li className="d-flex justify-content-between">
                            <p>Total Staked</p>
                            <p>
                            {parseFloat(stackedDetails?.total_staked).toFixed(5).replace(/\.?0*$/, '')}
                            </p>
                          </li>
                          <li className="d-flex justify-content-between">
                            <p>Total Stakers</p>
                            <p>{stackedDetails?.total_stakers}</p>
                          </li>
                          <li className="d-flex justify-content-between">
                            <p>Staked Token transferrable</p>
                            <p>
                              {stakeDetails?.transferrable == 1 ? 'Yes' : 'No'}
                            </p>
                          </li>
                          <li className="d-flex justify-content-between">
                            <p>Stake Start Time (IST)</p>
                            <p>
                              {moment(new Date(stakeDetails?.startDateTime * 1000)).format("YYYY-MM-DD HH:mm")}
                            </p>
                          </li>
                          <li className="d-flex justify-content-between">
                            <p>Stake End Time (IST)</p>
                            <p>
                              {moment(new Date(stakeDetails?.endDateTime * 1000)).format("YYYY-MM-DD HH:mm")}
                            </p>
                          </li>
                          {
                            stakeDetails?.rewardToken != "0x0000000000000000000000000000000000000000" &&
                          <li className="d-flex justify-content-between">
                            <p>Total Rewards Distributed</p>
                            {/* <p>{Number(stakeDetails?.total_rewards_distributed)}</p> */}
                            <p>{stakeDetails?.total_rewards_distributed==0 ?0:ethers.utils.formatUnits(String(stakeDetails?.total_rewards_distributed||0),stakeDetails?.rewardTokenInfo && JSON.parse(stakeDetails?.rewardTokenInfo)?.decimals || 0).toString()}</p>
                          </li>
                          }
                          <li className="d-flex justify-content-between">
                            <p>Last Distribute Time</p>
                            <p>
                              {Number(stakeDetails?.last_distribute_time)==0?'No':moment(new Date((Number(stakeDetails?.last_distribute_time) * 1000))).format('YYYY-MM-DD HH:mm')}
                            </p>
                          </li>


                          {/*  */}

                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row">

                  </div>

                </div>

                <div className="col-xxl-4 col-lg-4 col-md-12">
                  {stakeDetails && stakeDetails?.chain != chain?.id && isConnected == true ? (
                    <div className="custom_card text-center connect_network_presale h-auto">
                      <button
                        type="button"
                        className="btn text-white"
                        onClick={() => {
                          handleSwitchNetwork();
                        }}
                      >
                        <span>
                          Switch Network to
                        </span>
                        <span className="ms-2 me-1">
                          <img
                            onClick={() => { handleSwitchNetwork() }}
                            src={networkImages[chains.find(network => network.id ==stakeDetails?.chain)?.network]} 
                            alt="icon"
                            width="25"
                          />
                        </span>
                      <span className="text-blue">{config.SUPPORTED_NETWORKS.find(network => network.id === stakeDetails?.chain)?.switch_show_name}</span>
                      </button>
                    </div>
                  )
                    :
                    //   <div className="custom_card text-center connect_network_presale">
                    //   <button
                    //     type="button"
                    //     className="btn text-white"
                    //     onClick={() => {
                    //       connectNetwork();
                    //     }}
                    //   >
                    //     <span>
                    //       Connect Network to
                    //     </span>
                    //     <span className="ms-2 me-1">
                    //       <img
                    //         onClick={() => { connectNetwork() }}
                    //         src={networkImages["bsc-testnet"]}
                    //         alt="icon"
                    //         width="25"
                    //       />
                    //     </span>
                    //     <span className="text-blue">BSC Testnet</span>
                    //   </button>
                    // </div>
                    isConnected != true && Walletconnect
                  }


                  <div>
                    
                    {
                      (account?.isConnected && chain?.id== stakeDetails?.chain) &&
                    <CnbtStackDetails stackDetails={stakeDetails} isAllowanceData={isAllowanceData} refetchAllowance={refetchAllowance} approveToken={approveToken} isLoadingApprove={isLoadingApprove} isLoadingWFTApprove={isLoadingWFTApprove} mystackGet={mystackGet} my_staking={my_staking} myTotalRewardsData={myTotalRewardsData} balanceOf={balanceOf} getStakeDetails={getStakeDetails}/>
                    }
                    {
                      (stakeDetails?.canClaimAnyTime==1 && account?.isConnected && chain?.id== stakeDetails?.chain && Number(my_staking?.amount)>0) ?
                      <ClaimRewards stackDetails={stakeDetails} getPresaleFuncWithoutLoading={getPresaleFuncWithoutLoading}/>
                      :
                      (currentUnixTime < stakeDetails?.claimDateTime && account?.isConnected && chain?.id== stakeDetails?.chain && Number(my_staking?.amount)>0) ?
                      <ClaimRewards stackDetails={stakeDetails} getPresaleFuncWithoutLoading={getPresaleFuncWithoutLoading}/>
                      :
                      ''
                    }

                    {
                      stakeDetails &&
                      <StackTokenInfo stakeToken={stakeDetails?.stakeToken} chain={stakeDetails?.chain} />
                    }
                    {
                      stakeDetails?.owner==account?.address && chain?.id==stakeDetails?.chain && stakeDetails?.isCancel != 1 && account?.isConnected &&
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="custom_card mt-4 p-3 text-center">
                          <h7>Manage Stake</h7>
                          <hr />
                          <ManageStack stakeDetails={stakeDetails} isLoadingUpdatePresale={isLoadingUpdatePresale } isLoadingWFTUpdate={isLoadingWFTUpdate} getPresaleFuncWithoutLoading={getPresaleFuncWithoutLoading}/>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
              </>
              }

            </>
          )}
        </div>

        

        {/* Update model */}
        <div className="paresale_module">
          <div className="modal fade " id="updatePoolModal" tabIndex="99999" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                {/* Modal Header */}
                <div className="modal-header">
                  <h5 className="text-center text-blue">Update Sale Information</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" hidden={false} />
                </div>
                {/* Modal body */}
                <div className="modal-body pr-4">
                  <div id="myDropdown"
                    // className={`dropdown-content px-2 py-4 ${showCreate ? 'show' : 'hide'}`}
                    className="dropdown-content"
                  >
                    <div className="row">
                      {/* <div className="col-12">
                        <label className="fieldlabels form-label"
                        >Staking token logo url</label>
                        <div className="input-group mb-3">
                          <span className="input-group-text" id="basic-addon1">
                            <i className="fa fa-picture-o" aria-hidden="true"></i>
                          </span>
                          <input
                            type="text"
                            className={`form-control autofill ${formikUpdatePool.errors.stakingLogoUrl && formikUpdatePool.touched.stakingLogoUrl && 'invalid'}`}
                            name="stakingLogoUrl"
                            placeholder="Ex: https://..."
                            onChange={formikUpdatePool.handleChange}
                            value={formikUpdatePool.values.stakingLogoUrl}
                            aria-describedby="basic-addon1"
                          />
                        </div>
                        {formikUpdatePool.errors.stakingLogoUrl && formikUpdatePool.touched.stakingLogoUrl ? (
                          <span className="text-danger">{formikUpdatePool.errors.stakingLogoUrl}</span>
                        ) : null}
                      </div> */}
            <div className="col-12">
          <label className="fieldlabels" style={{position:'relative'}}>Staking token logo url {" "} 
          {/* <i class="fa fa-info-circle">
          <span className="tooltip-text">URL must end with a supported image extension png, jpg, jpeg or gif and dimensions of exactly 50x50 pixels.</span>
              </i> */}
                 <Tooltip title="URL must end with a supported image extension png, jpg, jpeg or gif and dimensions of exactly 50x50 pixels."
                      placement="top-start"
                      enterTouchDelay={false}
                      >
                       <InfoIcon style={{marginLeft:'1px',width:'19'}}/>
                      </Tooltip>
               <span className="text-danger" style={{fontSize: '1.5rem',position:'absolute',top:-7}}>*</span> </label>
          
          <div className={`${formikUpdatePool.errors.stakingLogoUrl && formikUpdatePool.touched.stakingLogoUrl ? 'input_invalid':'sort_by_Project'} d-flex align-items-center`}>
              <div className="p-2 d-flex align-items-center">
                <i className="fa fa-picture-o" aria-hidden="true"></i>
              </div>
              <input
                className="form-control mb-0 pl-0"
                type="text"
                name="stakingLogoUrl"
                placeholder="Ex: https://..."
                onChange={formikUpdatePool.handleChange}
                value={formikUpdatePool.values.stakingLogoUrl}
              />
            </div>

            {formikUpdatePool.errors.stakingLogoUrl && formikUpdatePool.touched.stakingLogoUrl ? (
                          <span className="text-danger">{formikUpdatePool.errors.stakingLogoUrl}</span>
                        ) : null}
          </div>
                      
                      <div className="col-12">
                      <label className="fieldlabels" style={{position:'relative'}}>Reward token logo url {" "} 
                      <Tooltip title="URL must end with a supported image extension png, jpg, jpeg or gif and dimensions of exactly 50x50 pixels."
                      placement="top-start"
                      enterTouchDelay={false}
                      >
                       <InfoIcon style={{marginLeft:'1px',width:'19'}}/>
                      </Tooltip>
              <span className="text-danger" style={{fontSize: '1.5rem',position:'absolute',top:-7}}>*</span> </label>
          
          <div className={`${formikUpdatePool.errors.rewardLogoUrl && formikUpdatePool.touched.rewardLogoUrl ? 'input_invalid':'sort_by_Project'} d-flex align-items-center`}>
              <div className="p-2 d-flex align-items-center">
                <i className="fa fa-picture-o" aria-hidden="true"></i>
              </div>
              <input
                className="form-control mb-0 pl-0"
                type="text"
                name="rewardLogoUrl"
                placeholder="Ex: https://..."
                onChange={formikUpdatePool.handleChange}
                value={formikUpdatePool.values.rewardLogoUrl}
              />
            </div>

            {formikUpdatePool.errors.rewardLogoUrl && formikUpdatePool.touched.rewardLogoUrl ? (
              <span className="text-danger">{formikUpdatePool.errors.rewardLogoUrl}</span>
            ) : null}
                      </div>


                      <div className="col-12">
                      <label className="fieldlabels">Banner url
                      <Tooltip title="Banner url with dimensions of exactly 286x110 pixels. supported extention png, jpg, jpeg, gif, mp4, webm, ogg"
                      placement="top-start"
                      enterTouchDelay={false}
                      >
                       <InfoIcon  style={{marginLeft:'2px',width:'19'}}/>
                      </Tooltip>
                      </label>
                    <div className={`${formikUpdatePool.errors.bannerUrl && formikUpdatePool.touched.bannerUrl ? 'input_invalid':'sort_by_Project'} d-flex align-items-center`}>
                      <div className="p-2 d-flex align-items-center">
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                      </div>
                      <input
                        className="form-control mb-0 pl-0"
                        type="text"
                        name="bannerUrl"
                        placeholder="Ex: https://..."
                        onChange={formikUpdatePool.handleChange}
                        value={formikUpdatePool.values.bannerUrl}
                      />
                    </div>
                        {formikUpdatePool.errors.bannerUrl && formikUpdatePool.touched.bannerUrl ? (
                          <span className="text-danger">{formikUpdatePool.errors.bannerUrl}</span>
                        ) : null}
                      </div>

                      <div className="col-12">
                      <label className="fieldlabels">Website url</label>

                          <div className={`${formikUpdatePool.errors.website_url && formikUpdatePool.touched.website_url ? 'input_invalid':'sort_by_Project'} d-flex align-items-center`}>
                            <div className="p-2 d-flex align-items-center">
                              <i class="fa fa-globe" aria-hidden="true"></i>
                            </div>
                            <input
                              className="form-control mb-0 pl-0"
                              type="text"
                              name="website_url"
                              placeholder="Ex: https://..."
                              onChange={formikUpdatePool.handleChange}
                              value={formikUpdatePool.values.website_url}
                            />
                          </div>

                        {formikUpdatePool.errors.website_url && formikUpdatePool.touched.website_url ? (
                          <span className="text-danger">{formikUpdatePool.errors.website_url}</span>
                        ) : null}
                      </div>

                      <div className="col-12">
                      <label className="fieldlabels">Twitter</label>
                        <div className={`${formikUpdatePool.errors.twitter && formikUpdatePool.touched.twitter ? 'input_invalid':'sort_by_Project'} d-flex align-items-center`}>
                          <div className="p-2 d-flex align-items-center">
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                          </div>
                          <input
                            className="form-control mb-0 pl-0"
                            type="text"
                            name="twitter"
                            placeholder="Ex: https://twitter.com/..."
                            onChange={formikUpdatePool.handleChange}
                            value={formikUpdatePool.values.twitter}
                          />
                        </div>
                        {formikUpdatePool.errors.twitter && formikUpdatePool.touched.twitter ? (
                          <span className="text-danger">{formikUpdatePool.errors.twitter}</span>
                        ) : null}
                      </div>

                      <div className="col-12">
                      <label className="fieldlabels">Github</label>

                          <div className={`${formikUpdatePool.errors.github && formikUpdatePool.touched.github ? 'input_invalid':'sort_by_Project'} d-flex align-items-center`}>
                            <div className="p-2 d-flex align-items-center">
                              <i class="fa fa-github" aria-hidden="true"></i>
                            </div>
                            <input
                              className="form-control mb-0 pl-0"
                              type="text"
                              name="github"
                              placeholder="Ex: https://github.com/..."
                              onChange={formikUpdatePool.handleChange}
                              value={formikUpdatePool.values.github}
                            />
                          </div>
                        {formikUpdatePool.errors.github && formikUpdatePool.touched.github ? (
                          <span className="text-danger">{formikUpdatePool.errors.github}</span>
                        ) : null}
                      </div>

                      <div className="col-12">
                      <label className="fieldlabels">Telegram</label>
                    <div className={`${formikUpdatePool.errors.telegram && formikUpdatePool.touched.telegram ? 'input_invalid':'sort_by_Project'} d-flex align-items-center`}>
                      <div className="p-2 d-flex align-items-center">
                        <i class="fa fa-telegram" aria-hidden="true"></i>
                      </div>
                      <input
                        className="form-control mb-0 pl-0"
                        type="text"
                        name="telegram"
                        placeholder="Ex: https://t.me/..."
                        onChange={formikUpdatePool.handleChange}
                        value={formikUpdatePool.values.telegram}
                      />
                    </div>

                        {formikUpdatePool.errors.telegram && formikUpdatePool.touched.telegram ? (
                          <span className="text-danger">{formikUpdatePool.errors.telegram}</span>
                        ) : null}
                      </div>


                      <div className="col-12">
                      <label className="fieldlabels">Discord</label>
                      <div className={`${formikUpdatePool.errors.discord && formikUpdatePool.touched.discord ? 'input_invalid':'sort_by_Project'} d-flex align-items-center`}>
                        <div className="p-2 d-flex align-items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="#90a3b7" height="1em" viewBox="0 0 640 512"><path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z" /></svg>
                        </div>
                        <input
                          className="form-control mb-0 pl-0"
                          type="text"
                          name="discord"
                          placeholder="Ex: https://discord.gg/..."
                          onChange={formikUpdatePool.handleChange}
                          value={formikUpdatePool.values.discord}
                        />
                      </div>

                        {formikUpdatePool.errors.discord && formikUpdatePool.touched.discord ? (
                          <span className="text-danger">{formikUpdatePool.errors.discord}</span>
                        ) : null}
                      </div>

                      <div className="col-12">
                      <label className="fieldlabels">Youtube {" "}
                      {/* <i class="fa fa-info-circle">
                      <span className="tooltip-text">Input YouTube URL or YouTube video ID. Make sure video link doesn't include a timestamp.</span> </i> */}
                              <Tooltip title="Input YouTube URL or YouTube video ID. Make sure video link doesn't include a timestamp."
                      placement="top-start"
                      enterTouchDelay={false}
                      >
                       <InfoIcon  style={{width:'19'}}/>
                      </Tooltip>
                      </label>
                      <div className={`${formikUpdatePool.errors.youtube && formikUpdatePool.touched.youtube ? 'input_invalid':'sort_by_Project'} d-flex align-items-center`}>
                        <div className="p-2 d-flex align-items-center">
                          <i class="fa fa-youtube-play" aria-hidden="true"></i>
                        </div>
                        <input
                          className="form-control mb-0 pl-0"
                          type="text"
                          name="youtube"
                          placeholder="Ex:https://youtube.com/watch?v=75h4tgshg3458i"
                          onChange={formikUpdatePool.handleChange}
                          value={formikUpdatePool.values.youtube}
                        />
                      </div>

                        {formikUpdatePool.errors.youtube && formikUpdatePool.touched.youtube ? (
                          <span className="text-danger">{formikUpdatePool.errors.youtube}</span>
                        ) : null}
                      </div>

                      <div className="col-12">
                      <label className="fieldlabels">Whitelist Link</label>
                      <div className={`${formikUpdatePool.errors.whitelist_link && formikUpdatePool.touched.whitelist_link ? 'input_invalid':'sort_by_Project'} d-flex align-items-center`}>
                        <div className="p-2 d-flex align-items-center">
                          <i class="fa fa-bolt" aria-hidden="true"></i>
                        </div>
                        <input
                          className="form-control mb-0 pl-0"
                          type="text"
                          name="whitelist_link"
                          placeholder="Ex.https://..."
                          onChange={formikUpdatePool.handleChange}
                          value={formikUpdatePool.values.whitelist_link}
                        />
                      </div>
                        {formikUpdatePool.errors.whitelist_link && formikUpdatePool.touched.whitelist_link ? (
                          <span className="text-danger">
                            {formikUpdatePool.errors.whitelist_link}
                          </span>
                        ) : null}
                      </div>


                      <div className="col-sm-12">
                      <label className="fieldlabels">Description <span className="text-danger" style={{fontSize: '1.5rem'}}>*</span></label>

                      <textarea
                        className={`form-control ${formikUpdatePool.errors.description && formikUpdatePool.touched.description ? 'input_invalid':'sort_by_Project'}`}
                        rows={3}
                        style={{borderRadius:'5px'}}
                        id="comment"
                        defaultValue={""}
                        name="description"
                        onChange={formikUpdatePool.handleChange}
                        value={formikUpdatePool.values.description}
                      />
                        {formikUpdatePool.errors.description && formikUpdatePool.touched.description ? (
                          <span className="text-danger">{formikUpdatePool.errors.description}</span>
                        ) : null}
                      </div>

                      <div style={{ overflow: "auto" }}>
                        <div className="d-flex gap-3 py-2 justify-content-end">
                          <button
                            type="button"
                            id="prevBtnCloseUpdate"
                            // onclick="nextPrev(-1)"
                            // onClick={prev}
                            className="btn btn-danger m-0"
                            data-bs-dismiss="modal"
                            disabled={isLoadingUpdatePresale}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            id="nextBtn"
                            className="btn btn-primary m-0"
                            disabled={isLoadingUpdatePresale}
                            onClick={(event) => {
                              event.preventDefault();
                              formikUpdatePool.handleSubmit();
                            }}
                          >
                            Ok
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  )
}

{/* <CnbtStackDetails/> */ }

function HomePage({ presale_chain }) {
  // return <Web3Button />;
  const { isDisconnected, isConnected, address, isConnecting } = useAccount();
  const { open, close, isOpen } = useWeb3Modal()

  // return <button class="btn btn-primery btn-connect" disabled={isOpen} onClick={() => { open() }}>{isOpen && !isConnected ? 'Connecting...' : isConnected ? `${address.slice(0, 4)}...${address.slice(-4)}` : 'Connect'}</button>

  return (
    <div className="custom_card text-center connect_network_presale h-auto">
      <button
        type="button"
        className="btn text-white"
        onClick={() => {
          open();
        }}
      >
        <span>
          Connect Network to
        </span>
        <span className="ms-2 me-1">
          <img
            onClick={() => { open() }}
            src={networkImages[config.SUPPORTED_NETWORKS.find(network => network.id == presale_chain)?.network]} 
            alt="icon"
            width="25"
          />
        </span>
        <span className="text-blue">{config.SUPPORTED_NETWORKS.find(network => network.id === presale_chain)?.switch_show_name}</span>
      </button>
    </div>
  )

}