import React, { useEffect, useState } from "react";
import { APP_NAME_TITLE, BASE_URL, networkImagesByChain } from "../../_constant";
import { getLocksAction, getMyLocksServiceAction } from "../../redux/apiActions/api.action";
import { useAccount, useNetwork } from "wagmi";
import { getAccount, getNetwork } from "@wagmi/core"
import { useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import { SyncLoader } from "react-spinners";
import { useRef } from "react";
import _lodash from 'lodash';
import Pagination from "../../Components/Pagination/PaginationSummary";
import config from "../../config";


export default function TokenLocks() {

    const { chain } = useNetwork()

    const account = getAccount()

    const search = useRef("");

    const [locks, setLocks] = useState([])
    const [loading,_loading]=useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(12);

    const navigate = useNavigate()

    const [totalRecords,setTotalRecords] = useState(0)
    const getLockFAction = async () => {
        try{
            _loading(true)
            const { data } = await getLocksAction({chain: chain?.id || config.SUPPORTED_NETWORKS[0]?.id, search:search?.current, pageSize:itemsPerPage,currentPage:currentPage}) 
            setLocks(data?.data)
            setTotalRecords(data?.total_records)
        }catch(error){
        }finally{
            _loading(false)
        }

    }

    const handlePageChange = (page) => {
        setCurrentPage(page)
      }



    useEffect(() => {
        getLockFAction()
    }, [chain?.id,account?.address,currentPage])

    
  const handleSearchDebounced = _lodash.debounce((value) => {
    search.current = value;
    getLockFAction()
    getMyLockFAction()
  }, 500);


    const handleChangeSearch  = ({target})=>{
        handleSearchDebounced(target.value)
      }


      const [myLoading,_myLoading] = useState(false)
      const [myLocks,_myLocks] =useState([])
      const [totalMylocks,_totalMyLocks] = useState([])
      const getMyLockFAction = async () => {
        try{
            _myLoading(true)
            const { data } = await getMyLocksServiceAction({chain: chain?.id || config.SUPPORTED_NETWORKS[0]?.id, search:search?.current, pageSize:itemsPerPage,currentPage:currentPage,address:account?.address}) 
            _myLocks(data?.data)
            _totalMyLocks(data?.total_records)
        }catch(error){
        }finally{
            _myLoading(false)
        }
    }

    useEffect(()=>{
        getMyLockFAction()
    },[currentPage,account?.address])

      document.title = `${APP_NAME_TITLE} - Token Locks`

    return (
        <div className="container-fluid mt-5">
            <div className="approve_token_card card">
                {/* <h4 className="card-header">Create Your Locks for Free</h4> */}
                <div className="card-body">

                    <div className="row">
                        <div className="approve_token_form">
                            <div className="col-md-12 mb-3">
                            <label htmlFor="liquidity-lock form-label">Search by token address...</label>
                                <div className="form-group mt-2">
                                    <input
                                        type="text"
                                        className="create_token_input"
                                        id="liquidity-lock"
                                        name="preSaleToken"
                                        onChange={handleChangeSearch}
                                        // placeholder="Search by LP Address..."
                                    />
                                </div>
                            </div>

                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <ul className="nav nav-tabs mb-3" id="ex1" role="tablist" style={{ textAlign: 'end' }}>
                                    <li className="nav-item" role="presentation">
                                        <a
                                            className="nav-link active"
                                            id="ex2-tab-1"
                                            data-bs-toggle="tab"
                                            href="#ex2-tabs-1"
                                            role="tab"
                                            aria-controls="ex2-tabs-1"
                                            aria-selected="true"
                                        >All</a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className="nav-link"
                                            id="ex2-tab-2"
                                            data-bs-toggle="tab"
                                            href="#ex2-tabs-2"
                                            role="tab"
                                            aria-controls="ex2-tabs-2"
                                            aria-selected="false"
                                        >My Locks</a>
                                    </li>
                                </ul>
                            </div>
                        </div>


                        {/* <div className="col"> */}
                        <div className="tab-content mt-3">
                        <div className="tab-pane fade show active" id="ex2-tabs-1" role="tabpanel" aria-labelledby="ex2-tab-1">
                        <div className="row">

                            {
                                loading? 
                                <SyncLoader color="#3498DB" />
                                :
                                <div className="approve_token_card mt-1 text-center p-md-4 p-3">

                                <div className="row row-cols-1">
                                    <div className="col-sm-12 col-md-12 mt-3">
                                        <div class="table-responsive">
                                            <table class="table subscriptions_Cashback_view_table">
                                                <thead>
                                                    <tr>
                                                        <th className="text-start">Token</th>
                                                        <th>Amount</th>
                                                        <th className="text-end">View</th>
                                                    </tr>
                                                </thead>

                                                <tbody className="mt-3">
                                             
                                                {
                                                    locks?.length > 0 ?
                                                       locks?.map((lock) => (
                                                        <tr>
                                                        <td className="text-start">
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img
                                                                    src={networkImagesByChain[97]} // Make sure this URL is correct
                                                                    className="img-fluid"
                                                                    alt="icon"
                                                                    width={50}
                                                                    style={{ marginRight: '16px' }} // Added margin for spacing
                                                                />
                                                                <div>
                                                                    <p style={{ margin: '0', fontWeight: '500', lineHeight: '1.5', fontSize: '18px' }}>{JSON.parse(lock.token_info)?.name}</p>
                                                                    <span style={{ margin: '0', fontWeight: '500', lineHeight: '1.5', fontSize: '1rem' }}>{JSON.parse(lock.token_info)?.symbol}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td> 
                                                        {   ethers.utils.formatUnits(String(lock.total_amount.split('.')[0]),JSON.parse(lock.token_info)?.decimal)}      
                                                        </td>
                                                        <td className="text-end">
                                                        <a  onClick={()=>{navigate(`${BASE_URL}token-lock-detail?token=${lock?.token}`)}}  style={{ color: '#3396FF', cursor:'pointer' }}>
                                                                View
                                                            </a>
                                                        </td>
                                                    </tr>
                                                       ))

                                                       :

                                                         <tr>
                                                        <td colSpan="4" className="text-center" style={{ color: 'white' }}>
                                                            No record found
                                                        </td>
                                                         </tr> 
                                                }
                                                           
                                                </tbody>
                                            </table>
                                        </div>
                                        <Pagination
                                              className="pagination-bar"
                                              currentPage={currentPage}
                                              totalCount={totalRecords}
                                              pageSize={itemsPerPage}
                                              onPageChange={page =>
                                                handlePageChange(page)
                                              }
                                              />
                                    </div>
                                </div>

                            </div>
                            }                            


                            </div>
                            </div>

                            {/* My Locks */}
                        <div className="tab-pane fade" id="ex2-tabs-2" role="tabpanel" aria-labelledby="ex2-tab-2">
                            <div className="row">
                            {myLoading? 
                                <SyncLoader color="#3498DB" />
                                :
                            <div className="approve_token_card mt-1 text-center p-md-4 p-3">

                                <div className="row row-cols-1">
                                    <div className="col-sm-12 col-md-12 mt-3">
                                        <div class="table-responsive">
                                            <table class="table subscriptions_Cashback_view_table">
                                                <thead>
                                                    <tr>
                                                        <th className="text-start">Token</th>
                                                        <th>Amount</th>
                                                        <th className="text-end">View</th>
                                                    </tr>
                                                </thead>

                                                <tbody className="mt-3">
                                            
                                                {
                                                    myLocks?.length > 0 ?
                                                    myLocks?.map((lock) => (
                                                        <tr>
                                                        <td className="text-start">
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img
                                                                    src={networkImagesByChain[97]} // Make sure this URL is correct
                                                                    className="img-fluid"
                                                                    alt="icon"
                                                                    width={50}
                                                                    style={{ marginRight: '16px' }} // Added margin for spacing
                                                                />
                                                                <div>
                                                                    <p style={{ margin: '0', fontWeight: '500', lineHeight: '1.5', fontSize: '18px' }}>{JSON.parse(lock.token_info)?.name}</p>
                                                                    <span style={{ margin: '0', fontWeight: '500', lineHeight: '1.5', fontSize: '1rem' }}>{JSON.parse(lock.token_info)?.symbol}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td> 
                                                        {   ethers.utils.formatUnits(String(lock.total_amount.split('.')[0]),JSON.parse(lock.token_info)?.decimal)}      
                                                        </td>
                                                        <td className="text-end">
                                                        <a  onClick={()=>{navigate(`${BASE_URL}token-lock-detail?token=${lock?.token}`)}}  style={{ color: '#3396FF', cursor:'pointer' }}>
                                                                View
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    ))

                                                    :

                                                        <tr>
                                                        <td colSpan="4" className="text-center" style={{ color: 'white' }}>
                                                            No record found
                                                        </td>
                                                        </tr> 
                                                }
                                                        
                                                </tbody>
                                            </table>
                                        </div>
                                        <Pagination
                                              className="pagination-bar"
                                              currentPage={currentPage}
                                              totalCount={totalMylocks}
                                              pageSize={itemsPerPage}
                                              onPageChange={page =>
                                                handlePageChange(page)
                                              }
                                              />
                                    </div>
                                </div>

                                </div>

                            }
                            </div>
                            </div>
                        </div>
                        </div>
                </div>
            </div>
        </div>
    )
}