import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  FEE_MANAGER_ADDRESSESS,
  blockInvalidChar,
  feeManagerTicket,
  gasFeesForToken,
  tokenList,
} from "../../_constant";
import SimpleToken from "../../_constant/SimpleToken.json";
import StandardToken from "../../_constant/StandardToken.json";
import ReflectionToken from "../../_constant/ReflectionToken.json";
import DividendToken from "../../_constant/DividendToken.json";
import routerAddresses from "../../_constant/routerAddresses.json";
import FeeManager from "../../_constant/FeeManager.json";
import { useWeb3Modal } from "@web3modal/react";
import Web3 from "web3";
import { toast } from "react-toastify";
import {
  createTokeSchema,
  dividentTokenSchema,
  reflectionTokenSchema,
  standardTokenSchema,
} from "../../validators/CreateTokens/CreateToken.validator";
import { ethers } from "ethers";
import { PostErrorLogAction, createTokenAction } from "../../redux/apiActions/api.action";
import { getAccount,getNetwork } from "@wagmi/core"
import { ERRORMSGS, SUCCESSMSGS, TRANSACTIONMSGS } from "../../utils/messages";
import { readContract } from '@wagmi/core'
import { useContractRead, useContractReads, useWaitForTransaction, useWalletClient } from "wagmi";
import { Interface, concat, hexlify } from "ethers";
import { Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import config from "../../config";

const toastID = "pleasedConnectWallett";

function CreateToken() {

  document.title = `Create Token`

  const { chain, chains } = getNetwork()


  const  FEE_MANAGER_ADDRESS = FEE_MANAGER_ADDRESSESS[chain?.network]

  const account = getAccount()
  const [contract, setContract] = useState(null);
  const [deploying, _deplying] = useState(false);

  const { data: walletClient } = useWalletClient();

  const [deployedData, setDeployedData] = useState();
  const [contractAddress, setContractAddress] = useState();

  const [argumentDb,_argumentDb]=useState()

  async function deployContract(data) {
    const {
      token_type,
      token_name,
      token_symbol,
      token_total_supply,
      token_decimal,
      max_wallet,
      max_transaction_amount,
      marketing_wallet,
      buy_liquidity_fee,
      sell_marketing_fee,
      buy_marketing_fee,
      tokenAddressForPair,
      sell_reward_fee,
      buy_reward_fee,
      marketingFeeToken,
      reflection_token,
      minimumTokenBalanceForDividends,
      isMarketingFeeInPairToken
    } = data;

    const abi =
      token_type === tokenList.SIMPLE_TOKEN
        ? SimpleToken.abi
        :token_type===tokenList.STANDARD_TOKEN?StandardToken.abi:token_type===tokenList.REFLECTION_TOKEN?ReflectionToken.abi:DividendToken.abi;

    const bytecode =
      token_type === tokenList.SIMPLE_TOKEN
        ? SimpleToken.bytecode
        : token_type===tokenList.STANDARD_TOKEN ?StandardToken.bytecode:token_type===tokenList.REFLECTION_TOKEN?ReflectionToken.bytecode:DividendToken.bytecode;

    const gasFee = gasFeesForToken[token_type];




    let argumentsDynamic =
      token_type === tokenList.SIMPLE_TOKEN
        ? [
            FEE_MANAGER_ADDRESS,
            {
              name: token_name,
              symbol: token_symbol,
              totalSupply: ethers.utils
                .parseUnits(String(token_total_supply), Number(token_decimal))
                .toString(),
              decimals: token_decimal,
            },
          ]
        : token_type == tokenList.STANDARD_TOKEN
        ? [
            FEE_MANAGER_ADDRESS,
            {
              name: token_name,
              symbol: token_symbol,
              totalSupply: ethers.utils
                .parseUnits(String(token_total_supply), Number(token_decimal))
                .toString(),
              decimals: token_decimal,
            },
            {
              maxHoldingAmount: ethers.utils
                .parseUnits(String(max_wallet), Number(token_decimal))
                .toString(),
              maxTransferAmount: ethers.utils
                .parseUnits(String(max_transaction_amount), Number(token_decimal))
                .toString(),
              marketingWallet: marketing_wallet,
              uniswapRouterAddress: routerAddresses[chain?.id].routerAddress,
              tokenAddressForPair: tokenAddressForPair,
              isMarketingFeeInPairToken: isMarketingFeeInPairToken,
            },
            {
              buyLiquidityFee: Number(buy_liquidity_fee) * 10,
              sellLiquidityFee: Number(sell_marketing_fee) * 10,
              buyMarketingFee: Number(buy_marketing_fee) * 10,
              sellMarketingFee: Number(sell_marketing_fee) * 10,
            },
          ]
        : token_type==tokenList.REFLECTION_TOKEN ? [
            FEE_MANAGER_ADDRESS,
            {
              name: token_name,
              symbol: token_symbol,
              totalSupply: ethers.utils
                .parseUnits(String(token_total_supply), Number(token_decimal))
                .toString(),
              decimals: token_decimal,
            },
            {
              maxHoldingAmount: ethers.utils
                .parseUnits(String(max_wallet), Number(token_decimal))
                .toString(),
              maxTransferAmount: ethers.utils
                .parseUnits(String(max_transaction_amount), Number(token_decimal))
                .toString(),
              marketingWallet: marketing_wallet,
              uniswapRouterAddress: routerAddresses[chain?.id].routerAddress,
              tokenAddressForPair: tokenAddressForPair,
              isMarketingFeeInPairToken: isMarketingFeeInPairToken,
            },
            {
              buyLiquidityFee: Number(buy_liquidity_fee) * 10,
              sellLiquidityFee: Number(sell_marketing_fee) * 10,
              buyMarketingFee: Number(buy_marketing_fee) * 10,
              sellMarketingFee: Number(sell_marketing_fee) * 10,
              buyRewardFee: Number(buy_reward_fee) * 10,
              sellRewardFee: Number(sell_reward_fee) * 10,
            },
          ]
          :
          [
            FEE_MANAGER_ADDRESS,
            {
              name: token_name,
              symbol: token_symbol,
              totalSupply: ethers.utils
                .parseUnits(String(token_total_supply), Number(token_decimal))
                .toString(),
              decimals: token_decimal,
            },
            {
              maxHoldingAmount: ethers.utils
                .parseUnits(String(max_wallet), Number(token_decimal))
                .toString(),
              maxTransferAmount: ethers.utils
                .parseUnits(String(max_transaction_amount), Number(token_decimal))
                .toString(),
              marketingWallet: marketing_wallet,
              uniswapRouterAddress: routerAddresses[chain?.id].routerAddress,
              tokenAddressForPair: tokenAddressForPair,
              isMarketingFeeInPairToken: isMarketingFeeInPairToken,
              marketingFeeToken:marketingFeeToken,
              reflectionTokenAddress:reflection_token,
              minimumTokenBalanceForDividend: ethers.utils
              .parseUnits(String(minimumTokenBalanceForDividends), Number(token_decimal))
              .toString()
            },
            {
              buyLiquidityFee: Number(buy_liquidity_fee) * 10,
              sellLiquidityFee: Number(sell_marketing_fee) * 10,
              buyMarketingFee: Number(buy_marketing_fee) * 10,
              sellMarketingFee: Number(sell_marketing_fee) * 10,
              buyRewardFee: Number(buy_reward_fee) * 10,
              sellRewardFee: Number(sell_reward_fee) * 10,
            },
          ];


          const fee = await readContract({
            address: FEE_MANAGER_ADDRESS,
            abi: FeeManager.abi,
            functionName: 'feeOf',
            args:[feeManagerTicket[token_type]]
          })

          const params = new ethers.utils.Interface(abi).encodeDeploy(argumentsDynamic);



          _argumentDb(argumentsDynamic)



          try {
            const tx = await walletClient?.sendTransaction({
              // @ts-ignore
              data: ethers.utils.hexlify(ethers.utils.concat([bytecode, params])),
              // chain: chain.id,
              value:fee
            });
            setDeployedData(tx);
          } catch (error) {
            PostErrorLogAction({ address: account?.address || 0, other_detail: { error } });
            console.log("2 Error",error)
            let errStr = error.toString().slice(0, 53)
            if (errStr === "TransactionExecutionError: User rejected the request.") {
                toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT)
            }else{
              toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG);
            }
          } finally {
                _deplying(false);
            // toast.dismiss(loadingTost);
            // setTokenData({
            //   name: "",
            //   symbol: "",
            //   decimals: 18,
            //   supply: 10000,
            // });
          }

          // const web3 = new Web3(window.ethereum);
          // const feeManagerContract = new web3.eth.Contract(FeeManager.abi, FEE_MANAGER_ADDRESS);
          // feeManagerContract.methods.feeOf(feeManagerTicket[token_type]).call(async(error, fee) => {
          //   if (error) {
          //     console.error(error);
          //   } else if(token_type != tokenList.SIMPLE_TOKEN && formik.values.marketing_wallet == account.address){
          //     toast.error("Marketing wallet can't be the same with the owner!")
          //     _deplying(false)
          //   }
          //   else {   
          //     try {
          //       // console.log("argumentsDynamic",argumentsDynamic)
                
          //       // Create a new Web3 instance
          //       const web3 = new Web3(window.ethereum);
          
          //       // Get the current account address
          //       const accounts = await web3.eth.getAccounts();
          //       const account = accounts[0];
          
          //       // Create a new contract instance
          //       const MyToken = new web3.eth.Contract(abi);
       

          //       // Deploy the contract
          //       const result = await MyToken.deploy({
          //         data: bytecode,
          //         arguments: argumentsDynamic,
          //       })
          //         .send({
          //           from: account,
          //           gas: gasFee,
          //           gasPrice: "30000000000",
          //           value: web3.utils.toWei(web3.utils.fromWei(fee), "ether"),
          //         })
          //         .then(async(data) => {
                    // toast.success(SUCCESSMSGS.TOKEN_DEPLOYED);
                    // const mergedObject = {
                    //   owner: account,
                    //   token_type:formik.values.token_type,
                    //   contract_address:data._address,
                    //   ...Object.assign({}, ...argumentsDynamic.slice(1))
                    // };
                    // await createTokenAction({bodyData:mergedObject})
          //           return data.options.address;
          //         })
          //         .catch((err) => {
          //           if(err.code==4001){
          //             toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT)
          //           }else{
          //             toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG);
          //           }
          //         })
          //         .finally(() => {
          //           _deplying(false);
          //         });

          //       setContract(result);
          //     } catch (error) {
          //       _deplying(false);
          //       console.error("Error deploying contract:", error);
          //     }

          //   }
          // });
  }


  const onReceipt = async (data) => {
    // toast.success(
    //   <a
    //     target="_blank"
    //     href={`${networkLinks[chain?.id]}/address/${data.contractAddress}`}
    //     className="underline"
    //     style={{ color: 'white', textDecoration: 'underline' }}
    //   >
    //     View Transaction
    //   </a>
    // );
    toast.success(SUCCESSMSGS.TOKEN_DEPLOYED);
    formik.resetForm(formik.initialValues);
    const mergedObject = {
      owner: account.address,
      chainId:chain?.id,
      token_type:formik.values.token_type,
      contract_address:data.contractAddress,
      ...Object.assign({}, ...argumentDb.slice(1))
    };
    await createTokenAction({bodyData:mergedObject})

  };


  const onError = async (error) => {
    console.log("error",error)
  };

  const { isFetching } = useWaitForTransaction({
    hash: deployedData,
    onSuccess: onReceipt,
    onError,
  });


  const createTokenSchema = Yup.lazy((values) => {
    if (
      values.token_type === tokenList.STANDARD_TOKEN   
    ) {
      return standardTokenSchema;
    }else if(values.token_type===tokenList.REFLECTION_TOKEN){
      return reflectionTokenSchema;
    }else if(values.token_type===tokenList.DIVIDENT_TOKEN){
      return dividentTokenSchema;
    }else{
      return createTokeSchema;
    }
  });
  const networkNames = config.SUPPORTED_NETWORKS.map(network => network.id);


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      token_type: tokenList.SIMPLE_TOKEN,
      token_name: "",
      token_symbol: "",
      token_decimal: 18,
      token_total_supply: "",
      max_wallet: "",
      max_transaction_amount: "",
      marketing_wallet: "",
      sell_liquidity: "",
      sell_marketing_fee: "",
      buy_liquidity_fee: "",
      buy_marketing_fee: "",
      anti_bot: false,
      tokenAddressForPair: "",
      sell_reward_fee:'',
      buy_reward_fee:'',
      marketingFeeToken:'0x0000000000000000000000000000000000000000',
      reflection_token:'',
      minimumTokenBalanceForDividends:"",
      isMarketingFeeInPairToken:false
    },
    validationSchema: createTokenSchema,
    onSubmit: async (data) => {
      try {
        if(account.isConnected==false){
          toast.error(ERRORMSGS.CONNECTTOWALLET,{toastId:toastID});
      }else if(!networkNames.includes(chain?.id)){
        toast.error("Wrong network!",{toastId:'wrongNetworkTId'});
        }else{
          const tokenTotalSupply = Number(formik.values.token_total_supply);
          const tokenType = formik.values.token_type;
          
          if (tokenTotalSupply > 1e+59) {
            toast.error("TotalSupply should be less than or equal to 1e59");
          } else if (tokenType !== tokenList.SIMPLE_TOKEN) {
            const sellLiquidity = Number(formik.values.sell_liquidity);
            const sellMarketingFee = Number(formik.values.sell_marketing_fee);
            const buyLiquidityFee = Number(formik.values.buy_liquidity_fee);
            const buyMarketingFee = Number(formik.values.buy_marketing_fee);
          
            let sellFeeExceedsLimit = false;
            let buyFeeExceedsLimit = false;
          
            if (tokenType === tokenList.STANDARD_TOKEN) {
              sellFeeExceedsLimit = sellLiquidity + sellMarketingFee > 20;
              buyFeeExceedsLimit = buyLiquidityFee + buyMarketingFee > 20;
            } else {
              const sellRewardFee = Number(formik.values.sell_reward_fee);
              const buyRewardFee = Number(formik.values.buy_reward_fee);
          
              sellFeeExceedsLimit = sellLiquidity + sellMarketingFee + sellRewardFee > 20;
              buyFeeExceedsLimit = buyLiquidityFee + buyMarketingFee + buyRewardFee > 20;
            }
          
            if (sellFeeExceedsLimit) {
              toast.error("Sell fee exceeds 20%!");
            } else if (buyFeeExceedsLimit) {
              toast.error("Buy fee exceeds 20%!");
            } else {
              _deplying(true);
              deployContract(data);
            }
          } else {
            _deplying(true);
            deployContract(data);
          }
        }
      } catch (error) {
      } finally {
      }
    },
  });

  const shouldHideField = (fieldName, conditionValue, formikValues) => {
    return (
      formikValues.token_type !== conditionValue && fieldName !== "token_type"
    );
  };

  

  const { data:feesData, isError:isErrorFees, isLoading:isLoaingFees,refetch:refetchFees } = useContractRead({
    address: FEE_MANAGER_ADDRESS,
    abi: FeeManager.abi,
    functionName: 'feeOf',
    args:[feeManagerTicket[formik.values.token_type]]
  })

  return (
    // <div className="body-content side-bar">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xxl-7 col-lg-10 col-md-12 mx-auto">
            <div className="row">
              <div className="col-md-12 mx-auto"></div>
            </div>
            <div className="row mt-5">
              <form id="approve_token_form" onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-md-12 mx-auto">
                    <div className="tab">
                      <h4 className="text-blue">Create Token</h4>
                      <div className="approve_token_card mt-3 text-start">
                        <div className="row g-3 row-cols-1 row-cols-sm-2">
                          <div className="col">
                            <label className="fieldlabels">Token Type</label>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <select
                              className="form-select whitelist-form-select"
                              name="token_type"
                              onChange={formik.handleChange}
                              value={formik.values.token_type}
                              disabled={deploying||isFetching}
                              style={{ marginBottom: '1px' }}
                            >
                              {Object.keys(tokenList).map((key) => (
                                <option>{tokenList[key]}</option>
                              ))}
                            </select>
                            {
                              account?.isConnected &&
                            <span className="text-blue" style={{fontSize:'0.75rem'}}>{isLoaingFees ? 'Loading...' : `Fees :  ${String(ethers.utils.formatEther(feesData || 0))} ${chain?.nativeCurrency?.symbol}` }</span>
                            }
                            </div>
                            {formik.errors.token_type &&
                            formik.touched.token_type ? (
                              <span className="text-danger">
                                {formik.errors.token_type}
                              </span>
                            ) : null}
                          </div>
                          <div className="col">
                            <label className="fieldlabels">Token Name</label>
                            <input
                              type="text"
                              name="token_name"
                              placeholder="Ex.Ethereum"
                              onChange={formik.handleChange}
                              value={formik.values.token_name}
                              disabled={deploying}
                              className={formik.errors.token_name &&
                                formik.touched.token_name && 'invalid'}
                            />
                            {formik.errors.token_name &&
                            formik.touched.token_name ? (
                              <span className="text-danger">
                                {formik.errors.token_name}
                              </span>
                            ) : null}
                          </div>
                          <div className="col">
                            <label className="fieldlabels">Token Symbol</label>
                            <input
                              type="text"
                              placeholder="Ex.ETH"
                              name="token_symbol"
                              onChange={formik.handleChange}
                              value={formik.values.token_symbol}
                              disabled={deploying}
                              className={formik.errors.token_symbol &&
                                formik.touched.token_symbol && 'invalid'}
                            />
                            {formik.errors.token_symbol &&
                            formik.touched.token_symbol ? (
                              <span className="text-danger">
                                {formik.errors.token_symbol}
                              </span>
                            ) : null}
                          </div>
                          <div className="col">
                            <label className="fieldlabels">
                              Token Decimals
                            </label>
                            <input
                              type="number"
                              onKeyDown={blockInvalidChar}
                              name="token_decimal"
                              placeholder="Ex.18"
                              onChange={formik.handleChange}
                              value={formik.values.token_decimal}
                              onWheel={(e) => e.target.blur()} 
                              disabled={deploying}
                              className={formik.errors.token_decimal &&
                                formik.touched.token_decimal && 'invalid'}
                            />
                            {formik.errors.token_decimal &&
                            formik.touched.token_decimal ? (
                              <span className="text-danger">
                                {formik.errors.token_decimal}
                              </span>
                            ) : null}
                          </div>
                          <div className="col">
                            <label className="fieldlabels">Total Supply</label>
                            <input
                              type="text"
                              name="token_total_supply"
                              placeholder="Ex.100000000000"
                              // onChange={formik.handleChange}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const numericValue = inputValue.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except dot (.)
                                formik.handleChange({
                                  target: {
                                    name: "token_total_supply",
                                    value: numericValue,
                                  },
                                });
                              }}
                              value={formik.values.token_total_supply}
                              disabled={deploying}
                              className={formik.errors.token_total_supply &&
                                formik.touched.token_total_supply && 'invalid'}
                            />
                            {formik.errors.token_total_supply &&
                            formik.touched.token_total_supply ? (
                              <span className="text-danger">
                                {formik.errors.token_total_supply}
                              </span>
                            ) : null}
                          </div>

                          {/* Standart Token  */}

                          <div
                            className="col"
                            hidden={
                              shouldHideField(
                                "max_wallet",
                                "Standard Token",
                                formik.values
                              ) &&
                              shouldHideField(
                                "max_wallet",
                                "Reflection Token",
                                formik.values
                              ) &&
                              shouldHideField(
                                "max_wallet",
                                "Dividend Token",
                                formik.values
                              )
                            }
                          >
                            <label className="fieldlabels">Max Wallet</label>
                            <input
                              type="text"
                              name="max_wallet"
                              placeholder="Ex.100000000000"
                              // onChange={formik.handleChange}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const numericValue = inputValue.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except dot (.)
                                formik.handleChange({
                                  target: {
                                    name: "max_wallet",
                                    value: numericValue,
                                  },
                                });
                              }}
                              value={formik.values.max_wallet}
                              disabled={deploying}
                              className={formik.errors.max_wallet &&
                                formik.touched.max_wallet && 'invalid'}
                            />
                            {formik.errors.max_wallet &&
                            formik.touched.max_wallet ? (
                              <span className="text-danger">
                                {formik.errors.max_wallet}
                              </span>
                            ) : null}
                          </div>

                          <div
                            className="col"
                            hidden={
                              shouldHideField(
                                "max_transaction_amount",
                                "Standard Token",
                                formik.values
                              ) &&
                              shouldHideField(
                                "max_transaction_amount",
                                "Reflection Token",
                                formik.values
                              ) &&
                              shouldHideField(
                                "max_transaction_amount",
                                "Dividend Token",
                                formik.values
                              )
                            }
                          >
                            <label className="fieldlabels">
                              Max Transaction Amount
                            </label>
                            <input
                              type="text"
                              name="max_transaction_amount"
                              placeholder="Ex.100000000000"
                              // onChange={formik.handleChange}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const numericValue = inputValue.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except dot (.)
                                formik.handleChange({
                                  target: {
                                    name: "max_transaction_amount",
                                    value: numericValue,
                                  },
                                });
                              }}
                              value={formik.values.max_transaction_amount}
                              disabled={deploying}
                              className={formik.errors.max_transaction_amount &&
                                formik.touched.max_transaction_amount && 'invalid'}
                            />
                            {formik.errors.max_transaction_amount &&
                            formik.touched.max_transaction_amount ? (
                              <span className="text-danger">
                                {formik.errors.max_transaction_amount}
                              </span>
                            ) : null}
                          </div>

                          <div
                            className="col"
                            hidden={
                              shouldHideField(
                                "router",
                                "Standard Token",
                                formik.values
                              ) &&
                              shouldHideField(
                                "router",
                                "Reflection Token",
                                formik.values
                              ) &&
                              shouldHideField(
                                "router",
                                "Dividend Token",
                                formik.values
                              )
                            }
                          >
                            <label className="fieldlabels">Router</label>
                            <select
                              className="form-select whitelist-form-select"
                              name="router"
                              onChange={formik.handleChange}
                              value={formik.values.router}
                            >
                              <option>{chain?.id && routerAddresses?.[chain?.id]?.routerName}</option>
                            </select>
                            {formik.errors.router && formik.touched.router ? (
                              <span className="text-danger">
                                {formik.errors.router}
                              </span>
                            ) : null}
                          </div>

                          <div
                            className="col"
                            hidden={
                              shouldHideField(
                                "router",
                                "Standard Token",
                                formik.values
                              ) &&
                              shouldHideField(
                                "router",
                                "Reflection Token",
                                formik.values
                              ) &&
                              shouldHideField(
                                "router",
                                "Dividend Token",
                                formik.values
                              )
                            }
                          >
                            <label className="fieldlabels">Base Token</label> <br/>
                            {
                              routerAddresses?.[chain?.id] &&
                            Object.entries(
                              routerAddresses?.[chain?.id]?.pairTokens
                            ).map(([key, value]) => (
                              <>
                                <label
                                  className="fieldlabels"
                                >
                                  {key}  : &nbsp;&nbsp;{" "}
                                </label>
                                <label onClick={() => {
                                    formik.setFieldValue(
                                      "tokenAddressForPair",
                                      value
                                    );
                                  }}
                                  className="hover-label"
                                  style={{cursor:'pointer'}}
                                  >&nbsp;{`${value} `}&nbsp;</label> 
                                <br/>
                                <br/>
                              </> 
                            ))}
                            <input
                              type="text"
                              name="tokenAddressForPair"
                              placeholder="Ex.0x..."
                              onChange={formik.handleChange}
                              value={formik.values.tokenAddressForPair}
                              disabled={deploying}
                              className={formik.errors.tokenAddressForPair &&
                                formik.touched.tokenAddressForPair && 'invalid'}
                            />
                               {formik.errors.tokenAddressForPair &&
                            formik.touched.tokenAddressForPair ? (
                              <span className="text-danger">
                                {formik.errors.tokenAddressForPair}
                              </span>
                            ) : null}

                          </div>


                          <div
                            className="col"
                            hidden={
                              shouldHideField(
                                "router",
                                "Standard Token",
                                formik.values
                              ) &&
                              shouldHideField(
                                "router",
                                "Reflection Token",
                                formik.values
                              ) &&
                              shouldHideField(
                                "router",
                                "Dividend Token",
                                formik.values
                              )
                            }
                          >
                            {/* <label className="fieldlabels">Marketing fee in BNB instead of token</label> */}
                          
                           <div className="material-switch pull-right d-flex align-items-center">
                            <p className="mb-0 me-3">Marketing fee in {chain?.nativeCurrency?.symbol||'$coin'} instead of token</p>
                            <input id="someSwitchOptionDefault" name="isMarketingFeeInPairToken" type="checkbox"
                              onChange={formik.handleChange}
                              value={formik.values.isMarketingFeeInPairToken}
                              disabled={deploying}
                            />
                            <label htmlFor="someSwitchOptionDefault" className="label-default" />
                          </div>

                          </div>

                          <div
                            className="col"
                            hidden={
                              shouldHideField(
                                "marketing_wallet",
                                "Standard Token",
                                formik.values
                              ) &&
                              shouldHideField(
                                "marketing_wallet",
                                "Reflection Token",
                                formik.values
                              ) &&
                              shouldHideField(
                                "marketing_wallet",
                                "Dividend Token",
                                formik.values
                              )
                            }
                          >
                            <label className="fieldlabels">
                              Marketing Wallet
                            </label>
                            <input
                              type="text"
                              name="marketing_wallet"
                              placeholder="Ex.0x..."
                              onChange={formik.handleChange}
                              value={formik.values.marketing_wallet}
                              disabled={deploying}
                              className={formik.errors.marketing_wallet &&
                                formik.touched.marketing_wallet && 'invalid'}
                            />
                            {formik.errors.marketing_wallet &&
                            formik.touched.marketing_wallet ? (
                              <span className="text-danger">
                                {formik.errors.marketing_wallet}
                              </span>
                            ) : null}
                          </div>

                          <div
                            className="col"
                            hidden={shouldHideField(
                              "marketing_fee",
                              "Dividend Token",
                              formik.values
                            )}
                          >
                            <label className="fieldlabels">
                              Which token will be taken for Marketing Fee?
                            </label>

                            <select
                              className="form-select whitelist-form-select"
                              name="marketingFeeToken"
                              onChange={formik.handleChange}
                              value={formik.values.marketingFeeToken}
                            >
                              <option value={"0x0000000000000000000000000000000000000000"}>Token Itself</option>
                              <option value={formik.values.tokenAddressForPair}>Base Token</option>
                              <option value={formik.values.reflection_token}>Reflection Token</option>
                            </select>
                            {formik.errors.marketingFeeToken &&
                            formik.touched.marketingFeeToken ? (
                              <span className="text-danger">
                                {formik.errors.marketingFeeToken}
                              </span>
                            ) : null}
                          </div>


                          <div
                            className="col"
                            hidden={
                              shouldHideField(
                                "reflection_token",
                                "Dividend Token",
                                formik.values
                              )
                            }
                          >
                            <label className="fieldlabels">
                            Reflection Token
                            </label>
                            <input
                              type="text"
                              name="reflection_token"
                              placeholder="Ex:0x..."
                              onChange={formik.handleChange}
                              value={formik.values.reflection_token}
                              disabled={deploying}
                              className={formik.errors.reflection_token &&
                                formik.touched.reflection_token && 'invalid'}
                            />
                            {formik.errors.reflection_token &&
                            formik.touched.reflection_token ? (
                              <span className="text-danger">
                                {formik.errors.reflection_token}
                              </span>
                            ) : null}
                          </div>

                          <div
                            className="col"
                            hidden={
                              shouldHideField(
                                "minimumTokenBalanceForDividends ",
                                "Dividend Token",
                                formik.values
                              )
                            }
                          >
                            <label className="fieldlabels">
                            Minimum token balance for dividends
                            </label>
                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={blockInvalidChar}
                              name="minimumTokenBalanceForDividends"
                              placeholder="Ex:10000000"
                              onChange={formik.handleChange}
                              value={formik.values.minimumTokenBalanceForDividends}
                              // value={formik.values.minimumTokenBalanceForDividends }
                              // disabled={deploying}
                              className={formik.errors.minimumTokenBalanceForDividends  &&
                                formik.touched.minimumTokenBalanceForDividends && 'invalid'}
                            />
                            {formik.errors.minimumTokenBalanceForDividends  &&
                            formik.touched.minimumTokenBalanceForDividends  ? (
                              <span className="text-danger">
                                {formik.errors.minimumTokenBalanceForDividends }
                              </span>
                            ) : null}
                          </div>

                          <div
                            className="col"
                            hidden={
                              shouldHideField(
                                "sell_liquidity",
                                "Standard Token",
                                formik.values
                              ) &&
                              shouldHideField(
                                "sell_liquidity",
                                "Reflection Token",
                                formik.values
                              ) &&
                              shouldHideField(
                                "max_wallet",
                                "Dividend Token",
                                formik.values
                              )
                            }
                          >
                            <label className="fieldlabels">
                              Sell Liquidity Fee (%)
                              <Tooltip arrow title="Max fee together can't be more than 20%"
                            placement="top-start"
                            enterTouchDelay={false}
                            >
                          <InfoIcon  style={{marginLeft:'2px',width:'19'}}/>
                          </Tooltip>
                            
                            </label>
                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={blockInvalidChar}
                              name="sell_liquidity"
                              placeholder="0-20.0"
                              onChange={formik.handleChange}
                              value={formik.values.sell_liquidity}
                              disabled={deploying}
                              className={formik.errors.sell_liquidity &&
                                formik.touched.sell_liquidity && 'invalid'}
                            />
                            {formik.errors.sell_liquidity &&
                            formik.touched.sell_liquidity ? (
                              <span className="text-danger">
                                {formik.errors.sell_liquidity}
                              </span>
                            ) : null}
                          </div>

                          <div
                            className="col"
                            hidden={
                              shouldHideField(
                                "sell_marketing_fee",
                                "Standard Token",
                                formik.values
                              ) &&
                              shouldHideField(
                                "sell_marketing_fee",
                                "Reflection Token",
                                formik.values
                              ) &&
                              shouldHideField(
                                "sell_marketing_fee",
                                "Dividend Token",
                                formik.values
                              )
                            }
                          >
                            <label className="fieldlabels">
                              Sell Marketing Fee (%)
                            </label>
                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={blockInvalidChar}
                              name="sell_marketing_fee"
                              placeholder="0-20.0"
                              onChange={formik.handleChange}
                              value={formik.values.sell_marketing_fee}
                              disabled={deploying}
                              className={formik.errors.sell_marketing_fee &&
                                formik.touched.sell_marketing_fee && 'invalid'}
                            />
                            {formik.errors.sell_marketing_fee &&
                            formik.touched.sell_marketing_fee ? (
                              <span className="text-danger">
                                {formik.errors.sell_marketing_fee}
                              </span>
                            ) : null}
                          </div>

                          <div
                            className="col"
                            hidden={
                              shouldHideField(
                                "buy_liquidity_fee",
                                "Standard Token",
                                formik.values
                              ) &&
                              shouldHideField(
                                "buy_liquidity_fee",
                                "Reflection Token",
                                formik.values
                              ) &&
                              shouldHideField(
                                "buy_liquidity_fee",
                                "Dividend Token",
                                formik.values
                              )
                            }
                          >
                            <label className="fieldlabels">
                              Buy Liquidity Fee (%)
                              <Tooltip arrow title="Max fee together can't be more than 20%"
                              placement="top-start"
                              enterTouchDelay={false}
                              >
                              <InfoIcon  style={{marginLeft:'2px',width:'19'}}/>
                              </Tooltip>
                            </label>
                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={blockInvalidChar}
                              name="buy_liquidity_fee"
                              placeholder="0-20.0"
                              onChange={formik.handleChange}
                              value={formik.values.buy_liquidity_fee}
                              disabled={deploying}
                              className={formik.errors.buy_liquidity_fee &&
                                formik.touched.buy_liquidity_fee && 'invalid'}
                            />
                            {formik.errors.buy_liquidity_fee &&
                            formik.touched.buy_liquidity_fee ? (
                              <span className="text-danger">
                                {formik.errors.buy_liquidity_fee}
                              </span>
                            ) : null}
                          </div>

                          <div
                            className="col"
                            hidden={
                              shouldHideField(
                                "buy_liquidity_fee",
                                "Standard Token",
                                formik.values
                              ) &&
                              shouldHideField(
                                "buy_liquidity_fee",
                                "Reflection Token",
                                formik.values
                              ) &&
                              shouldHideField(
                                "buy_liquidity_fee",
                                "Dividend Token",
                                formik.values
                              )
                            }
                          >
                            <label className="fieldlabels">
                              Buy Marketing Fee (%)
                            </label>
                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={blockInvalidChar}
                              name="buy_marketing_fee"
                              placeholder="0-20.0"
                              onChange={formik.handleChange}
                              value={formik.values.buy_marketing_fee}
                              disabled={deploying}
                              className={formik.errors.buy_marketing_fee &&
                                formik.touched.buy_marketing_fee && 'invalid'}
                            />
                            {formik.errors.buy_marketing_fee &&
                            formik.touched.buy_marketing_fee ? (
                              <span className="text-danger">
                                {formik.errors.buy_marketing_fee}
                              </span>
                            ) : null}
                          </div>

                          {/* End Standart Token  */}

                          {/* Reflection Token */}

                          <div
                            className="col"
                            hidden={
                              shouldHideField(
                                "sell_reward_fee",
                                "Reflection Token",
                                formik.values
                              ) &&
                              shouldHideField(
                                "sell_reward_fee",
                                "Dividend Token",
                                formik.values
                              )
                            }
                          >
                            <label className="fieldlabels">
                              Sell Reward Fee (%)
                            </label>
                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={blockInvalidChar}
                              name="sell_reward_fee"
                              placeholder="0-20.0"
                              onChange={formik.handleChange}
                              value={formik.values.sell_reward_fee}
                              disabled={deploying}
                              className={formik.errors.sell_reward_fee &&
                                formik.touched.sell_reward_fee && 'invalid'}
                            />
                            {formik.errors.sell_reward_fee &&
                            formik.touched.sell_reward_fee ? (
                              <span className="text-danger">
                                {formik.errors.sell_reward_fee}
                              </span>
                            ) : null}
                          </div>

                          <div
                            className="col"
                            hidden={
                              shouldHideField(
                                "buy_reward_fee",
                                "Reflection Token",
                                formik.values
                              ) &&
                              shouldHideField(
                                "buy_reward_fee",
                                "Dividend Token",
                                formik.values
                              )
                            }
                          >
                            <label className="fieldlabels">
                              Buy Reward Fee (%)
                            </label>
                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={blockInvalidChar}
                              name="buy_reward_fee"
                              placeholder="0-20.0"
                              onChange={formik.handleChange}
                              value={formik.values.buy_reward_fee}
                              disabled={deploying}
                              className={formik.errors.buy_reward_fee &&
                                formik.touched.buy_reward_fee && 'invalid'}
                            />
                            {formik.errors.buy_reward_fee &&
                            formik.touched.buy_reward_fee ? (
                              <span className="text-danger">
                                {formik.errors.buy_reward_fee}
                              </span>
                            ) : null}
                          </div>

                          {/* End Reflection Token */}
                        </div>
                        <section>{/* <hr /> */}</section>

                        <div class="d-flex flex-wrap justify-content-between align-items-center">
                          <div className="material-switch pull-right d-flex align-items-center">
                            <input
                              id="someSwitchOptionDefault"
                              name="anti_bot"
                              type="checkbox"
                              onChange={formik.handleChange}
                              value={formik.values.anti_bot}
                              disabled={deploying}
                            />
                            {/* <label
                              htmlFor="someSwitchOptionDefault"
                              className="label-default"
                            ></label>
                            <p
                              className="mb-0 me-3"
                              style={{ marginLeft: "10px" }}
                            >
                              Implement Anti-Bot
                            </p> */}
                          </div>
                        </div>

                        <button
                          class="btn align-items-center d-flex mx-auto mt-4 mb-1"
                          type="submit"
                          disabled={deploying||isFetching}
                        >
                          {deploying||isFetching ? "Deploying..." : `Create Token`}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    // </div>
  );
}

export default CreateToken;
