import { bscTestnet, bsc } from 'wagmi/chains';

let config = {
  development: {
    project_name: 'HelperPad.io',
    project_name_short: 'HelperPad.io',
    project_domain: 'www.io.pixelsoftwares.com',
    project_base_path: "/scanner_launchpad/v1/",
    project_domain_link: 'https://io.pixelsoftwares.com/scanner_launchpad/v1',
    server_base_url: 'https://io.pixelsoftwares.com:8091/api/v1',
    mode: 'development',
    USDT_ADDRESS: '0x3F6194dfa98569AeA86aefe1e4f1c4Df54e42355',
    usdtContractDecimals: 6,
    HLPR_ADDRESS: '0x0F6ffdD80e270F1ACD7A9baD73E7E1b7cE921Cd7',
    SUPPORTED_NETWORKS: [
      {
        id: 56,
        decimals: 18,
        name: 'BNB',
        symbol: 'BNB',
        switch_show_name: 'Bsc Mainnet',
        network: 'bsc',
      },
      {
        id: 97,
        decimals: 18,
        name: 'BNB',
        symbol: 'BNB',
        switch_show_name: 'Bsc Testnet',
        network: 'bsc-testnet',
      },
    ],
    ACTIVE_CHAINS: [bscTestnet, bsc],
    INFURAID: 'e717cb93316376cf535223e01336cd09',
  },
  production: {
    project_name: 'HelperPad.io',
    project_name_short: 'HelperPad.io',
    project_domain: 'www.helperpad.io',
    project_domain_link: 'https://helperpad.io',
    project_base_path: "/",
    server_base_url: 'https://helperpad.io:8091/api/v1',
    mode: 'production',
    USDT_ADDRESS: '0x55d398326f99059ff775485246999027b3197955',
    usdtContractDecimals: 18,
    HLPR_ADDRESS: '0x501cb2cd15198a4853bf8944b04c2053410a912b',
    SUPPORTED_NETWORKS: [
      {
        id: 56,
        decimals: 18,
        name: 'BNB',
        symbol: 'BNB',
        switch_show_name: 'Bsc Mainnet',
        network: 'bsc',
      },
    ],
    ACTIVE_CHAINS: [bsc],
    INFURAID: 'e717cb93316376cf535223e01336cd09',
  },
};

console.log(process.env.REACT_APP_ENV, '  config');
console.log(config.development.SUPPORTED_NETWORKS, 'supported networks');
console.log(config.development.ACTIVE_CHAINS, 'active chains');

export default config[
  process.env?.REACT_APP_ENV === 'production' ? 'production' : 'development'
];
