import React, { useEffect, useState } from "react";
import { useAccount, useContractWrite, useNetwork, useWaitForTransaction } from "wagmi";
import PreSaleManager from "../../_constant/PreSaleManager.json";
import { PRESALE_MANAGER_ADDRESSESS } from "../../_constant";
import { toast } from "react-toastify";
import { SUCCESSMSGS, TRANSACTIONMSGS } from "../../utils/messages";
import moment from "moment";
import { PostErrorLogAction, allowClaimAction } from "../../redux/apiActions/api.action";
import { SyncLoader } from "react-spinners";


export function Allowclaim({preSale, getPresaleFuncWithoutLoading}) {

    const { chain, chains } = useNetwork();
    const { address } = useAccount();
    const PRESALE_MANAGER_ADDRESS = PRESALE_MANAGER_ADDRESSESS[chain?.network]

    const onErrorClaimLPTokens = (error)=>{
        PostErrorLogAction({ address: address || 0, other_detail: { error } });
        console.log('16 Error', error);
        let errStr = error.toString().slice(0, 53)
        if (errStr === "TransactionExecutionError: User rejected the request.") {
            toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT)
        }else if(error.toString().includes('CannotUnlockLiquidityNow')){
            toast.error("Cannot Unlock Liquidity Now")
        }else if(error.toString().includes("InsufficientTokenBalance")){
            toast.error("Already claimed!",{toastId:'istbTid'})
        }
        else{
            toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG)
        }
    }


    const onSuccessClaimLPTokens = ()=>{

    }

    const {
        data: dataAllowClaim,
        write: writeAllowClaim,
        isLoading: isLoadingAllCl,
        isSuccess: isSuccessClaimLPTokens,
      } = useContractWrite({
        address: PRESALE_MANAGER_ADDRESS,
        abi: PreSaleManager.abi,
        functionName: "allowClaimForSpecialSale",
        args:[preSale],
        onError: onErrorClaimLPTokens,
        onSuccess: onSuccessClaimLPTokens
      });

      const onSuccessWaitForTransaction = async(data) => {
        toast.success("Claim Allowed!")

        // allow-claim
        let bodyData = {preSale:preSale}
        await allowClaimAction(bodyData)
        getPresaleFuncWithoutLoading()
      }

      const onApproveReceipt = ()=>{

      }
    
      // Waiting for tx to mine.
      const { isLoading: isLoadingWaitForTranasaction } = useWaitForTransaction({
        hash: dataAllowClaim?.hash,
        onSettled: onApproveReceipt,
        onSuccess: onSuccessWaitForTransaction
      });


   const currentUnixTime = moment().unix();

    return (
        <>
        {
            // <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 gap-2">


                        <button
                        className="btn btn-success btn-block mt-4 modal-open btn_managepool"
                        disabled={isLoadingAllCl||isLoadingWaitForTranasaction}
                        onClick={()=>{writeAllowClaim()}}
                        >{isLoadingAllCl||isLoadingWaitForTranasaction ?  <SyncLoader color={'#3498DB'} size={10} />: "Allow claim"}</button>


                    </div>
                </div>
            // </div>
        }
        </>
    )
}