import React from "react";
import { useAccount, useContractRead, useContractWrite, useNetwork, useWaitForTransaction } from "wagmi";
import {PRESALE_MANAGER_ADDRESSESS } from "../../_constant";
import PreSaleManager from "../../_constant/PreSaleManager.json";
import { SUCCESSMSGS, TRANSACTIONMSGS } from "../../utils/messages";
import { toast } from "react-toastify";
import PreSale from "../../_constant/PreSale.json";
import { ClaimedAction, PostErrorLogAction } from "../../redux/apiActions/api.action";
import { SyncLoader } from "react-spinners";

export function Claim({preSale,getInvestDetailsFunc, presaleDetails}){
    const { isDisconnected, isConnected, address } = useAccount();
    const { chain, chains } = useNetwork();
    const PRESALE_MANAGER_ADDRESS = PRESALE_MANAGER_ADDRESSESS[chain?.network]


    const onErrorClaim = (error) => {
        PostErrorLogAction({ address: address || 0, other_detail: { error } });
        console.log("7 Error",error)
        let errStr = error.toString().slice(0, 25)
        if (errStr === "TransactionExecutionError") {
          toast.error(TRANSACTIONMSGS.METAMASKREQUESTREJECT,{toastId:'rejectedTrans'})
        }else if(error.toString().includes('InvestorContributionIsZero')){
          toast.error("Investor Contribution Is Zero!",{toastId:'iCiszeroTid'})
        }else if (error.toString().includes("FirstReleasePeriodNotReached")){
          toast.error("First Release Period Not Reached!",{toastId:'frpnRTId'})
        }else if (error.toString().includes("WaitTillReleaseDate")){
          toast.error(`Claim amount is bigger than released amount.
          Please wait till next release!`,{toastId:'frpnRTId1'})
        }
         else {
          toast.error(TRANSACTIONMSGS.SOMETHINGWENTWRONG,{toastId:'smwrTid'});
        }
      }

      const onSuccessClaim =async(data)=>{
        // console.log("Data",data)

      }

    const {
        data: dataClaim,
        isLoading: isLoadingClaim,
        write: claim,
      } = useContractWrite({
        address: PRESALE_MANAGER_ADDRESS,
        abi: PreSaleManager.abi,
        functionName: "claimTokensFromPreSale",
        args: [preSale],
        onError: onErrorClaim,
        onSuccess: onSuccessClaim
      });


      
        const { isError: refetchClaimedAmountIsError, isLoading: ClaimedAmountIsLoading, refetch: refetchClaimedAmount } = useContractRead({
          address: preSale,
          abi: PreSale.abi,
          functionName: '_preSaleVestingUserTokenReleased',
          args: [address],
      })

      const { isError: ReceiveTokensisError, isLoading: ReceiveTokensisLoading, refetch: refetchInvestorReceivedTokens } = useContractRead({
        address: preSale,
        abi: PreSale.abi,
        functionName: 'investorReceivedTokens',
        args: [address],
      })
      
      const onSuccessWaitForTransaction  = async()=>{

        
        const responseOfClaimedAmount = await refetchClaimedAmount();
        const responseOfInvestorReceivedTokens = await refetchInvestorReceivedTokens();
        console.log(responseOfClaimedAmount, responseOfInvestorReceivedTokens, 'responseOfClaimedAmount, responseOfInvestorReceivedTokens');
  
        if(presaleDetails?.isPreSaleVesting){
          const responseOfClaimedAmount = await refetchClaimedAmount();
          const responseOfInvestorReceivedTokens = await refetchInvestorReceivedTokens();
          console.log(responseOfClaimedAmount, responseOfInvestorReceivedTokens, 'responseOfClaimedAmount, responseOfInvestorReceivedTokens');
          
          if(responseOfClaimedAmount?.isError || responseOfInvestorReceivedTokens?.isError || responseOfClaimedAmount?.data !== responseOfInvestorReceivedTokens?.data){
            return 
          }
        }
        
        let bodyData = {preSale:preSale, owner:address}
        let response = await ClaimedAction(bodyData)
        if(response){
            toast.success(SUCCESSMSGS.CLAIM_SUCCESS)
            getInvestDetailsFunc()
        }
      }

      const { data: DataNotEth, isError: isErrorWaitForTransNotEth, isLoading: isLoadingWaitForTransNotEth } = useWaitForTransaction({
        hash: dataClaim?.hash,
        onSuccess: onSuccessWaitForTransaction
    })

    return(
        <ul>
          {
            // isLoadingClaim || isLoadingWaitForTransNotEth ?
            // <div className="text-center">
            // <SyncLoader color={'#3498DB'} size={10} />
            // </div>
            // :
          <button
          className="btn emergency_with_btn mt-2 mx-auto"
          onClick={() => { claim() }}
          disabled={isLoadingClaim || isLoadingWaitForTransNotEth}
        > {isLoadingClaim || isLoadingWaitForTransNotEth? <SyncLoader color={'#3498DB'} size={10} />:'Claim'}</button>
          }
      </ul>
    )
}